import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import { formatNumberToPrice, layDiaChi } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import { useSelector } from "react-redux";
import { convertString } from "../update_1-11/PhieuChiDinhGreenLab";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const stylePhieuThuGoiDv = `
.thong-tin-kh-v2 {
    display: none;
  }
#phieu-thu-goi-dv * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-thu-goi-dv .logo { width: 54px; height: 54px; object-fit: contain; }
#phieu-thu-goi-dv .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-thu-goi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 10px; }
#phieu-thu-goi-dv .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-thu-goi-dv .subtitle { margin-block: 0.25rem; font-size: 12px; font-weight: bold; }
#phieu-thu-goi-dv .table * { line-height: 1.2em; }
#phieu-thu-goi-dv .table { page-break-inside:auto; }
#phieu-thu-goi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-goi-dv thead { display:table-header-group }
#phieu-thu-goi-dv tfoot { display:table-footer-group }
#phieu-thu-goi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-goi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-goi-dv .table th, .table td { padding: 5px; }
#phieu-thu-goi-dv .table th { text-align: start; }
#phieu-thu-goi-dv .noBorder2 { border-right: none; }
#phieu-thu-goi-dv .noBorder { border-left: none; }
#phieu-thu-goi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-goi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-goi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-goi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-goi-dv .des { font-style: italic; }
#phieu-thu-goi-dv .sub-barcode-text {}

@media print {
   #phieu-thu-goi-dv, #phieu-thu-goi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-goi-dv{page-break-after: always;}
}
`;

export const stylePhieuThuGoiDvIshi = `
.thong-tin-kh-v1 {
  display: none;
}

#phieu-thu-goi-dv * { font-size: 16px; font-family: "Times New Roman", Times, serif; }
#phieu-thu-goi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-goi-dv .fs12 { font-style: 14px; }
#phieu-thu-goi-dv .header { display: flex; gap: 10px; position:relative; font-size: 14px;}
#phieu-thu-goi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16px; }
#phieu-thu-goi-dv .title { text-transform: uppercase; font-size: 20px; font-weight: bold; text-align: center; width: 90%; margin: 1.5rem auto 0.25rem auto;}
#phieu-thu-goi-dv .subtitle { margin-block: 0.25rem; font-size: 14px; font-weight: bold; }
#phieu-thu-goi-dv .table * { line-height: 1.2em; }
#phieu-thu-goi-dv .table { page-break-inside:auto; }
#phieu-thu-goi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-goi-dv thead { display:table-header-group }
#phieu-thu-goi-dv tfoot { display:table-footer-group }
#phieu-thu-goi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-goi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-goi-dv .table th, .table td { padding: 5px; }
#phieu-thu-goi-dv .table th { text-align: start; }
#phieu-thu-goi-dv .noBorder2 { border-right: none; }
#phieu-thu-goi-dv .noBorder { border-left: none; }
#phieu-thu-goi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-goi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-goi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-goi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-goi-dv .des { font-style: italic; }
#phieu-thu-goi-dv .sub-barcode-text { font-size:14px;position:absolute;top:50px; left:50%; line-height: 1.4em; transform: translateX(-50%);}
#phieu-thu-goi-dv .phone-website { display: flex; gap: 24px; align-items: center}
.txt-700 {
  font-weight: 700;
}
@media print {
   #phieu-thu-goi-dv, #phieu-thu-goi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-goi-dv{page-break-after: always;}
}
`;


export const stylePhieuThuGoiDvAivi = `
.thong-tin-kh-v1 {
  display: none;
}

#phieu-thu-goi-dv * { font-size: 14px; font-family: "Times New Roman", Times, serif; }
#phieu-thu-goi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-goi-dv .fs12 { font-style: 14px; }
#phieu-thu-goi-dv .header { display: flex; gap: 10px; position:relative; font-size: 14px;}
#phieu-thu-goi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 14px; }
#phieu-thu-goi-dv .title { text-transform: uppercase; font-size: 18px; font-weight: bold; text-align: center; width: 90%; margin: 30px auto 10px auto;}
#phieu-thu-goi-dv .subtitle { margin-block: 0.25rem; font-size: 14px; font-weight: bold; }
#phieu-thu-goi-dv .table * { line-height: 1.2em; }
#phieu-thu-goi-dv .table { page-break-inside:auto; }
#phieu-thu-goi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-goi-dv thead { display:table-header-group }
#phieu-thu-goi-dv tfoot { display:table-footer-group }
#phieu-thu-goi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-goi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-goi-dv .table th, .table td { padding: 5px; }
#phieu-thu-goi-dv .table th { text-align: start; }
#phieu-thu-goi-dv .noBorder2 { border-right: none; }
#phieu-thu-goi-dv .noBorder { border-left: none; }
#phieu-thu-goi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-goi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-goi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-goi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-goi-dv .des { font-style: italic; }
#phieu-thu-goi-dv .sub-barcode-text { font-size:14px;position:absolute;top:50px; left:50%; line-height: 1.4em; transform: translateX(-50%);}
#phieu-thu-goi-dv .phone-website { display: flex; gap: 24px; align-items: center}
.txt-700 {
  font-weight: 700;
}
.web, .email {
    display: none;
  }
@media print {
   #phieu-thu-goi-dv, #phieu-thu-goi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-goi-dv{page-break-after: always;}
}
`;


const PhieuThuGoiDv = ({ data, rid }) => {

    const userProfile = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (!!rid && !!data.benh_nhan.ma_benh_nhan) {
            JsBarcode(`#barcode-${rid}`)
                .options({ font: "OCR-B" })
                .CODE128(data.benh_nhan.ma_benh_nhan, {
                    text: ` `,
                    fontSize: 10,
                    width: 1.2,
                    height: 40,
                    textMargin: 0,
                    margin: 0,
                    padding: 0,
                })
                .render();
        }
    }, [data.benh_nhan.ma_benh_nhan, rid]);

    const TongChiPhi = data.dv_goi?.reduce((acc, obj) => { return acc + (+obj.DON_GIA * +(obj.so_luong || obj.SO_LUONG)) }, 0)
    const TongMienGiam = data.dv_goi?.reduce((acc, obj) => { return acc + obj.MIEN_GIAM }, 0)
    const TongThanhToan = TongChiPhi - TongMienGiam

    return Array(['aivi052428', '0018nadk', '0018dk248', '0018htdk'].includes(userProfile.partner_code) ? 1 : 2)
        .fill(null)
        .map((_, index) => (
            <div id="phieu-thu-goi-dv" style={index === 0 ? { marginTop: -7 } : {}} key={index}>
                <div className="header">
                    { data.hospital.avatar && <Images className="logo" imgId={data.hospital.avatar}/> }
                    
                    <div className="content-header">
                        <h2 style={{ margin: 0 }} className="hospital-name __dumi-default-dark-autoupperTxt">
                            {userProfile?.benh_vien?.TEN_CSKCB}
                        </h2>
                        <div style={{ wordWrap: 'break-word' }}>
                            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
                        </div>
                        <div className="flex">
                            <div className="phone-website">
                                <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
                            </div>
                        </div>
                        <div>
                            <span className="web txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
                        </div>
                        <div>
                            <span className="email txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
                        <span><svg id={`barcode-${rid}`}></svg></span>
                        <div className="sub-barcode-text">
                            <div style={{ width: 300, textAlign: 'center' }}>
                                {i18n.t(languageKeys.ma_kh)}:<b> {data.benh_nhan.ma_benh_nhan}</b>
                            </div>
                            <div style={{ width: 300, textAlign: 'center' }}>
                                {i18n.t(languageKeys.field_So_phieu)}: <b>{data.benh_nhan.SO_PHIEU}</b>
                            </div>
                            <div style={{ width: 300, textAlign: 'center' }}>
                                {i18n.t(languageKeys.ma_ca_kham)}: <b>{data?.benh_nhan?.MA_CUOC_KHAM || ""}</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="title">
                    {data.nhom === "HOAN_TIEN" ? i18n.t(languageKeys.phieu_hoan_goi_dv) : i18n.t(languageKeys.phieu_thu_goi_dv)}
                </div>

                {['aivi052428', '0018nadk', '0018dk248', '0018htdk'].includes(userProfile.partner_code)
                    ? <></>
                    : (
                        <div className="subtitle" style={{ textAlign: "center", lineHeight: "1em", marginBottom: '2rem' }}>
                            ({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})
                        </div>
                    )
                }

                <div className="thong-tin-kh-v1">
                    <div className="subtitle">{i18n.t(languageKeys.thong_tin_khach_hang)}</div>

                    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {i18n.t(languageKeys.ho_va_ten)}: <b> {data.benh_nhan.ten_benh_nhan}</b>
                            </div>
                        </div>

                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {i18n.t(languageKeys.field_Ngay_sinh)}: {data.benh_nhan?.ngay_sinh.split("/").pop()}
                            </div>
                            <div>
                                {i18n.t(languageKeys.field_Gioi_tinh)}: {data.benh_nhan.gioi_tinh}
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
                        <div>
                            {i18n.t(languageKeys.field_So_dien_thoai)}: {data.benh_nhan.sdt}
                        </div>
                        <div>
                            {i18n.t(languageKeys.field_Doi_tuong)}: {data.benh_nhan.doi_tuong === 'KHAM_DOAN' ? i18n.t(languageKeys.kham_doan) : data.benh_nhan.doi_tuong}
                        </div>
                    </div>
                    <div>
                        {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi}
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.ten_goi_dv)}: {data.ten_goi_dv}
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.loai_goi_dv)}: {data.ten_loai_goi}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="thong-tin-kh-v2">
                    <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                        <div >
                            {i18n.t(languageKeys.ho_ten)}: <b> {data.benh_nhan.ten_benh_nhan}</b>
                        </div>

                        <div style={{ display: "flex", gap: 24 }}>
                            <div>
                                {i18n.t(languageKeys.field_Gioi_tinh)}: {data.benh_nhan.gioi_tinh}
                            </div>
                            <div>
                                {i18n.t(languageKeys.field_Ngay_sinh)}: {data.benh_nhan?.ngay_sinh.split("/").pop()}

                            </div>
                        </div>

                        <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>{i18n.t(languageKeys.so_dien_thoai)}:<b>{data.benh_nhan.sdt}</b></div>
                        </div>
                        <div>
                            {i18n.t(languageKeys.field_Doi_tuong)}: {data.benh_nhan.doi_tuong === 'KHAM_DOAN' ? i18n.t(languageKeys.kham_doan) : data.benh_nhan.doi_tuong}
                        </div>
                        <div>

                        </div>
                    </div>
                    <div>
                        {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi}
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {i18n.t(languageKeys.ten_goi_dv)}: {data.ten_goi_dv}
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {i18n.t(languageKeys.loai_goi_dv)}: {data.ten_loai_goi}
                            </div>
                        </div>
                    </div>


                </div>

                <div>


                    <div>
                        {data.nhom === "HOAN_TIEN" ? (
                            <div>
                                {" "}
                                {i18n.t(languageKeys.field_Ly_do_hoan_tien)} : {data.benh_nhan.ly_do_hoan_tien}
                            </div>
                        ) : (
                            <div>
                                {" "}
                                {i18n.t(languageKeys.field_Ly_do_mien_giam)} : {data.benh_nhan.ly_do_mien_giam}
                            </div>
                        )}
                    </div>

                    <table style={{ width: "100%" }} className="table">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_So_luong)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.tong_tien)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Mien_giam)}</th>
                                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.dv_goi?.map((dich_vu, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                                            <td>{dich_vu.TEN_DV}</td>
                                            <td style={{ textAlign: 'center' }}>{dich_vu.so_luong || dich_vu.SO_LUONG}</td>
                                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(dich_vu.DON_GIA)}</td>
                                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(+dich_vu.DON_GIA * +(dich_vu.so_luong || dich_vu.SO_LUONG))}</td>
                                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(dich_vu.MIEN_GIAM)}</td>
                                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(+dich_vu.DON_GIA * +(dich_vu.so_luong || dich_vu.SO_LUONG) - dich_vu.MIEN_GIAM)}</td>
                                        </tr>
                                        {userProfile.config.HIEN_THI_KEM_THEO && dich_vu.DV_KEM_THEO?.map((dvkt, i) => (
                                            <tr key={i}>
                                                <td></td>
                                                <td style={{ paddingLeft: 30 }}>{dvkt.TEN_DV}</td>
                                                <td style={{ textAlign: 'center' }}>{dvkt.so_luong || dvkt.SO_LUONG}</td>
                                                <td style={{ textAlign: "end" }}>{formatNumberToPrice(dvkt.DON_GIA)}</td>
                                                <td style={{ textAlign: "end" }}>{formatNumberToPrice(+dvkt.DON_GIA * +(dvkt.so_luong || dvkt.SO_LUONG))}</td>
                                                <td style={{ textAlign: "end" }}>{formatNumberToPrice(dvkt.MIEN_GIAM)}</td>
                                                <td style={{ textAlign: "end" }}>{formatNumberToPrice((+dvkt.DON_GIA * +(dvkt.so_luong || dvkt.SO_LUONG)) - +dvkt.TONG_TIEN_GIAM)}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                );
                            })}

                            <tr>
                                <td colSpan='2'>
                                    <b>{i18n.t(languageKeys.bao_cao_tong_cong)}</b>
                                </td>
                                <td colSpan="1" style={{ textAlign: "center", borderRight: "none", fontWeight: "bold" }}>
                                    {data.dv_goi.reduce((acc, obj) => {
                                        return acc + (
                                            obj.DV_KEM_THEO
                                                ? obj.DV_KEM_THEO.reduce((cal, item) => { return cal + item.SO_LUONG }, 0)
                                                : obj.SO_LUONG
                                        );
                                    }, 0)
                                    }
                                </td>
                                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                                </td>
                                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                                    {formatNumberToPrice(TongChiPhi)}
                                </td>
                                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                                    {formatNumberToPrice(TongMienGiam)}
                                </td>
                                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                                    {formatNumberToPrice(TongThanhToan)}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {data.nhom === "THANH_TOAN" && (
                        <div className="foot-break" >
                            {/* <div/>
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 20,
                                        alignItems: "center",
                                    }}
                                >
                                    <div>(1) {i18n.t(languageKeys.tong_tien)}:</div>
                                    <b>{formatNumberToPrice(TongChiPhi)}</b>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 20,
                                        alignItems: "center",
                                    }}
                                >
                                    <div>(2) {i18n.t(languageKeys.field_Mien_giam)}:</div>
                                    <b>{formatNumberToPrice(TongMienGiam)}</b>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 20,
                                        alignItems: "center",
                                        borderTop: "1px solid #000",
                                        marginTop: 5,
                                        paddingTop: 5,
                                    }}
                                >
                                    <div>(3) = (1) - (2) {i18n.t(languageKeys.tong_thanh_toan)}:</div>
                                    <b>{formatNumberToPrice(TongThanhToan)}</b>
                                </div>
                            </div> */}

                            <div style={{ display: "flex", justifyContent: "space-between", margin: '15px 0 12px' }}>
                                <div>
                                    <b>{i18n.t(languageKeys.tong_tien)}: </b>
                                    <b>{formatNumberToPrice(TongChiPhi, 'đ')}</b>
                                </div>
                                <div>
                                    <b>{i18n.t(languageKeys.field_Mien_giam)}: </b>
                                    <b>{formatNumberToPrice(TongMienGiam, 'đ')}</b>
                                </div>
                                <div>
                                    <b>{i18n.t(languageKeys.vien_phi_thanh_tien)}: </b>
                                    <b>{formatNumberToPrice(TongThanhToan, 'đ')}</b>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>{/*QR*/}</div>

                                <div>
                                    {data.cac_phuong_thuc_tt.map((httt, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: 40,
                                                alignItems: "center",
                                            }}
                                        >
                                            <div>{i18n.t(languageKeys.field_Thanh_toan) + " " + Object.keys(httt)[0]}:</div>
                                            <div>{formatNumberToPrice(Object.values(httt)[0])}</div>
                                        </div>
                                    ))}

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 40,
                                            alignItems: "center",
                                            borderTop: "1px solid #000",
                                            marginTop: 5,
                                            paddingTop: 5,
                                        }}
                                    >
                                        <b>{i18n.t(languageKeys.tong_tien_kh_dua)}:</b>
                                        <b>{formatNumberToPrice(data.tong_tien_khach_dua)}</b>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 40,
                                            alignItems: "center",
                                        }}
                                    >
                                        <b>{i18n.t(languageKeys.tong_can_thanh_toan)}:</b>
                                        <b>{formatNumberToPrice(TongThanhToan)}</b>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 40,
                                            alignItems: "center",
                                        }}
                                    >
                                        <b>{i18n.t(languageKeys.so_du_cong_no)}:</b>
                                        <b style={{ color: '#f34946' }}>
                                            {data.so_du_cong_no < 0
                                                ? `(${formatNumberToPrice(Math.abs(data.so_du_cong_no))})`
                                                : formatNumberToPrice(Math.abs(data.so_du_cong_no))}
                                        </b>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                </div>

                <div className="foot-break" style={{ minHeight: "20vh", marginTop: '20px' }}>

                    <div className="anotherFoot">
                        <div style={{ marginTop: 16 }}>
                            <b style={{ fontSize: "12px" }}>{i18n.t(languageKeys.khach_hang)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                            <div style={{ marginTop: 80, fontWeight: "bold" }}>{data.benh_nhan.ten_benh_nhan}</div>
                        </div>

                        <div>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                {data?.ngay_tao ?
                                    <i>
                                        {data.gio ? data.gio + ", " : ""}{data.ngay_tao}
                                    </i>
                                    :
                                    <i
                                        style={{
                                            width: "50%",
                                            textAlign: "center",
                                            marginTop: 15,
                                        }}
                                    >
                                        {i18n.language === languages.tieng_viet
                                            ? (moment().format("HH:mm") +
                                                `,  ${i18n.t(languageKeys.common_ngay)} ` +
                                                moment().format("DD") +
                                                `  ${i18n.t(languageKeys.common_thang)} ` +
                                                moment().format("MM") +
                                                `  ${i18n.t(languageKeys.common_nam)} ` +
                                                +moment().format("YYYY"))
                                            : moment().format(enDateFormat)
                                        }
                                    </i>}
                            </div>
                            <b style={{ fontSize: "12px" }}>{i18n.t(languageKeys.data_Thu_ngan)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                            <div style={{ marginTop: 80, fontWeight: "bold" }}>{data?.ten_nhan_su || ""}</div>
                        </div>
                    </div>
                </div>
            </div>
        ));
};

export default PhieuThuGoiDv;
