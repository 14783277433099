import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import { formatNumberToPrice, HLog, layDiaChi, getSexFromString } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import { useSelector } from "react-redux";
import { uniq } from "lodash";
import { convertString } from "../update_1-11/PhieuChiDinhGreenLab";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const stylePhieuThuPhiDv = `
.thong-tin-kh-v2 {
  display: none;
}

.barcodeStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  position: relative;
  min-width: 150px;
  position: absolute;
  right: 2px;
  top: 8px;
}
#phieu-thu-phi-dv * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-thu-phi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-phi-dv .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-thu-phi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 10px; }
#phieu-thu-phi-dv .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-thu-phi-dv .subtitle { margin-block: 0.25rem; font-size: 12px; font-weight: bold; }
#phieu-thu-phi-dv .table * { line-height: 1.2em; }
#phieu-thu-phi-dv .table { page-break-inside:auto; }
#phieu-thu-phi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-phi-dv thead { display:table-header-group }
#phieu-thu-phi-dv tfoot { display:table-footer-group }
#phieu-thu-phi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-phi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-phi-dv .table th, .table td { padding: 5px; }
#phieu-thu-phi-dv .table th { text-align: start; }
#phieu-thu-phi-dv .noBorder2 { border-right: none; }
#phieu-thu-phi-dv .noBorder { border-left: none; }
#phieu-thu-phi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-phi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-phi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-phi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-phi-dv .des { font-style: italic; }
#phieu-thu-phi-dv .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-thu-phi-dv .phone-website { display: flex; align-items: center,}
#phieu-thu-phi-dv .fs12 { font-style: 12px; }

.txt-700 {
  font-weight: 700 !important;
}
@media print {
   #phieu-thu-phi-dv, #phieu-thu-phi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-phi-dv{page-break-after: always;}
}
`;

export const stylePhieuThuPhiDvGL = `
.thong-tin-kh-v2 {
  display: none;
}
#phieu-thu-phi-dv * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#phieu-thu-phi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

.barcodeStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  position: relative;
  min-width: 150px;
  // position: absolute;
  right: 2px;
  top: 8px;
}
#phieu-thu-phi-dv .fs12 { font-style: 12pt; }
#phieu-thu-phi-dv .header { display: flex; gap: 10px; position:relative; font-size: 14pt;}
#phieu-thu-phi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt; }
#phieu-thu-phi-dv .title { text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-thu-phi-dv .subtitle { margin-block: 0.25rem; font-size: 14pt; font-weight: bold; }
#phieu-thu-phi-dv .table * { line-height: 1.2em; }
#phieu-thu-phi-dv .table { page-break-inside:auto; }
#phieu-thu-phi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-phi-dv thead { display:table-header-group }
#phieu-thu-phi-dv tfoot { display:table-footer-group }
#phieu-thu-phi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-phi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-phi-dv .table th, .table td { padding: 5px; }
#phieu-thu-phi-dv .table th { text-align: start; }
#phieu-thu-phi-dv .noBorder2 { border-right: none; }
#phieu-thu-phi-dv .noBorder { border-left: none; }
#phieu-thu-phi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-phi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-phi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-phi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-phi-dv .des { font-style: italic; }
#phieu-thu-phi-dv .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.4em; transform: translateX(-50%);}
#phieu-thu-phi-dv .phone-website { display: flex; align-items: center}

.txt-700 {
  font-weight: 700;
}
@media print {
   #phieu-thu-phi-dv, #phieu-thu-phi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-phi-dv{page-break-after: always;}
}
`;

export const stylePhieuThuPhiDvIshi = `
.thong-tin-kh-v1 {
  display: none;
}

.barcodeStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  position: relative;
  min-width: 150px;
  // position: absolute;
  right: 2px;
  top: 8px;
}

#phieu-thu-phi-dv * { font-size: 16px; font-family: "Times New Roman", Times, serif; }
#phieu-thu-phi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-phi-dv .fs12 { font-style: 14px; }
#phieu-thu-phi-dv .header { display: flex; gap: 10px; position:relative; font-size: 14px;}
#phieu-thu-phi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16px; }
#phieu-thu-phi-dv .title { text-transform: uppercase; font-size: 20px; font-weight: bold; text-align: center; width: 90%; margin: 1.5rem auto 0.25rem auto;}
#phieu-thu-phi-dv .subtitle { margin-block: 0.25rem; font-size: 14px; font-weight: bold; }
#phieu-thu-phi-dv .table * { line-height: 1.2em; }
#phieu-thu-phi-dv .table { page-break-inside:auto; }
#phieu-thu-phi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-phi-dv thead { display:table-header-group }
#phieu-thu-phi-dv tfoot { display:table-footer-group }
#phieu-thu-phi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-phi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-phi-dv .table th, .table td { padding: 5px; }
#phieu-thu-phi-dv .table th { text-align: start; }
#phieu-thu-phi-dv .noBorder2 { border-right: none; }
#phieu-thu-phi-dv .noBorder { border-left: none; }
#phieu-thu-phi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-phi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-phi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-phi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-phi-dv .des { font-style: italic; }
#phieu-thu-phi-dv .sub-barcode-text { font-size:14px;position:absolute;top:50px; left:50%; line-height: 14px; transform: translateX(-50%);}
#phieu-thu-phi-dv .phone-website { display: flex; align-items: center}
.txt-700 {
  font-weight: 700;
}
@media print {
   #phieu-thu-phi-dv, #phieu-thu-phi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-phi-dv{page-break-after: always;}
}
`;


export const stylePhieuThuPhiDvAivi = `
.thong-tin-kh-v1 {
  display: none;
}

.barcodeStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  position: relative;
  min-width: 150px;
  // position: absolute;
  right: 2px;
  top: 0px;
}

#phieu-thu-phi-dv * { font-size: 14px; font-family: "Times New Roman", Times, serif; }
#phieu-thu-phi-dv .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-phi-dv .fs12 { font-style: 14px; }
#phieu-thu-phi-dv .header { display: flex; gap: 10px; position:relative; font-size: 14px;}
#phieu-thu-phi-dv .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 14px; }
#phieu-thu-phi-dv .title { text-transform: uppercase; font-size: 18px; font-weight: bold; text-align: center; width: 90%; margin: 30px auto 0.10px auto;}
#phieu-thu-phi-dv .subtitle { margin-block: 0.25rem; font-size: 14px; font-weight: bold; }
#phieu-thu-phi-dv .table * { line-height: 1.2em; }
#phieu-thu-phi-dv .table { page-break-inside:auto; }
#phieu-thu-phi-dv tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-phi-dv thead { display:table-header-group }
#phieu-thu-phi-dv tfoot { display:table-footer-group }
#phieu-thu-phi-dv .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-thu-phi-dv .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-phi-dv .table th, .table td { padding: 5px; }
#phieu-thu-phi-dv .table th { text-align: start; }
#phieu-thu-phi-dv .noBorder2 { border-right: none; }
#phieu-thu-phi-dv .noBorder { border-left: none; }
#phieu-thu-phi-dv .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-thu-phi-dv .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-thu-phi-dv .anotherFoot { display: flex; padding-top: 10px; }
#phieu-thu-phi-dv .anotherFoot > div { width: 50%; text-align: center; }
#phieu-thu-phi-dv .des { font-style: italic; }
#phieu-thu-phi-dv .sub-barcode-text { font-size:14px;position:absolute;top:50px; left:50%; line-height: 14px; transform: translateX(-50%);}
#phieu-thu-phi-dv .phone-website { display: flex; align-items: center}
.txt-700 {
  font-weight: 700;
}

.web, .email {
  display: none;
}
@media print {
   #phieu-thu-phi-dv, #phieu-thu-phi-dv .foot-break { break-inside: avoid; }
   #phieu-thu-phi-dv{page-break-after: always;}
}
`;



const PhieuThuPhiDv = ({ data = { benh_nhan: {}, nhom_dv: [], hospital: {}, nhom: "", }, rid }) => {

  // console.log("BENH_NHAN" , data.benh_nhan)
  const userProfile = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!!rid && !!data.benh_nhan.SO_PHIEU) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(data.benh_nhan.SO_PHIEU, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data.benh_nhan.SO_PHIEU, rid]);

  const calculator = () => {
    const giatriMacDinh = "";
    return {
      soLuong: () => {
        try {
          let value = 0;
          data.nhom_dv.forEach(item => {
            const soLuongCon = item.dich_vu.reduce((acc, curr) => acc + +(curr.so_luong || curr.SO_LUONG), 0)
            value += soLuongCon
          });
          return formatNumberToPrice(+value);
        } catch (error) {
          return giatriMacDinh
        }
      },
      tongTien: () => {
        try {
          let value = 0;
          data.nhom_dv.forEach(item => {
            const tongTienCon = item.dich_vu.reduce(
              (acc, dich_vu) => acc + +(dich_vu.don_gia * +(dich_vu.so_luong || dich_vu.SO_LUONG)), 0)
            value += tongTienCon
          });
          return (+value);
        } catch (error) {
          return giatriMacDinh
        }
      },
      mienGiam: () => {
        try {
          let value = 0;
          data.nhom_dv.forEach(item => {
            const mienGiamCon = item.dich_vu.reduce((acc, dich_vu) => acc + +(dich_vu.mien_giam), 0)
            value += mienGiamCon
          });
          return (+value);
        } catch (error) {
          return giatriMacDinh
        }
      },
      thanhTien: () => {
        try {
          let value = 0;
          value = calculator().tongTien() - calculator().mienGiam()
          return (+value);
        } catch (error) {
          return giatriMacDinh
        }
      },
      sum: (arr_dich_vu, key, onlyValue = false) => {
        try {
          const value = arr_dich_vu.reduce((acc, curr) => {
            let finalKey = Object.keys(curr).includes(key) ? key : key.toUpperCase()
            return acc + +curr[finalKey];
          }, 0)
          if (!onlyValue)
            return formatNumberToPrice(value)
          else return value
        } catch (error) {
          return ""
        }
      },
      thanhTienTungDv: (arr_dich_vu) => {
        try {
          let value = arr_dich_vu.reduce((thanhTien, dv) => {
            return thanhTien + ((dv.so_luong ?? dv.SO_LUONG) * dv.don_gia) - dv.mien_giam
          }, 0)
          return formatNumberToPrice(+value)

        } catch (error) {
          HLog(error, 'error calculating');
          return ""
        }
      }
    }
  }

  const onlyOne = ['aivi052428', '0018nadk', '0018dk248', '0018htdk'].includes(userProfile.partner_code) || data.QR

  // const tinhTuoi = () => {
  //   try {

  //     if (data.benh_nhan?.ngay_sinh.includes('/')) {
  //       return convertMillisecondToAge(convertValueToMilisecond(moment(data.benh_nhan?.ngay_sinh, 'DD/MM/YYYY').format('YYYYMMDD')))
  //     } else {
  //       return convertMillisecondToAge(convertValueToMilisecond(data.benh_nhan?.ngay_sinh))
  //     }

  //   } catch (error) {
  //     return data.benh_nhan.tuoi
  //   }

  // }

  return Array(onlyOne ? 1 : 2)
    .fill(null)
    .map((_, index) => (
      <div id="phieu-thu-phi-dv" style={index === 0 ? {} : {}} key={index}>
        <div className="header">
          {data.hospital.avatar &&
            <Images className="logo" imgId={data.hospital.avatar} />
          }
          <div className="content-header">
            <h2 style={{ margin: 0 }} className="hospital-name ">
              {userProfile?.benh_vien?.TEN_CSKCB}
            </h2>
            <div style={{ wordWrap: 'break-word' }}>
              <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
            </div>
            <div className="flex">
              <div className="phone-website">
                <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
              </div>
            </div>
            <div>
              <span className="web txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
            <div>
              <span className="email txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
            </div>
          </div>
          <div className="barcodeStyle">
            <span><svg id={`barcode-${rid}`}></svg></span>
            <div className="sub-barcode-text">
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.ma_kh)}:<b> {data.benh_nhan.ma_benh_nhan}</b>
              </div>
              {!data.benh_nhan?.printAll && <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.field_So_phieu)}: <b>{data.benh_nhan.SO_PHIEU}</b>
              </div>}
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.ma_ca_kham)}: <b>{data?.benh_nhan?.MA_CUOC_KHAM || ""}</b>
              </div>
            </div>
          </div>
        </div>

        <div className="title">
          {data.nhom === "HOAN_TIEN"
            ? i18n.t(languageKeys.tab_Phieu_hoan_tien).toUpperCase()
            : i18n.t(languageKeys.title_Phieu_thu_phi_dich_vu).toUpperCase()}
        </div>

        {onlyOne
          ? <></>
          : (
            <div className="subtitle" style={{ textAlign: "center", lineHeight: "1em", marginBottom: '1rem' }}>
              ({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})
            </div>
          )
        }

        <div>
          <div className="thong-tin-kh-v1">
            <div className="subtitle">{i18n.t(languageKeys.thong_tin_khach_hang)}</div>
            <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
              <div style={{ display: "flex", gap: "20%" }}>
                <div>
                  {i18n.t(languageKeys.ho_va_ten)}: <b> {data.benh_nhan.ten_benh_nhan}</b>
                </div>
              </div>

              <div style={{ display: "flex", gap: "20%" }}>
                <div>
                  {i18n.t(languageKeys.field_Ngay_sinh)}: {data.benh_nhan?.ngay_sinh.split("/").pop()}
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(data.benh_nhan.gioi_tinh)}
                </div>
              </div>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
              <div>
                {i18n.t(languageKeys.field_So_dien_thoai)}: {data.benh_nhan.sdt}
              </div>
              <div>
                {i18n.t(languageKeys.field_Doi_tuong)}: {data?.resDT
                  ? uniq(data?.resDT?.flatMap(i => i?.TEN_DOI_TUONG)).join(", ")
                  : uniq(data?.nhom_dv?.flatMap(i => i?.dich_vu)?.map(i => i?.ten_doi_tuong))?.join(", ")
                }
              </div>
            </div>
            <div>
              {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi}
            </div>

          </div>

          <div className="thong-tin-kh-v2">
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
              <div >
                {i18n.t(languageKeys.ho_ten)}: <b> {data.benh_nhan.ten_benh_nhan}</b>
              </div>

              <div style={{ display: "flex", gap: 24 }}>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {data.benh_nhan.gioi_tinh}
                </div>
                <div>
                  {i18n.t(languageKeys.field_Ngay_sinh)}: {data.benh_nhan?.ngay_sinh.split("/").pop()}

                </div>
              </div>

              <div style={{ display: "grid" }}>
                <span style={{ display: "flex" }}>{i18n.t(languageKeys.so_dien_thoai)}:<b>{data.benh_nhan.sdt}</b></span>
              </div>
              <div>
                {i18n.t(languageKeys.field_Doi_tuong)}: {data?.resDT
                  ? uniq(data?.resDT?.flatMap(i => i?.TEN_DOI_TUONG)).join(", ")
                  : uniq(data?.nhom_dv?.flatMap(i => i?.dich_vu)?.map(i => i?.ten_doi_tuong))?.join(", ")
                }
              </div>
            </div>
            <div>
              {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi}
            </div>

          </div>

          <div>
            {data.nhom === "HOAN_TIEN" ? (
              <div>
                {i18n.t(languageKeys.field_Ly_do_hoan_tien)} : {data.benh_nhan.ly_do_hoan_tien}
              </div>
            ) : (
              <div>
                {i18n.t(languageKeys.field_Ly_do_mien_giam)} : {data.benh_nhan.ly_do_mien_giam}
              </div>
            )}
          </div>

          <table style={{ width: "100%" }} className="table">
            <thead>
              <tr>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
                {/* <th>{i18n.t(languageKeys.field_Don_vi)}</th> */}
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_So_luong)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.tong_tien)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Mien_giam)}</th>
                <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
              </tr>
            </thead>

            <tbody>
              {data.nhom_dv?.map((nhom_dv, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <th className="noBorder2" colSpan="2">
                        {nhom_dv.nhom_dv}
                      </th>
                      {/* <th colSpan="1">
                      </th> */}
                      <th colSpan="1" align="center" style={{ textAlign: 'center' }}>
                        {calculator().sum(nhom_dv.dich_vu, 'so_luong')}
                      </th>
                      <th colSpan="1" style={{ textAlign: "end" }}>
                      </th>
                      <th className="noBorder" style={{ textAlign: "end" }}>{formatNumberToPrice(countSum(nhom_dv.dich_vu))}</th>

                      <th colSpan="1" style={{ textAlign: "end" }}>
                        {calculator().sum(nhom_dv.dich_vu, 'mien_giam')}
                      </th>
                      <th colSpan="1" style={{ textAlign: "end" }}>
                        {calculator().thanhTienTungDv(nhom_dv.dich_vu)}
                      </th>
                    </tr>

                    {nhom_dv.dich_vu.map((dich_vu, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td style={{ textAlign: 'center' }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                            <td>{dich_vu.ten_dich_vu}</td>
                            {/* <td>{dich_vu.don_vi}</td> */}
                            <td style={{ textAlign: 'center' }}>{dich_vu.so_luong || dich_vu.SO_LUONG}</td>
                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(dich_vu.don_gia)}</td>
                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(+dich_vu.don_gia * +(dich_vu.so_luong || dich_vu.SO_LUONG))}</td>
                            <td style={{ textAlign: "end" }}>{formatNumberToPrice(dich_vu.mien_giam)}</td>
                            <td style={{ textAlign: "end" }}>{formatNumberToPrice((+dich_vu.don_gia * +(dich_vu.so_luong || dich_vu.SO_LUONG)) - +dich_vu.mien_giam)}</td>
                          </tr>
                          {userProfile.config.HIEN_THI_KEM_THEO && dich_vu.dv_kem_theo?.map((dvkt, i) => (
                            <tr key={i}>
                              <td></td>
                              <td style={{ paddingLeft: 30 }}>{dvkt.ten_dich_vu || dvkt.TEN_DICHVU}</td>
                              <td style={{ textAlign: 'center' }}>{dvkt.so_luong || dvkt.SO_LUONG}</td>
                              <td style={{ textAlign: "end" }}>-</td>
                              <td style={{ textAlign: "end" }}>-</td>
                              <td style={{ textAlign: "end" }}>-</td>
                              <td style={{ textAlign: "end" }}>-</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}

              <tr>
                <td colSpan='2'>
                  <b>{i18n.t(languageKeys.bao_cao_tong_cong)}</b>
                </td>
                <td colSpan="1" style={{ textAlign: "center", borderRight: "none", fontWeight: "bold" }}>
                  {data.nhom_dv
                    ? formatNumberToPrice(calculator().soLuong())
                    : data.dv_goi.reduce((acc, obj) => {
                      return acc + (
                        obj.DV_KEM_THEO
                          ? obj.DV_KEM_THEO.reduce((cal, item) => { return cal + item.SO_LUONG }, 0)
                          : obj.SO_LUONG
                      );
                    }, 0)
                  }
                </td>
                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                </td>
                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                  {formatNumberToPrice(data.nhom_dv ? calculator().tongTien() : data.tong_chi_phi)}
                </td>
                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                  {formatNumberToPrice(data.nhom_dv ? calculator().mienGiam() : data.mien_giam)}
                </td>
                <td colSpan="1" style={{ textAlign: "end", borderRight: "none", fontWeight: "bold" }}>
                  {formatNumberToPrice(data.nhom_dv ? calculator().thanhTien() : data.tong_thanh_toan)}
                </td>
              </tr>
            </tbody>
          </table>

          {data.nhom === "THANH_TOAN" && (
            <div className="foot-break">
              {/* {data.nhom_dv ? (
                <div>
                  {i18n.t(languageKeys.cong_khoan)} :{" "}
                  <b>{countQuantity(data.nhom_dv) < 10 ? `0${countQuantity(data.nhom_dv)}` : countQuantity(data.nhom_dv)}</b>
                </div>
              ) : <div />} */}

              {/* <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <div>(1) {i18n.t(languageKeys.tong_tien)}:</div>
                  {data.nhom_dv
                    ? <b>{formatNumberToPrice(countToTalSum(data.nhom_dv))}</b>
                    : <b>{formatNumberToPrice(data.tong_chi_phi)}</b>
                  }

                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <div>(2) {i18n.t(languageKeys.field_Mien_giam)}:</div>
                  <b>{formatNumberToPrice(data.mien_giam)}</b>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 20,
                    alignItems: "center",
                    borderTop: "1px solid #000",
                    marginTop: 5,
                    paddingTop: 5,
                  }}
                >
                  <div>(3) = (1) - (2) {i18n.t(languageKeys.tong_thanh_toan)}:</div>
                  {data.nhom_dv
                    ? <b>{formatNumberToPrice(countToTalSum(data.nhom_dv) - data.mien_giam)}</b>
                    : <b>{formatNumberToPrice(data.tong_thanh_toan)}</b>
                  }
                </div>
              </div> */}

              <div style={{ display: "flex", justifyContent: "space-between", margin: '15px 0 12px' }}>
                <div>
                  <b>{i18n.t(languageKeys.tong_tien)}: </b>
                  <b>{formatNumberToPrice(data.nhom_dv ? calculator().tongTien() : data.tong_chi_phi, 'đ')}</b>
                </div>
                <div>
                  <b>{i18n.t(languageKeys.field_Mien_giam)}: </b>
                  <b>{formatNumberToPrice(data.nhom_dv ? calculator().mienGiam() : data.mien_giam, 'đ')}</b>
                </div>
                <div>
                  <b>{i18n.t(languageKeys.vien_phi_thanh_tien)}: </b>
                  <b>{formatNumberToPrice(data.nhom_dv ? calculator().thanhTien() : data.tong_thanh_toan, 'đ')}</b>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>

                {data.QR ? (
                  <img
                    src={`data:image/png;base64, ${data.QR}`}
                    // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    height={200}
                    // width={'100%'}
                    alt="QR"
                  />
                ) : <div />}

                <div>
                  {data?.cac_phuong_thuc_tt?.map((httt, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 40,
                        alignItems: "center",
                      }}
                    >
                      <div>{i18n.t(languageKeys.field_Thanh_toan) + " " + Object.keys(httt)[0]}:</div>
                      <div>{formatNumberToPrice(Object.values(httt)[0])}</div>
                    </div>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 40,
                      alignItems: "center",
                      borderTop: "1px solid #000",
                      marginTop: 5,
                      paddingTop: 5,
                    }}
                  >
                    <b>{i18n.t(languageKeys.tong_tien_kh_dua)}:</b>
                    <b>{formatNumberToPrice(data.tong_tien_khach_dua)}</b>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 40,
                      alignItems: "center",
                    }}
                  >
                    <b>{i18n.t(languageKeys.tong_can_thanh_toan)}:</b>
                    <b>{formatNumberToPrice(data.nhom_dv ? calculator().thanhTien() : data.tong_thanh_toan)}</b>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 40,
                      alignItems: "center",
                    }}
                  >
                    <b>{i18n.t(languageKeys.so_du_cong_no)}:</b>
                    <b style={{ color: '#f34946' }}>{formatNumberToPrice(Math.abs(data.so_du_cong_no))}</b>
                  </div>
                </div>

              </div>

            </div>
          )}

        </div>

        <div className="foot-break" style={{ minHeight: "20vh", marginTop: '20px' }}>

          <div className="anotherFoot">
            <div style={{ marginTop: 16 }}>
              <b className="fs12">{i18n.t(languageKeys.khach_hang)}</b>
              <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
              <div style={{ marginTop: 80, fontWeight: "bold" }}>{data.benh_nhan.ten_benh_nhan}</div>
            </div>

            <div>
              <div style={{ display: "flex", justifyContent: "center", }}>
                {data?.ngay_tao ?
                  <i>
                    {i18n.language === languages.tieng_viet
                      ? (data.gio ? data.gio + ", " + data.ngay_tao : data.ngay_tao)
                      : moment(data.gio ? `${data.ngay} ${data.gio}` : data.ngay, data.gio ? 'YYYYMMDD HH:mm' : 'YYYYMMDD').format(enDateFormat)
                    }
                  </i>
                  :
                  <i
                    style={{
                      // width: "50%",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    {i18n.language === languages.tieng_viet
                      ? (moment().format("HH:mm") +
                        `,  ${i18n.t(languageKeys.common_ngay)} ` +
                        moment().format("DD") +
                        `  ${i18n.t(languageKeys.common_thang)} ` +
                        moment().format("MM") +
                        `  ${i18n.t(languageKeys.common_nam)} ` +
                        +moment().format("YYYY"))
                      : moment().format(enDateFormat)
                    }
                  </i>}
              </div>
              <b className="fs12">{i18n.t(languageKeys.field_nguoi_ban_thuoc)}</b>
              <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
              <div style={{ marginTop: 80, fontWeight: "bold" }}>{data?.ten_nhan_su || ""}</div>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default PhieuThuPhiDv;

// ham tinh tong ds dich vu
const countSum = (ds_dich_vu = []) => {
  return ds_dich_vu.reduce((prevVal, dich_vu) => {
    return prevVal + Number(dich_vu.thanh_tien);
  }, 0);
};
