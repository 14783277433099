const languageKeys = {
  ngon_ngu_tieng_viet: "ngon_ngu_tieng_viet",
  ngon_ngu_tieng_anh: "ngon_ngu_tieng_anh",

  common_ngay: "common_ngay",
  common_ngay_kham: "common_ngay_kham",
  common_thang: "common_thang",
  common_nam: "common_nam",
  common_tuan: "common_tuan",
  common_khong_co_quyen: "common_khong_co_quyen",
  common_huy: 'common_huy',
  // Tiêu đề menu
  menu_Bang_dieu_khien: "menu_Bang_dieu_khien",
  menu_Quan_ly_tiep_don: "menu_Quan_ly_tiep_don",
  menu_Quan_ly_he_thong: "menu_Quan_ly_he_thong",
  menu_Quan_ly_danh_muc: "menu_Quan_ly_danh_muc",
  menu_Quan_ly_nguoi_dung: "menu_Quan_ly_nguoi_dung",
  menu_Thong_tin_cskcb: "menu_Thong_tin_cskcb",
  menu_Quan_ly_vien_phi: "menu_Quan_ly_vien_phi",
  menu_Quan_ly_thanh_toan: "menu_Quan_ly_thanh_toan",
  menu_Quan_ly_so_hoa_don: "menu_Quan_ly_so_hoa_don",
  menu_Quan_ly_kham_benh: "menu_Quan_ly_kham_benh",
  menu_Quan_ly_chan_doan_hinh_anh_ris: "menu_Quan_ly_chan_doan_hinh_anh_ris",
  menu_Quan_ly_bhyt: "menu_Quan_ly_bhyt",
  menu_Quan_ly_duoc_ngoai_tru: "menu_Quan_ly_duoc_ngoai_tru",
  menu_Kho_thuoc: "menu_Kho_thuoc",
  menu_Quan_ly_xep_hang_qms: "menu_Quan_ly_xep_hang_qms",
  menu_Quan_ly_dich_vu_can_thanh_toan: "menu_Quan_ly_dich_vu_can_thanh_toan",
  menu_Quan_ly_xet_nghiem_lis: "menu_Quan_ly_xet_nghiem_lis",
  menu_Lich_lam_viec: "menu_Lich_lam_viec",
  menu_Ban_thuoc: "menu_Ban_thuoc",
  menu_Phieu_xuat_nhap: "menu_Phieu_xuat_nhap",
  menu_Kho_duoc_ngoai_tru: "menu_Kho_duoc_ngoai_tru",
  app_loi_ket_noi: "app_loi_ket_noi",
  menu_Bao_cao: "menu_Bao_cao",
  menu_Quan_ly_benh_nhan: "menu_Quan_ly_benh_nhan",
  limit_thoi_gian_1_nam: "limit_thoi_gian_1_nam",
  menu_Ho_tro_khach_hang: "menu_Ho_tro_khach_hang",
  menu_phauthuat_thuthuat: "menu_phauthuat_thuthuat",
  dm_thuthuat_ma_cttt: "dm_thuthuat_ma_cttt",
  dm_thuthuat_ten_cttt: "dm_thuthuat_ten_cttt",
  dm_thuthuat_ma_pptt: "dm_thuthuat_ma_pptt",
  dm_thuthuat_ten_pptt: "dm_thuthuat_ten_pptt",
  field_thuthuat_ma_cttt: "field_thuthuat_ma_cttt",
  field_thuthuat_ten_cttt: "field_thuthuat_ten_cttt",
  field_thuthuat_ma_pptt: "field_thuthuat_ma_pptt",
  field_thuthuat_ten_pptt: "field_thuthuat_ten_pptt",

  // Danh sách danh mục (quản lý danh mục)
  cate_benh_vien: "cate_benh_vien",
  cate_DM: "cate_DM",
  cate_Danh_muc: "cate_Danh_muc",
  cate_khoa: "cate_khoa",
  cate_phong: "cate_phong",
  cate_dich_vu_ki_thuat: "cate_dich_vu_ki_thuat",
  cate_nhom_dv_ki_thuat: "cate_nhom_dv_ki_thuat",
  cate_loai_dv_ki_thuat: "cate_loai_dv_ki_thuat",
  cate_nhom_hoa_don: "cate_nhom_hoa_don",
  cate_thuoc: "cate_thuoc",
  cate_nha_thuoc: "cate_nha_thuoc",
  cate_hang_san_xuat: "cate_hang_san_xuat",
  cate_duong_dung: "cate_duong_dung",
  cate_hoat_chat: "cate_hoat_chat",
  cate_don_vi_tinh: "cate_don_vi_tinh",
  cate_kho_tu_truc: "cate_kho_tu_truc",
  cate_tinh: "cate_tinh",
  cate_huyen: "cate_huyen",
  cate_xa: "cate_xa",
  cate_nghe_nghiep: "cate_nghe_nghiep",
  cate_dan_toc: "cate_dan_toc",
  cate_quoc_gia: "cate_quoc_gia",
  cate_icd10: "cate_icd10",
  cate_nhom_icd10: "cate_nhom_icd10",
  cate_so_hoa_don: "cate_so_hoa_don",
  cate_giuong: "cate_giuong",
  cate_dv_giuong: "cate_dv_giuong",
  cate_cach_thuc_PTTT: "cate_cach_thuc_PTTT",
  cate_phuong_phap_PTTT: "cate_phuong_phap_PTTT",
  cate_pp_vo_cam: "cate_pp_vo_cam",
  cate_xuat_khac: "cate_xuat_khac",
  cate_nguon: "cate_nguon",
  cate_kho_thuoc: "cate_kho_thuoc",
  cate_nguon_khach: "cate_nguon_khach",
  cate_doi_tuong: "cate_doi_tuong",
  cate_cach_dung: "cate_cach_dung",
  cate_hinh_thuc_thanh_toan: "cate_hinh_thuc_thanh_toan",

  // Danh sách trường thông tin (bao gồm: tên cột của table, label của form)
  field_Ma_cskcb: "field_Ma_cskcb",
  field_Ten_benh_vien: "field_Ten_benh_vien",
  field_Tuyen: "field_Tuyen",
  field_Hang: "field_Hang",
  field_Dia_chi: "field_Dia_chi",
  field_Ma_khoa: "field_Ma_khoa",
  field_Ma_may: "field_Ma_may",

  field_Ten_khoa: "field_Ten_khoa",
  field_Ma_khoa_byt: "field_Ma_khoa_byt",
  field_Truong_khoa: "field_Truong_khoa",
  field_Loai_khoa: "field_Loai_khoa",
  field_Ma_phong: "field_Ma_phong",
  field_Ten_phong: "field_Ten_phong",
  field_Khoa: "field_Khoa",
  field_Loai_phong: "field_Loai_phong",
  field_Phong_chi_dinh: "field_Phong_chi_dinh",
  field_Stt: "field_Stt",
  field_Ma_dich_vu: "field_Ma_dich_vu",
  field_Ma_phieu_chi_dinh: "field_Ma_phieu_chi_dinh",
  field_Bac_si_chi_dinh: "field_Bac_si_chi_dinh",
  field_Ten_dich_vu: "field_Ten_dich_vu",
  field_Ten_dich_vu_theo_byt: "field_Ten_dich_vu_theo_byt",
  field_Don_vi_tinh: "field_Don_vi_tinh",
  field_Thong_tin_co_so: "field_Thong_tin_co_so",
  field_Thong_tin_co_ban: "field_Thong_tin_co_ban",
  field_Ten_cskcb: "field_Ten_cskcb",
  field_Tinh_thanh: "field_Tinh_thanh",
  field_Quan_huyen: "field_Quan_huyen",
  field_Xa_phuong: "field_Xa_phuong",
  field_Dia_chi_chi_tiet: "field_Dia_chi_chi_tiet",
  field_Ghi_chu: "field_Ghi_chu",
  field_Hien_thi: "field_Hien_thi",
  field_Thao_tac: "field_Thao_tac",
  field_Nhom_dich_vu: "field_Nhom_dich_vu",
  field_Loai_dich_vu: "field_Loai_dich_vu",
  field_Nhom_bhyt: "field_Nhom_bhyt",
  field_nhom_hoa_don: "field_nhom_hoa_don",
  field_Gia: "field_Gia",
  field_Gia_thu_phi: "field_Gia_thu_phi",
  field_Gia_bhyt: "field_Gia_bhyt",
  field_Gia_dich_vu: "field_Gia_dich_vu",
  field_Gia_nuoc_ngoai: "field_Gia_nuoc_ngoai",
  field_Thong_tin_khac: "field_Thong_tin_khac",
  field_Ti_le_dung_tuyen: "field_Ti_le_dung_tuyen",
  field_Ti_le_trai_tuyen: "field_Ti_le_trai_tuyen",
  field_So_ngay_tai_chi_dinh: "field_So_ngay_tai_chi_dinh",
  field_Phong_thuc_hien: "field_Phong_thuc_hien",
  field_Phong_lay_mau: "field_Phong_lay_mau",
  field_chon_loai_thu_thuat: "field_chon_loai_thu_thuat",
  field_Chi_so_xet_nghiem: "field_Chi_so_xet_nghiem",
  field_Don_vi_chi_so: "field_Don_vi_chi_so",
  field_Giai_ket_qua_chi_so: "field_Giai_ket_qua_chi_so",
  field_Cho_phep_mien_giam: "field_Cho_phep_mien_giam",
  field_Cho_sua_gia_khi_chi_dinh: "field_Cho_sua_gia_khi_chi_dinh",
  field_Ma_nhom: "field_Ma_nhom",
  field_Ten_nhom: "field_Ten_nhom",
  field_Ma_nhom_hoa_don: "field_Ma_nhom_hoa_don",
  field_Ten_nhom_hoa_don: "field_Ten_nhom_hoa_don",
  field_Ma_loai_dich_vu_ki_thuat: "field_Ma_loai_dich_vu_ki_thuat",
  field_Ten_loai_dich_vu_ki_thuat: "field_Ten_loai_dich_vu_ki_thuat",
  field_Id_loai_dv_ki_thuat: "field_Id_loai_dv_ki_thuat",
  field_Nhom_dv_ki_thuat: "field_Nhom_dv_ki_thuat",
  field_Ten_loai_dv_ki_thuat: "field_Ten_loai_dv_ki_thuat",
  field_Id_nhom: "field_Id_nhom",
  field_Ma_hang_san_xuat: "field_Ma_hang_san_xuat",
  field_Ten_hang_san_xuat: "field_Ten_hang_san_xuat",
  field_Ma_duong_dung: "field_Ma_duong_dung",
  field_Ma_duong_dung_theo_byt: "field_Ma_duong_dung_theo_byt",
  field_ten_duong_dung: "field_ten_duong_dung",
  field_Ma_kho: "field_Ma_kho",
  field_Ten_kho: "field_Ten_kho",
  field_Thu_kho: "field_Thu_kho",
  field_Loai_kho: "field_Loai_kho",
  field_Kieu_kho: "field_Kieu_kho",
  field_Ma_tinh: "field_Ma_tinh",
  field_Ma_tinh_byt: "field_Ma_tinh_byt",
  field_Ten_tinh: "field_Ten_tinh",
  field_Ten_Tinh_thanh: "field_Ten_Tinh_thanh",
  field_Id_huyen: "field_Id_huyen",
  field_Ma_huyen_byt: "field_Ma_huyen_byt",
  field_Ten_huyen: "field_Ten_huyen",
  field_Ten_Quan_huyen: "field_Ten_Quan_huyen",
  field_Ma_xa: "field_Ma_xa",
  field_Ma_xa_byt: "field_Ma_xa_byt",
  field_Ten_xa: "field_Ten_xa",
  field_Ten_Xa_phuong: "field_Ten_Xa_phuong",
  field_Ma_nghe_nghiep: "field_Ma_nghe_nghiep",
  field_Ten_nghe_nghiep: "field_Ten_nghe_nghiep",
  field_Ma_dan_toc: "field_Ma_dan_toc",
  field_Ten_dan_toc: "field_Ten_dan_toc",
  field_Ma_icd: "field_Ma_icd",
  field_Ten_icd: "field_Ten_icd",
  field_Nhom_icd: "field_Nhom_icd",
  field_Loai_icd: "field_Loai_icd",
  field_Ma_nhom_icd: "field_Ma_nhom_icd",
  field_Ten_nhom_icd: "field_Ten_nhom_icd",
  field_Ma_quoc_gia: "field_Ma_quoc_gia",
  field_Ten_quoc_gia: "field_Ten_quoc_gia",
  field_Ten_goi_khac: "field_Ten_goi_khac",
  field_Ten_viet_tat: "field_Ten_viet_tat",
  field_Benh_nhan: "field_Benh_nhan",
  field_Dich_vu_su_dung: "field_Dich_vu_su_dung",
  field_Thanh_toan: "field_Thanh_toan",
  field_Trang_thai: "field_Trang_thai",
  field_Thong_tin_hanh_chinh: "field_Thong_tin_hanh_chinh",
  field_Tim_kiem_benh_nhan: "field_Tim_kiem_benh_nhan",
  field_Tim_kiem_thuoc: "field_Tim_kiem_thuoc",
  field_Doi_tuong_benh_nhan: "field_Doi_tuong_benh_nhan",
  field_Uu_tien: "field_Uu_tien",
  field_Ma_benh_nhan: "field_Ma_benh_nhan",
  field_Ten_benh_nhan: "field_Ten_benh_nhan",
  field_So_dien_thoai: "field_So_dien_thoai",
  field_Email: "field_Email",
  field_Gioi_tinh: "field_Gioi_tinh",
  field_Ngay_sinh: "field_Ngay_sinh",
  field_THX: "field_THX",
  field_Nghe_nghiep: "field_Nghe_nghiep",
  field_TE_co_the_bhyt: "field_TE_co_the_bhyt",
  field_Dan_toc: "field_Dan_toc",
  field_Quoc_gia: "field_Quoc_gia",
  field_Thong_tin_bhyt: "field_Thong_tin_bhyt",
  field_The_bhyt: "field_The_bhyt",
  field_Han_the_tu: "field_Han_the_tu",
  field_Han_the_den: "field_Han_the_den",
  field_Ngay_mien_cung_chi_tra: "field_Ngay_mien_cung_chi_tra",
  field_Ngay_dong_han_du_5_nam: "field_Ngay_dong_han_du_5_nam",
  field_Noi_song: "field_Noi_song",
  field_Thong_tin_kham: "field_Thong_tin_kham",
  field_Yeu_cau_kham: "field_Yeu_cau_kham",
  field_Chon_phong_kham: "field_Chon_phong_kham",
  field_Ma_cccd: "field_Ma_cccd",
  field_So_phong: "field_So_phong",
  field_Benh_nhan_cho_kham: "field_Benh_nhan_cho_kham",
  field_In_phieu_kham: "field_In_phieu_kham",
  field_doi_tuong_BHYT: "field_doi_tuong_BHYT",
  field_doi_tuong_yeu_cau: "field_doi_tuong_yeu_cau",
  field_doi_tuong_vien_phi: "field_doi_tuong_vien_phi",
  field_doi_tuong_nuoc_ngoai: "field_doi_tuong_nuoc_ngoai",
  field_doi_tuong_mien_giam: "field_doi_tuong_mien_giam",
  field_ton_kho: "field_ton_kho",
  field_gia_ban: "field_gia_ban",
  field_gia_nhap: "field_gia_nhap",
  field_tinh_nang: "field_tinh_nang",
  field_ma_ly_do: "field_ma_ly_do",
  field_ly_do: "field_ly_do",
  field_ma_nguon: "field_ma_nguon",
  field_ten_nguon: "field_ten_nguon",
  field_ten_nguon_khach: "field_ten_nguon_khach",
  field_so_dang_ky_thuoc: "field_so_dang_ky_thuoc",
  field_gio_bat_dau: "field_gio_bat_dau",
  field_gio_ket_thuc: "field_gio_ket_thuc",
  field_thoi_gian_hoat_dong: "field_thoi_gian_hoat_dong",
  field_thoi_gian: "field_thoi_gian",
  field_chua_cai_dat_thoi_gian: "field_chua_cai_dat_thoi_gian",
  field_gia_vien_phi: "field_gia_vien_phi",
  field_ten_khach_hang: "field_ten_khach_hang",
  field_nguoi_ban_thuoc: "field_nguoi_ban_thuoc",

  /// thuốc
  field_ma_thuoc: "field_ma_thuoc",
  field_ma_thuoc_bhyt: "field_ma_thuoc_bhyt",
  field_ten_thuoc: "field_ten_thuoc",
  field_lo_thuoc: "field_lo_thuoc",
  field_Ten_thuoc_bhyt: "field_Ten_thuoc_bhyt",
  field_stt_bhyt: "field_stt_bhyt",
  field_ten_hoat_chat: "field_ten_hoat_chat",
  field_don_vi_tinh: "field_don_vi_tinh",
  field_nong_do: "field_nong_do",
  field_the_tich: "field_the_tich",
  field_ham_luong: "field_ham_luong",
  field_ma_hoat_chat: "field_ma_hoat_chat",
  field_nuoc_san_xuat: "field_nuoc_san_xuat",
  field_hang_san_xuat: "field_hang_san_xuat",
  field_quy_cach_dong_goi: "field_quy_cach_dong_goi",
  field_ATC_code: "field_ATC_code",
  field_thuoc_khang_sinh: "field_thuoc_khang_sinh",
  field_che_pham_y_hoc_co_truyen: "field_che_pham_y_hoc_co_truyen",
  field_cho_phep_mien_giam: "field_cho_phep_mien_giam",
  field_thuoc_tan_duoc: "field_thuoc_tan_duoc",
  field_thuoc_ke_don: "field_thuoc_ke_don",
  field_vi_thuoc_y_hoc: "field_vi_thuoc_y_hoc",
  field_thuc_pham_chuc_nang: "field_thuc_pham_chuc_nang",
  field_tam_dung_nhap_NCC: "field_tam_dung_nhap_NCC",
  field_ma_hoat_chat_bhyt: "field_ma_hoat_chat_bhyt",
  field_ngay_het_han: "field_ngay_het_han",
  field_loai_thuoc: "field_loai_thuoc",
  field_nhom_thuoc: "field_nhom_thuoc",
  field_tat_ca: "field_tat_ca",
  field_tat_ca_nhom: "field_tat_ca_nhom",
  field_da_het_han: "field_da_het_han",
  field_chua_het_han: "field_chua_het_han",
  field_ma_vach: "field_ma_vach",
  field_nhom_chi_phi: "field_nhom_chi_phi",
  field_chung_tu: "field_chung_tu",
  field_phuong_thuc: "field_phuong_thuc",
  field_don_gia: "field_don_gia",
  field_ton_cuoi: "field_ton_cuoi",
  field_doi_tac: "field_doi_tac",
  field_so_lo: "field_so_lo",
  field_han_su_dung: "field_han_su_dung",
  field_con_hang: "field_con_hang",
  field_het_hang: "field_het_hang",
  field_ten_nhom_thuoc: "field_ten_nhom_thuoc",
  field_nhom_cha: "field_nhom_cha",
  field_ma_lo: "field_ma_lo",
  field_them_lo: "field_them_lo",
  field_chi_tiet_phieu_nhap: "field_chi_tiet_phieu_nhap",
  field_chi_tiet_phieu_xuat: "field_chi_tiet_phieu_xuat",
  field_chi_tiet_phieu_ban_thuoc: "field_chi_tiet_phieu_ban_thuoc",
  field_tao_phieu_nhap: "field_tao_phieu_nhap",
  field_chinh_sua_phieu: "field_chinh_sua_phieu",
  field_nhom_cls: "field_nhom_cls",
  field_loai_doi_tuong: "field_loai_doi_tuong",
  field_ma_nguon_khach: "field_ma_nguon_khach",
  field_doanh_thu: "field_doanh_thu",
  field_tong_tien: "field_tong_tien",

  //sổ hóa đơn
  field_shd_loai_so: "field_shd_loai_so",
  field_shd_ten_so: "field_shd_ten_so",
  field_shd_ky_hieu: "field_shd_ky_hieu",
  field_shd_tong_so: "field_shd_tong_so",
  field_shd_ma_so: "field_shd_ma_so",

  // Từ khóa thông dụng (tên button, chức năng phổ biến, tiêu đề dùng nhiều, ...)
  common_trong: "common_trong",
  common_Huy: "common_Huy",
  cap: "cap",
  common_Luu: "common_Luu",
  common_Thoat: "common_Thoat",
  common_Luu_va_In: "common_Luu_va_In",
  common_In: "common_In",
  common_Nhap: "common_Nhap",
  common_Chon: "common_Chon",
  common_Bat: "common_Bat",
  common_Xoa: "common_Xoa",
  common_huy_don: "common_huy_don",
  common_Chinh_sua: "common_Chinh_sua",
  common_Them_moi: "common_Them_moi",
  common_Tim_kiem: "common_Tim_kiem",
  nhap_dung_dinh_dang_so: "nhap_dung_dinh_dang_so",
  common_Tim_kiem_theo_loai: "common_Tim_kiem_theo_loai",
  common_Nhap_csv: "common_Nhap_csv",
  common_Xuat_csv: "common_Xuat_csv",
  common_Tat: "common_Tat",
  common_Xem_truoc: "common_Xem_truoc",
  common_Tai_mau: "common_Tai_mau",
  common_Tiep_tuc: "common_Tiep_tuc",
  common_Dang_kham: "common_Dang_kham",
  common_Kham_xong: "common_Kham_xong",
  common_Dang_xuat: "common_Dang_xuat",
  common_Canh_bao: "common_Canh_bao",
  common_Doi_ngon_ngu: "common_Doi_ngon_ngu",
  common_Xuat_file: "common_Xuat_file",
  common_ok: "common_ok",
  common_cai_dat_lai: "common_cai_dat_lai",
  common_dong: "common_dong",
  common_bo_loc: "common_bo_loc",
  common_tai_lai: "common_tai_lai",

  // Giới tính
  gioi_tinh_Nam: "gioi_tinh_Nam",
  gioi_tinh_Nu: "gioi_tinh_Nu",
  gioi_tinh_Khac: "gioi_tinh_Khac",

  // Khác...
  noi_dung_keo_tha_upload_file: "noi_dung_keo_tha_upload_file",

  // Loại khoa
  loai_khoa_kham_benh: "loai_khoa_kham_benh",
  loai_khoa_noi_khoa: "loai_khoa_noi_khoa",
  loai_khoa_duoc: "loai_khoa_duoc",
  loai_khoa_tai_chinh: "loai_khoa_tai_chinh",
  loai_khoa_xet_nghiem: "loai_khoa_xet_nghiem",
  loai_khoa_cdha: "loai_khoa_cdha",
  loai_khoa_khac: "loai_khoa_khac",

  // label
  label_Da_tiep_nhan: "label_Da_tiep_nhan",
  label_quan_ly_lich_hen: "label_quan_ly_lich_hen",
  label_Tiep_don: "label_Tiep_don",
  label_Tiep_don_kham_chua_benh: "label_Tiep_don_kham_chua_benh",
  label_So_hoa_don: "label_So_hoa_don",
  label_them_so_hoa_don: "label_them_so_hoa_don",
  label_sua_so_hoa_don: "label_sua_so_hoa_don",
  label_thong_tin_chi_tiet: "label_thong_tin_chi_tiet",
  label_phieu_xuat_nhap: "label_phieu_xuat_nhap",

  // thông tin cơ sở khám chữa bệnh
  thong_tin_cskcb: "thong_tin_cskcb",
  luu_thay_doi: "luu_thay_doi",
  field_ten_CSKCB: "field_ten_CSKCB",
  field_don_vi_quan_ly: "field_don_vi_quan_ly",
  field_hang_benh_vien: "field_hang_benh_vien",
  field_giam_doc_benh_vien: "field_giam_doc_benh_vien",
  them_hinh_anh: "them_hinh_anh",
  field_ten_nguoi_dung: "field_ten_nguoi_dung",
  field_phan_quyen: "field_phan_quyen",
  txt_da_chon: "txt_da_chon",
  field_ma_cc_hanh_nghe: "field_ma_cc_hanh_nghe",
  field_thong_tin_cong_viec: "field_thong_tin_cong_viec",
  field_hoc_ham: "field_hoc_ham",
  field_chuc_danh: "field_chuc_danh",
  field_ho: "field_ho",
  field_ten_ten_dem: "field_ten_ten_dem",
  field_mat_khau: "field_mat_khau",
  field_ten_danh_nhap: "field_ten_danh_nhap",
  title_them_moi_nguoi_dung: "title_them_moi_nguoi_dung",
  title_thong_tin_tai_khoan: "title_thong_tin_tai_khoan",
  vui_long_nhap: "vui_long_nhap",
  please_chon: "please_chon",
  vui_long_chon: "vui_long_chon",
  vui_long_nhap_du_ky_tu: "vui_long_nhap_du_ky_tu",
  vui_long_nhap_dung_dinh_dang: "vui_long_nhap_dung_dinh_dang",
  title_chinh_sua_nguoi_dung: "title_chinh_sua_nguoi_dung",
  title_tong_gia: "title_tong_gia",
  title_Thu_phi: "title_Thu_phi",
  button_nhap_BHYT: "button_nhap_BHYT",
  button_kiem_tra_BHYT: "button_kiem_tra_BHYT",
  field_dia_chi_the: "field_dia_chi_the",
  short_ma_cc_hanh_nghe: "short_ma_cc_hanh_nghe",
  // message errorr :
  nhap_dung_dinh_dang_email: "nhap_dung_dinh_dang_email",
  nhap_email: "nhap_email",
  nhap_dung_dinh_dang_sdt: "nhap_dung_dinh_dang_sdt",
  nhap_sdt: "nhap_sdt",
  error_trung_so_dien_thoai: "error_trung_so_dien_thoai",
  error_trung_so_user_name: "error_trung_so_user_name",
  error_loi_ton_tai: "error_loi_ton_tai",
  error_loi_khong_nhap_gia_tri: "error_loi_khong_nhap_gia_tri",
  error_gia_tri_khong_hop_le: "error_gia_tri_khong_hop_le",
  error_khong_trung_khop: "error_khong_trung_khop",
  error_dich_vu_da_thanh_toan: "error_dich_vu_da_thanh_toan",
  error_vien_phi_thieu_phieu: "error_vien_phi_thieu_phieu",
  error_thoi_gian_khong_hop_le: "error_thoi_gian_khong_hop_le",
  data_thu_thuat: "data_thu_thuat",
  thu_thuat_dac_biet: "thu_thuat_dac_biet",
  thu_thuat_loai_1: "thu_thuat_loai_1",
  thu_thuat_loai_2: "thu_thuat_loai_2",
  thu_thuat_loai_3: "thu_thuat_loai_3",
  error_gioi_han_ky_tu_sdt: "error_gioi_han_ky_tu_sdt",
  error_gioi_han_ky_tu_sdt_min: "error_gioi_han_ky_tu_sdt_min",
  error_gioi_han_ky_tu_mst: "error_gioi_han_ky_tu_mst",
  error_zero_only: "error_zero_only",

  cap_nhat_thanh_cong: "cap_nhat_thanh_cong",
  cap_nhat_that_bai: "cap_nhat_that_bai",
  them_moi_thanh_cong: "them_moi_thanh_cong",
  them_moi_that_bai: "them_moi_that_bai",
  thao_tac_thanh_cong: "thao_tac_thanh_cong",
  thao_tac_that_bai: "thao_tac_that_bai",

  common_Hom_nay: "common_Hom_nay",
  common_Hom_qua: "common_Hom_qua",
  Cho_kham: "Cho_kham",
  Cho_kham_short: "Cho_kham_short",
  CLS: "CLS",
  common_Hoan_thanh: "common_Hoan_thanh",
  common_Hoan_thanh_short: "common_Hoan_thanh_short",
  field_Tuoi: "field_Tuoi",
  common_Ap_dung: "common_Ap_dung",
  Thong_tin_benh_nhan: "Thong_tin_benh_nhan",
  Lich_su_kham_benh: "Lich_su_kham_benh",
  Chi_dinh_CLS: "Chi_dinh_CLS",
  Ke_don_thuoc_tu_nguyen: "Ke_don_thuoc_tu_nguyen",
  xac_nhan_lich_hen: "xac_nhan_lich_hen",
  Don_thuoc_bhyt: "Don_thuoc_bhyt",
  field_Ngay_kham: "field_Ngay_kham",
  field_Noi_kham: "field_Noi_kham",
  field_Chan_doan_benh_chinh: "field_Chan_doan_benh_chinh",
  field_Bac_si: "field_Bac_si",
  field_Bac_si_kham: "field_Bac_si_kham",
  field_Khoa_dieu_tri: "field_Khoa_dieu_tri",
  Don_thuoc: "Don_thuoc",
  Ket_qua_CLS: "Ket_qua_CLS",
  field_CMND_CCCD: "field_CMND_CCCD",
  xac_nhan_tiep_don: "xac_nhan_tiep_don",
  xac_nhan_va_in_phieu_kham: "xac_nhan_va_in_phieu_kham",
  bs: "bs",
  field_Chan_doan_benh_phu_kem_theo: "field_Chan_doan_benh_phu_kem_theo",

  field_Chi_so_sinh_ton: "field_Chi_so_sinh_ton",
  field_Mach: "field_Mach",
  field_Lan_phut: "field_Lan_phut",
  field_Do_C: "field_Do_C",
  field_Nhiet_do: "field_Nhiet_do",
  field_Nhip_tho: "field_Nhip_tho",
  field_Nhip_phut: "field_Nhip_phut",
  field_Huyet_ap: "field_Huyet_ap",
  field_Ly_do_kham: "field_Ly_do_kham",
  field_Qua_trinh_benh_ly: "field_Qua_trinh_benh_ly",
  field_Tien_su_ban_than: "field_Tien_su_ban_than",
  field_Tien_su_gia_dinh: "field_Tien_su_gia_dinh",
  field_Kham_lam_sang: "field_Kham_lam_sang",
  field_Ma_benh_chinh: "field_Ma_benh_chinh",
  field_Ma_benh_kem_theo: "field_Ma_benh_kem_theo",
  field_Chan_doan_lam_sang: "field_Chan_doan_lam_sang",
  field_Chan_doan_xac_dinh: "field_Chan_doan_xac_dinh",
  field_Cach_giai_quyet: "field_Cach_giai_quyet",
  field_Ket_qua_dieu_tri: "field_Ket_qua_dieu_tri",
  field_Xu_tri: "field_Xu_tri",
  field_Tai_kham: "field_Tai_kham",
  field_Dat_kham: "field_Dat_kham",
  field_ngay_kham: "field_ngay_kham",
  field_ngay_thuc_hien: "field_ngay_thuc_hien",
  field_gio_thuc_hien: "field_gio_thuc_hien",
  field_Tai_kham_sau: "field_Tai_kham_sau",
  Ket_thuc_cuoc_kham: "Ket_thuc_cuoc_kham",
  field_Chon_ngay_ap_dung: "field_Chon_ngay_ap_dung",
  thong_tin_dich_vu_cls: "thong_tin_dich_vu_cls",
  chi_tiet_tiep_don_kham_chua_benh: "chi_tiet_tiep_don_kham_chua_benh",
  Ke_don_thuoc: "Ke_don_thuoc",
  field_Thoi_gian_y_lenh: "field_Thoi_gian_y_lenh",
  field_Them_moi_thuoc: "field_Them_moi_thuoc",
  field_Kho_thuoc: "field_Kho_thuoc",
  field_Ten_thuoc: "field_Ten_thuoc",
  field_So_luong: "field_So_luong",
  field_Moi_ngay: "field_Moi_ngay",
  field_Chia_lam: "field_Chia_lam",
  field_Cach_dung: "field_Cach_dung",
  field_Ma_thuoc: "field_Ma_thuoc",
  field_Hoat_chat: "field_Hoat_chat",
  field_Don_vi: "field_Don_vi",
  field_In_phieu: "field_In_phieu",
  field_Lan: "field_Lan",
  field_Ma_don_thuoc: "field_Ma_don_thuoc",
  field_Kho_nhan_thuoc: "field_Kho_nhan_thuoc",
  Ds_dich_vu_cls: "Ds_dich_vu_cls",
  field_Dich_vu_chi_dinh: "field_Dich_vu_chi_dinh",
  Chi_dinh_can_lam_sang: "Chi_dinh_can_lam_sang",
  chi_tiet_phieu_chi_dinh_can_lam_sang: "chi_tiet_phieu_chi_dinh_can_lam_sang",
  tao_phieu_chi_dinh_can_lam_sang: "tao_phieu_chi_dinh_can_lam_sang",
  danh_sach_dich_vu_da_chon: "danh_sach_dich_vu_da_chon",
  field_Them_moi_can_lam_sang: "field_Them_moi_can_lam_sang",
  field_Chi_tiet_phieu_cls: "field_Chi_tiet_phieu_cls",
  txt_So_luong: "txt_So_luong",
  txt_nhap_loi_dan: "txt_nhap_loi_dan",

  field_Ket_qua: "field_Ket_qua",
  field_Da_TT: "field_Da_TT",
  field_mau_bp: "field_mau_bp",
  field_Da_TH: "field_Da_TH",
  field_Ma_CK: "field_Ma_CK",
  field_Ma_BN: "field_Ma_BN",
  field_SL: "field_SL",
  field_BS: "field_BS",
  TT_cho: "TT_cho",

  field_KQKB_khoi_benh: "field_KQKB_khoi_benh",
  field_KQKB_do: "field_KQKB_do",
  field_KQKB_khong_thay_doi: "field_KQKB_khong_thay_doi",
  field_KQKB_nang: "field_KQKB_nang",
  field_KQKB_tu_vong: "field_KQKB_tu_vong",

  field_cach_xu_tri_ra_vien: "field_cach_xu_tri_ra_vien",
  field_cach_xu_tri_chuyen_vien: "field_cach_xu_tri_chuyen_vien",
  field_cach_xu_tri_chuyen_vien2: "field_cach_xu_tri_chuyen_vien2",
  field_cach_xu_tri_tron_vien: "field_cach_xu_tri_tron_vien",
  field_cach_xu_tri_xin_ra_vien: "field_cach_xu_tri_xin_ra_vien",

  currency_viet_nam: "currency_viet_nam",
  field_Tong_gia: "field_Tong_gia",
  field_Tong_gia_tien: "field_Tong_gia_tien",
  error_Dich_vu_da_ton_tai: "error_Dich_vu_da_ton_tai",
  fallback_Phong_thuc_hien_trong: "fallback_Phong_thuc_hien_trong",
  status_Chua_thanh_toan: "status_Chua_thanh_toan",
  status_Da_thanh_toan: "status_Da_thanh_toan",
  status_Cho_ket_qua: "status_Cho_ket_qua",
  status_Da_huy: "status_Da_huy",
  status_Tat_ca: "status_Tat_ca",
  status_Cho_xac_nhan: "status_Cho_xac_nhan",
  field_Tong_thanh_toan: "field_Tong_thanh_toan",
  field_Ten_nhom_thuoc: "field_Ten_nhom_thuoc",
  // Viện phí

  vien_phi_da_tam_ung: "vien_phi_da_tam_ung",
  vien_phi_Tam_ung: "vien_phi_Tam_ung",
  vien_phi_dich_vu_can_thanh_toan: "vien_phi_dich_vu_can_thanh_toan",
  vien_phi_thanh_tien: "vien_phi_thanh_tien",
  vien_phi_mien_giam_chi_phi: "vien_phi_mien_giam_chi_phi",
  vien_phi_tong_chi_phi: "vien_phi_tong_chi_phi",
  vien_phi_mien_giam: "vien_phi_mien_giam",
  vien_phi_tong_thanh_toan: "vien_phi_tong_thanh_toan",
  vien_phi_tong_tien_benh_nhan_dua: "vien_phi_tong_tien_benh_nhan_dua",
  vien_phi_tien_tam_ung: "vien_phi_tien_tam_ung",
  vien_phi_tien_thua: "vien_phi_tien_thua",
  vien_phi_so_hoa_don: "vien_phi_so_hoa_don",
  vien_phi_thanh_toan: "vien_phi_thanh_toan",
  vien_phi_thanh_toan_va_in: "vien_phi_thanh_toan_va_in",

  vien_phi_Tao_tam_ung: "vien_phi_Tao_tam_ung",
  vien_phi_Hoan_ung: "vien_phi_Hoan_ung",
  vien_phi_Tao: "vien_phi_Tao",
  vien_phi_Don_gia: "vien_phi_Don_gia",
  vien_phi_warning_khong_the_bo_chon_tat_ca: "vien_phi_warning_khong_the_bo_chon_tat_ca",
  status_Dang_kham: "status_Dang_kham",
  status_Dang_kham_short: "status_Dang_kham_short",
  status_Hoan_thanh: "status_Hoan_thanh",
  vien_phi_BN_phai_tra: "vien_phi_BN_phai_tra",
  vien_phi_tien_hoan_ung: "vien_phi_tien_hoan_ung",
  vien_phi_Hoan_tien: "vien_phi_Hoan_tien",
  vien_phi_Hoan_tien_va_in: "vien_phi_Hoan_tien_va_in",
  noti_vien_phi_ly_do_hoan_tien: "noti_vien_phi_ly_do_hoan_tien",
  noti_so_hoa_don: "noti_so_hoa_don",

  field_Yeu_cau_hoi_chan: "field_Yeu_cau_hoi_chan",
  field_Thong_tin_nguoi_su_dung: "field_Thong_tin_nguoi_su_dung",
  field_Chu_ky_so: "field_Chu_ky_so",
  field_Anh_chu_ky_scan: "field_Anh_chu_ky_scan",
  field_Khoa_lam_viec: "field_Khoa_lam_viec",
  field_Phong_lam_viec: "field_Phong_lam_viec",
  field_Ket_qua_tim_kiem: "field_Ket_qua_tim_kiem",
  field_Kham_benh: "field_Kham_benh",
  field_dich_vu_kt: "field_dich_vu_kt",
  field_Dich_vu: "field_Dich_vu",
  field_Lua_chon_dich_vu: "field_Lua_chon_dich_vu",
  field_Nhom_chi_phi_bhyt: "field_Nhom_chi_phi_bhyt",
  field_Nhom_danh_muc: "field_Nhom_danh_muc",

  Ban_chua_chon_benh_nhan_nao: "Ban_chua_chon_benh_nhan_nao",

  data_Tuyen_trung_uong: "data_Tuyen_trung_uong",
  data_Tuyen_tinh: "data_Tuyen_tinh",
  data_Tuyen_huyen: "data_Tuyen_huyen",
  data_Tuyen_xa: "data_Tuyen_xa",
  data_Khong_phan_tuyen: "data_Khong_phan_tuyen",
  data_Hang_dac_biet: "data_Hang_dac_biet",
  data_Hang: "data_Hang",
  data_Khong_xep_hang: "data_Khong_xep_hang",
  data_Kham_benh: "data_Kham_benh",
  data_Kham_cls: "data_Kham_cls",
  data_Noi_khoa: "data_Noi_khoa",
  data_Duoc: "data_Duoc",
  data_Tai_chinh: "data_Tai_chinh",
  data_Xet_nghiem: "data_Xet_nghiem",
  data_CDHA: "data_CDHA",
  data_Khac: "data_Khac",
  data_Vien_phi: "data_Vien_phi",
  data_Bhyt: "data_Bhyt",
  data_Lay_mau: "data_Lay_mau",
  data_Nha_thuoc: "data_Nha_thuoc",
  data_Kho_bhyt: "data_Kho_bhyt",
  data_Ngay: "data_Ngay",
  data_Tuan: "data_Tuan",
  data_Thang: "data_Thang",
  data_Nam: "data_Nam",
  data_Kho_thuoc_tu_nguyen: "data_Kho_thuoc_tu_nguyen",
  data_Kho_thuoc_bhyt: "data_Kho_thuoc_bhyt",
  data_Kho_chan: "data_Kho_chan",
  data_Kho_le: "data_Kho_le",
  data_Tu_truc: "data_Tu_truc",
  data_Noi_tru: "data_Noi_tru",
  data_Ngoai_tru: "data_Ngoai_tru",
  data_Noi_tru_Ngoai_tru: "data_Noi_tru_Ngoai_tru",
  data_buong_dieu_tri: "data_buong_dieu_tri",
  data_Tiep_tan: "data_Tiep_tan",
  data_Nguoi_lay_mau: "data_Nguoi_lay_mau",
  field_Nguoi_tiep_don: "field_Nguoi_tiep_don",
  field_Nguoi_tu_van: "field_Nguoi_tu_van",
  data_Giao_su: "data_Giao_su",
  data_Pho_giao_su: "data_Pho_giao_su",
  data_Tien_si: "data_Tien_si",
  data_Thac_si: "data_Thac_si",
  data_Bs_chuyen_khoa: "data_Bs_chuyen_khoa",
  data_Truong_khoa: "data_Truong_khoa",
  data_Pho_khoa: "data_Pho_khoa",
  data_Pho_giam_doc: "data_Pho_giam_doc",
  data_Giam_doc: "data_Giam_doc",
  data_Bac_si: "data_Bac_si",
  data_Quyen: "data_Quyen",
  cach_thuc_pttt: "cach_thuc_pttt",
  phuong_phap_pttt: "phuong_phap_pttt",

  field_Ma_dvt: "field_Ma_dvt",
  field_Ten_dvt: "field_Ten_dvt",

  Cai_dat_llv: "Cai_dat_llv",
  Tuan_nay: "Tuan_nay",
  data_Thu_2: "data_Thu_2",
  data_Thu_3: "data_Thu_3",
  data_Thu_4: "data_Thu_4",
  data_Thu_5: "data_Thu_5",
  data_Thu_6: "data_Thu_6",
  data_Thu_7: "data_Thu_7",
  data_Chu_nhat: "data_Chu_nhat",
  field_Ca: "field_Ca",
  field_Thoi_gian_lam_viec: "field_Thoi_gian_lam_viec",
  field_Tu: "field_Tu",
  field_Den: "field_Den",
  field_Ngay_ap_dung: "field_Ngay_ap_dung",
  field_Thoi_gian_lam_viec_mac_dinh: "field_Thoi_gian_lam_viec_mac_dinh",
  field_Benh_nhan_tiep_theo: "field_Benh_nhan_tiep_theo",
  field_So_benh_nhan_kham_mac_dinh: "field_So_benh_nhan_kham_mac_dinh",
  field_Tai_phong_kham: "field_Tai_phong_kham",
  field_phut: "field_phut",

  field_Ngay_tao: "field_Ngay_tao",
  field_Nguoi_tao: "field_Nguoi_tao",
  field_Tong_chi_phi: "field_Tong_chi_phi",
  field_Mien_giam: "field_Mien_giam",
  field_hinh_thuc_thanh_toan: "field_hinh_thuc_thanh_toan",
  In_hoa_don: "In_hoa_don",
  field_Ki_hieu: "field_Ki_hieu",
  field_Loai_so: "field_Loai_so",
  status_Moi: "status_Moi",
  status_Gan_day: "status_Gan_day",
  status_Da_day: "status_Da_day",
  field_So_phieu: "field_So_phieu",
  field_Ma_so: "field_Ma_so",
  field_Ten_so: "field_Ten_so",
  field_Tong_so_phieu: "field_Tong_so_phieu",
  field_Nguoi_su_dung: "field_Nguoi_su_dung",
  field_So_bat_dau: "field_So_bat_dau",
  field_So_ket_thuc: "field_So_ket_thuc",
  field_Phan_quyen_nguoi_su_dung_so: "field_Phan_quyen_nguoi_su_dung_so",

  noti_Chi_duoc_upload_anh: "noti_Chi_duoc_upload_anh",
  noti_Kich_thuoc_anh_be_hon_5mb: "noti_Kich_thuoc_anh_be_hon_5mb",
  noti_Kich_thuoc_anh_be_hon_1mb: "noti_Kich_thuoc_anh_be_hon_1mb",
  noti_Chi_duoc_upload_excel: "noti_Chi_duoc_upload_excel",
  noti_Dang_nhap_thanh_cong: "noti_Dang_nhap_thanh_cong",
  noti_Dang_nhap_that_bai: "noti_Dang_nhap_that_bai",
  noti_Xoa_thanh_cong: "noti_Xoa_thanh_cong",
  noti_Xoa_that_bai: "noti_Xoa_that_bai",
  noti_Dang_xuat_danh_sach: "noti_Dang_xuat_danh_sach",
  noti_Xuat_danh_sach_thanh_cong: "noti_Xuat_danh_sach_thanh_cong",
  noti_Xuat_danh_sach_that_bai: "noti_Xuat_danh_sach_that_bai",
  noti_Dang_Tai_template: "noti_Dang_Tai_template",
  noti_Tai_template_thanh_cong: "noti_Tai_template_thanh_cong",
  noti_Tai_template_that_bai: "noti_Tai_template_that_bai",
  noti_Nhap_du_lieu_thanh_cong: "noti_Nhap_du_lieu_thanh_cong",
  noti_Nhap_du_lieu_that_bai: "noti_Nhap_du_lieu_that_bai",
  noti_Xoa_danh_sach_thanh_cong: "noti_Xoa_danh_sach_thanh_cong",
  noti_Xoa_danh_sach_that_bai: "noti_Xoa_danh_sach_that_bai",
  noti_Khoa_danh_sach_thanh_cong: "noti_Khoa_danh_sach_thanh_cong",
  noti_Khoa_danh_sach_that_bai: "noti_Khoa_danh_sach_that_bai",
  noti_Chua_con_phong_thuc_hien: "noti_Chua_con_phong_thuc_hien",
  noti_Thuoc_da_ton_tai: "noti_Thuoc_da_ton_tai",
  noti_Chua_chon_thoi_gian_y_lenh: "noti_Chua_chon_thoi_gian_y_lenh",
  noti_Chua_chon_ngay_tai_kham: "noti_Chua_chon_ngay_tai_kham",
  noti_Chua_co_thong_tin_kham: "noti_Chua_co_thong_tin_kham",
  noti_Chua_chon_phong_thuc_hien: "noti_Chua_chon_phong_thuc_hien",
  noti_Chua_chon_dich_vu_kham_benh: "noti_Chua_chon_dich_vu_kham_benh",
  noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls: "noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls",
  noti_Luu_lich_kham_thanh_cong: "noti_Luu_lich_kham_thanh_cong",
  noti_Luu_lich_kham_that_bai: "noti_Luu_lich_kham_that_bai",
  noti_Chua_chon_benh_nhan_dv_thanh_toan: "noti_Chua_chon_benh_nhan_dv_thanh_toan",
  noti_Khong_the_huy_dich_vu_da_thanh_toan: "noti_Khong_the_huy_dich_vu_da_thanh_toan",
  noti_Luu_cuoc_hen_thanh_cong: "noti_Luu_cuoc_hen_thanh_cong",
  noti_Luu_cuoc_hen_that_bai: "noti_Luu_cuoc_hen_that_bai",
  noti_Dang_tai_du_lieu: "noti_Dang_tai_du_lieu",
  noti_Tai_du_lieu_thanh_cong: "noti_Tai_du_lieu_thanh_cong",
  noti_Tai_du_lieu_that_bai: "noti_Tai_du_lieu_that_bai",
  noti_Dang_kiem_tra_trang_thai_don_thuoc: "noti_Dang_kiem_tra_trang_thai_don_thuoc",
  noti_warning_thay_doi_thoi_gian_phong: "noti_warning_thay_doi_thoi_gian_phong",
  noti_warning_thuoc: "noti_warning_thuoc",
  noti_warning_thuoc_co_trong_don: "noti_warning_thuoc_co_trong_don",
  noti_warning_thuoc_khong_du: "noti_warning_thuoc_khong_du",
  noti_lay_danh_sach_lich_kham_that_bai: "noti_lay_danh_sach_lich_kham_that_bai",
  noti_lay_danh_sach_that_bai: "noti_lay_danh_sach_that_bai",
  noti_vui_long_nhap_du_ngay_thang_nam: "noti_vui_long_nhap_du_ngay_thang_nam",
  noti_ten_dang_nhap_da_ton_tai: "noti_ten_dang_nhap_da_ton_tai",
  noti_ma_so_da_ton_tai: "noti_ma_so_da_ton_tai",
  noti_lay_ds_nguoi_tiep_don_fail: "noti_lay_ds_nguoi_tiep_don_that_bai",
  Dang_nhap: "Dang_nhap",
  Dang_nhap_subtitle: "Dang_nhap_subtitle",
  field_Ten_dang_nhap: "field_Ten_dang_nhap",
  field_Nang_suat_kham: "field_Nang_suat_kham",
  field_Danh_sach_thuoc: "field_Danh_sach_thuoc",

  field_lich_lam_viec: "field_lich_lam_viec",
  error_Chon_toi_thieu_1_ngay: "error_Chon_toi_thieu_1_ngay",
  error_Chua_co_ca_lam_viec: "error_Chua_co_ca_lam_viec",

  field_Don_thuoc_tu_nguyen: "field_Don_thuoc_tu_nguyen",
  field_Bac_si_ke_don: "field_Bac_si_ke_don",
  button_In_don_thuoc: "button_In_don_thuoc",
  field_Loi_dan: "field_Loi_dan",
  cf_Ban_co_chac_chan_xoa: "cf_Ban_co_chac_chan_xoa",
  label_Tao_cuoc_hen_moi: "label_Tao_cuoc_hen_moi",
  error_view_Chua_co_don_thuoc: "error_view_Chua_co_don_thuoc",
  error_view_Khong_co_don_thuoc: "error_view_Khong_co_don_thuoc",

  field_Doi_tuong: "field_Doi_tuong",
  field_ma_doi_tuong: "field_ma_doi_tuong",
  field_Gio_kham: "field_Gio_kham",
  title_cuoc_hen_sap_toi: "title_cuoc_hen_sap_toi",
  title_Tao_cuoc_hen: "title_Tao_cuoc_hen",
  title_Chinh_sua_cuoc_hen: "title_Chinh_sua_cuoc_hen",
  title_Chinh_sua_thong_tin_tiep_don: "title_Chinh_sua_thong_tin_tiep_don",
  title_Xac_nhan: "title_Xac_nhan",
  title_Gia_dich_vu: "title_Gia_dich_vu",
  title_phong_khám: "title_phong_khám",
  noti_Tiep_tuc_khi_chua_ke_don_thuoc: "noti_Tiep_tuc_khi_chua_ke_don_thuoc",
  noti_Cuoc_hen_khong_phai_hom_nay: "noti_Cuoc_hen_khong_phai_hom_nay",

  field_Ma_cuoc_kham: "field_Ma_cuoc_kham",
  chi_tiet_cuoc_hen: "chi_tiet_cuoc_hen",
  chinh_sua_cuoc_hen: "chinh_sua_cuoc_hen",
  thong_tin_cuoc_kham: "thong_tin_cuoc_kham",
  thong_tin_kham_benh: "thong_tin_kham_benh",
  error_Rang_buoc_ton_tai: "error_Rang_buoc_ton_tai",
  common_Xuat_hang_cho: "common_Xuat_hang_cho",
  title_Hang_cho_kham_benh: "title_Hang_cho_kham_benh",
  title_Hang_cho: "title_Hang_cho",
  thong_tin: "thong_tin",
  the_kho: "the_kho",
  lo_han_su_dung: "lo_han_su_dung",
  title_them_moi_nhom_thuoc: "title_them_moi_nhom_thuoc",
  title_chinh_sua_nhom_thuoc: "title_chinh_sua_nhom_thuoc",

  error_Chua_chon_doi_tuong_benh_nhan: "error_Chua_chon_doi_tuong_benh_nhan",
  error_Ngay_sinh_ko_hop_le: "error_Ngay_sinh_ko_hop_le",
  error_Chua_chon_thong_tin_luot_dieu_tri: "error_Chua_chon_thong_tin_luot_dieu_tri",
  error_Khong_chon_ngay_khac_voi_ngay_truoc_do: "error_Khong_chon_ngay_khac_voi_ngay_truoc_do",

  field_Trang_thai_thanh_toan: "field_Trang_thai_thanh_toan",
  field_Trang_thai_cuoc_hen: "field_Trang_thai_cuoc_hen",
  title_Phieu_thu_da_tao: "title_Phieu_thu_da_tao",
  error_Gia_tri_mien_giam_ko_hop_le: "error_Gia_tri_mien_giam_ko_hop_le",
  error_Khong_sua_duoc_lich_kham: "error_Khong_sua_duoc_lich_kham",
  error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham: "error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham",

  tab_Phieu_mua_thuoc: "tab_Phieu_mua_thuoc",
  tab_Lich_su_phieu: "tab_Lich_su_phieu",
  field_Ma_phieu: "field_Ma_phieu",
  placeholder_Ma_tu_dong_tao: "placeholder_Ma_tu_dong_tao",
  placeholder_Lua_chon_don_thuoc_mau: "placeholder_Lua_chon_don_thuoc_mau",
  common_Thay_the: "common_Thay_the",
  txt_Them_lo: "txt_Them_lo",
  txt_Chon_lo: "txt_Chon_lo",
  txt_tu_choi: "txt_tu_choi",

  noti_confirm_Huy_dich_vu: "noti_confirm_Huy_dich_vu",
  field_Ly_do_hoan_tien: "field_Ly_do_hoan_tien",
  field_Tong_tien: "field_Tong_tien",
  warning_url_notfound: "warning_url_notfound",
  warning_url_notfound_title: "warning_url_notfound_title",
  warning_change_password: "warning_change_password",
  field_Ly_do_mien_giam: "field_Ly_do_mien_giam",

  error_body__at_column: "error_body__at_column",
  error_body__and_duplicate_value: "error_body__and_duplicate_value",

  //Nhà cung cấp
  da_chon: "da_chon",
  ton_kho: "ton_kho",
  ma_nha_cung_cap: "ma_nha_cung_cap",
  nha_cung_cap: "nha_cung_cap",
  ten_nha_cung_cap: "ten_nha_cung_cap",
  dien_thoai: "dien_thoai",
  no_can_tra: "no_can_tra",
  tong_mua: "tong_mua",
  nhom_ncc: "nhom_ncc",
  tat_ca_cac_nhom: "tat_ca_cac_nhom",
  tu: "tu",
  toi: "toi",
  no_hien_tai: "no_hien_tai",
  tat_ca: "tat_ca",
  dang_hoat_dong: "dang_hoat_dong",
  khong_hoat_dong: "khong_hoat_dong",
  trang_thai: "trang_thai",
  them_nha_cung_cap: "them_nha_cung_cap",
  thong_tin_co_so: "thong_tin_co_so",
  dia_chi: "dia_chi",
  cong_ty: "cong_ty",
  ma_so_thue: "ma_so_thue",
  tao_moi: "tao_moi",
  huy: "huy",
  thong_tin_chi_tiet: "thong_tin_chi_tiet",
  thong_tin_camel: "thong_tin_camel",
  lich_su_nhap_tra_hang_camel: "lich_su_nhap_tra_hang_camel",
  quan_ly_cong_no_camel: "quan_ly_cong_no_camel",
  xoa: "xoa",
  luu: "luu",
  ma_phieu: "ma_phieu",
  thoi_gian: "thoi_gian",
  nguoi_tao: "nguoi_tao",
  loai_phieu: "loai_phieu",
  tong_tien: "tong_tien",
  nhap_gia_tri: "nhap_gia_tri",
  gia_tri: "gia_tri",
  cong_no: "cong_no",
  dieu_chinh: "dieu_chinh",
  xuat_file_cong_no: "xuat_file_cong_no",
  in_file_cong_no: "in_file_cong_no",
  no_can_tra_hien_tai: "no_can_tra_hien_tai",
  cai_dat_ngay_gio: "cai_dat_ngay_gio",
  ngay_dieu_chinh: "ngay_dieu_chinh",
  gia_tri_no_dieu_chinh: "gia_tri_no_dieu_chinh",
  mo_ta: "mo_ta",
  cap_nhat: "cap_nhat",
  tien_mat: "tien_mat",
  chuyen_khoan: "chuyen_khoan",
  no_sau: "no_sau",
  tao_phieu_chi: "tao_phieu_chi",
  tao_phieu_chi_va_in: "tao_phieu_chi_va_in",
  tra_cho_ncc: "tra_cho_ncc",
  hay_chon_ngay: "hay_chon_ngay",
  da_nhap_hang: "da_nhap_hang",
  da_tra_hang: "da_tra_hang",
  hay_chon_nhom: "hay_chon_nhom",
  hay_dien_ma: "hay_dien_ma",
  hay_dien_ten: "hay_dien_ten",
  hay_nhap_so_dien_thoai: "hay_nhap_so_dien_thoai",
  hay_dien_email: "hay_dien_email",
  hay_dien_dia_chi: "hay_dien_dia_chi",
  hay_dien_ten_cong_ty: "hay_dien_ten_cong_ty",
  nhap_ten: "nhap_ten",
  nhap_dia_chi: "nhap_dia_chi",

  /// BẢNG ĐIỀU KHIỂN
  doanh_thu_trung_binh: "doanh_thu_trung_binh",
  doanh_thu_trung_binh_moi_cuoc_kham: "doanh_thu_trung_binh_moi_cuoc_kham",
  cuoc_kham: "cuoc_kham",
  trung_binh_cuoc_kham: "trung_binh_cuoc_kham",
  so_voi_ky_truoc: "so_voi_ky_truoc",
  tuoi: "tuoi",
  so_benh_nhan_trong_ky: "so_benh_nhan_trong_ky",
  so_benh_nhan_ky_truoc: "so_benh_nhan_ky_truoc",
  so_luong_benh_nhan_theo_thoi_gian: "so_luong_benh_nhan_theo_thoi_gian",
  benh_nhan: "benh_nhan",
  tong_quan_benh_nhan: "tong_quan_benh_nhan",
  ti_le_benh_nhan_theo_gioi_tinh: "ti_le_benh_nhan_theo_gioi_tinh",
  ti_le_benh_nhan_theo_do_tuoi: "ti_le_benh_nhan_theo_do_tuoi",
  don_vi_trieu_dong: "don_vi_trieu_dong",

  tong_quan_dich_vu: "tong_quan_dich_vu",
  tong_so_cuoc_kham: "tong_so_cuoc_kham",
  tong_so_benh_nhan: "tong_so_benh_nhan",
  tong_quan_kinh_doanh: "tong_quan_kinh_doanh",
  tong_quan_tiep_don_kham_chua_benh: "tong_quan_tiep_don_kham_chua_benh",

  field_Gia_nhap: "field_Gia_nhap",
  field_Gia_ban: "field_Gia_ban",

  noti_tao_moi_don_thuoc_thanh_cong: "noti_tao_moi_don_thuoc_thanh_cong",
  noti_tao_moi_benh_nhan_thanh_cong: "noti_tao_moi_benh_nhan_thanh_cong",
  noti_tao_moi_benh_nhan_that_bai: "noti_tao_moi_benh_nhan_that_bai",
  noti_them_vao_hang_cho_ban_thuoc_thanh_cong: "noti_them_vao_hang_cho_ban_thuoc_thanh_cong",
  noti_them_vao_hang_cho_ban_thuoc_that_bai: "noti_them_vao_hang_cho_ban_thuoc_that_bai",
  confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham: "confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham",
  confirm_Chuyen_cuoc_kham_sang_hoan_thanh: "confirm_Chuyen_cuoc_kham_sang_hoan_thanh",

  confirm_Thay_doi_kho_thuoc: "confirm_Thay_doi_kho_thuoc",

  field_Ton: "field_Ton",
  chon_phong: "chon_phong",
  loai: "loai",
  phong: "phong",
  thuoc: "thuoc",
  doanh_thu_theo_thoi_gian: "doanh_thu_theo_thoi_gian",
  tong_doanh_thu: "tong_doanh_thu",
  dich_vu_cls: "dich_vu_cls",
  truc_tiep: "truc_tiep",
  qua_app: "qua_app",
  qua_website: "qua_website",
  qua_zalo: "qua_zalo",
  ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan: "ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan",
  so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan: "so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan",
  ky_nay: "ky_nay",
  ky_truoc: "ky_truoc",

  /// Nội trú
  title_noi_tru: "title_noi_tru",
  dm_Giuong: "dm_Giuong",
  dm_giuong_ma_giuong: "dm_giuong_ma_giuong",
  dm_giuong_ma_giuong_bhyt: "dm_giuong_ma_giuong_bhyt",
  dm_giuong_ten_giuong: "dm_giuong_ten_giuong",
  confirm_xoa_don_thuoc: "confirm_xoa_don_thuoc",

  // Quản lý bệnh nhân
  cai_dat_hien_thi: "cai_dat_hien_thi",
  field_do_tuoi: "field_do_tuoi",
  field_ngay_kham_gan_nhat: "field_ngay_kham_gan_nhat",
  field_nho_hon_1_tuoi: "field_nho_hon_1_tuoi",
  field_1_11_tuoi: "field_1_11_tuoi",
  field_12_17_tuoi: "field_12_17_tuoi",
  field_18_64_tuoi: "field_18_64_tuoi",
  field_lon_hon_64_tuoi: "field_lon_hon_64_tuoi",
  bao_hiem_y_te: "bao_hiem_y_te",
  danh_sach_lich_su_kham_benh: "danh_sach_lich_su_kham_benh",
  field_chuan_doan_benh_chinh: "field_chuan_doan_benh_chinh",
  field_cskcb: "field_cskcb",
  field_khoa_theo_byt: "field_khoa_theo_byt",
  field_co_so_kham_chua_benh: "field_co_so_kham_chua_benh",
  field_ngay_tai_kham: "field_ngay_tai_kham",
  danh_sach_phieu_thu: "danh_sach_phieu_thu",
  field_loai_phieu_thu: "field_loai_phieu_thu",
  field_phan_loai: "field_phan_loai",
  field_hoan_tien: "field_hoan_tien",

  title_Bao_cao_nhanh: "title_Bao_cao_nhanh",
  title_Danh_sach_phieu_thu: "title_Danh_sach_phieu_thu",
  tab_Bao_cao_phieu_thu: "tab_Bao_cao_phieu_thu",
  tab_Bao_cao_vien_phi: "tab_Bao_cao_vien_phi",
  tab_Bao_cao_doanh_thu: "tab_Bao_cao_doanh_thu",
  tab_Bao_cao_tiep_don: "tab_Bao_cao_tiep_don",
  tag_Ban_thuoc: "tag_Ban_thuoc",
  tab_DK_goi_dich_vu: "tab_DK_goi_dich_vu",
  button_Cai_dat_lai: "button_Cai_dat_lai",
  button_Xuat_excel: "button_Xuat_excel",
  button_In_bao_cao: "button_In_bao_cao",
  label_Thoi_gian: "label_Thoi_gian",
  label_Loai_phieu: "label_Loai_phieu",
  option_Tat_ca: "option_Tat_ca",
  txt_ngay_gan_nhat: "txt_ngay_gan_nhat",
  txt_thang_gan_nhat: "txt_thang_gan_nhat",
  txt_tuy_chon: "txt_tuy_chon",
  bao_cao_duoc: "bao_cao_duoc",
  ten_doi_tuong: "ten_doi_tuong",

  //PHIẾU
  noti_Tao_phieu_thanh_cong: "noti_Tao_phieu_thanh_cong",
  noti_Tao_phieu_that_bai: "noti_Tao_phieu_that_bai",
  noti_Them_lo_moi_thanh_cong: "noti_Them_lo_moi_thanh_cong",
  noti_Them_lo_moi_that_bai: "noti_Them_lo_moi_that_bai",
  field_chon_phieu_mau: "field_chon_phieu_mau",
  field_chon_loai_phieu_mau: "field_chon_loai_phieu_mau",
  txt_can_tra_ncc: "txt_can_tra_ncc",
  txt_luu_phieu_mau: "txt_luu_phieu_mau",
  txt_ten_phieu_mau: "txt_ten_phieu_mau",
  txt_tien_tra_ncc: "txt_tien_tra_ncc",
  txt_tinh_vao_cong_no: "txt_tinh_vao_cong_no",
  title_phieu_xuat_ban: "title_phieu_xuat_ban",
  title_phieu_xuat_tra: "title_phieu_xuat_tra",
  title_phieu_xuat_huy: "title_phieu_xuat_huy",
  txt_tong_gia_tri_huy: "txt_tong_gia_tri_huy",
  txt_tien_ncc_tra: "txt_tien_ncc_tra",
  txt_ncc_phai_tra: "txt_ncc_phai_tra",
  noti_them_thuoc_that_bai: "noti_them_thuoc_that_bai",
  noti_chua_nhap_lo_thuoc: "noti_chua_nhap_lo_thuoc",
  noti_so_luong_thuoc_toi_da: "noti_so_luong_thuoc_toi_da",
  noti_lo_khong_con_thuoc: "noti_lo_khong_con_thuoc",
  noti_lay_chi_tiet_phieu_mau_that_bai: "noti_lay_chi_tiet_phieu_mau_that_bai",
  noti_lo_da_ton_tai: "noti_lo_da_ton_tai",

  //Dược ngoại trú update
  txt_them_moi_kho_thuoc: "txt_them_moi_kho_thuoc",
  field_khoa_kho: "field_khoa_kho",
  field_thoi_gian_tao: "field_thoi_gian_tao",

  //Loại thanh toán
  loai_phieu_nhap: "loai_phieu_nhap",
  loai_phieu_xuat: "loai_phieu_xuat",
  loai_phieu_ban_le: "loai_phieu_ban_le",
  loai_phieu_dieu_chinh: "loai_phieu_dieu_chinh",
  loai_phieu_thanh_toan: "loai_phieu_thanh_toan",
  loai_phieu_xuat_huy: "loai_phieu_xuat_huy",
  loai_phieu_xuat_tra_ncc: "loai_phieu_xuat_tra_ncc",
  loai_phieu_xuat_ban: "loai_phieu_xuat_ban",

  /// BẢNG ĐIỀU KHIỂN

  title_add: "title_add",
  noti_chua_co_phieu_ban_thuoc: "noti_chua_co_phieu_ban_thuoc",

  noti_chua_chon_benh_nhan_ban_thuoc: "noti_chua_chon_benh_nhan_ban_thuoc",

  error_Thoi_gian_khong_hop_le: "error_Thoi_gian_khong_hop_le",

  //thông tin bệnh nhân, tạo mới bệnh nhân
  tao_moi_benh_nhan: "tao_moi_benh_nhan",
  tab_Phieu_thanh_toan: "tab_Phieu_thanh_toan",
  tab_Phieu_hoan_tien: "tab_Phieu_hoan_tien",

  // NỘI TRÚ
  ma_bn: "ma_bn",
  ma_lk: "ma_lk",
  chon_khoa: "chon_khoa",
  loc_BN: "loc_BN",
  in_bao_cao: "in_bao_cao",
  dich_vu: "dich_vu",
  thuoc_vat_tu_mau: "thuoc_vat_tu_mau",
  dieu_duong: "dieu_duong",
  dieu_tri: "dieu_tri",
  xet_nghiem: "xet_nghiem",
  cdha: "cdha",
  pttt: "pttt",
  thuoc_vat_tu: "thuoc_vat_tu",
  dich_vu_khac: "dich_vu_khac",
  xu_tri: "xu_tri",
  ngay_chi_dinh: "ngay_chi_dinh",
  ngay_y_lenh: "ngay_y_lenh",
  noi_chi_dinh: "noi_chi_dinh",
  ngay_ket_qua: "ngay_ket_qua",
  noi_thuc_hien: "noi_thuc_hien",
  ten_chi_so: "ten_chi_so",
  tham_chieu: "tham_chieu",
  ten_PTTT: "ten_PTTT",
  loai_PTTT: "loai_PTTT",
  ten_thuoc_vat_tu: "ten_thuoc_vat_tu",
  sl: "sl",
  HDSD: "HDSD",
  ngay_vao_giuong: "ngay_vao_giuong",
  ngay_ra_giuong: "ngay_ra_giuong",
  chon_hinh_thuc: "chon_hinh_thuc",
  nhap_PTTT: "nhap_PTTT",
  bat_dau_luc: "bat_dau_luc",
  ket_thuc_luc: "ket_thuc_luc",
  chan_doan_truoc_PT: "chan_doan_truoc_PT",
  chan_doan_sau_PT: "chan_doan_sau_PT",
  cach_thuc_PT: "cach_thuc_PT",
  phuong_phap_PT: "phuong_phap_PT",
  phuong_phap_vo_cam: "phuong_phap_vo_cam",
  mo_ta_chi_tiet: "mo_ta_chi_tiet",
  nhom_mau: "nhom_mau",
  tai_bien_PTTT: "tai_bien_PTTT",
  hinh_anh_PTTT: "hinh_anh_PTTT",
  in_tuong_trinh: "in_tuong_trinh",
  ho_va_ten: "ho_va_ten",
  ket_luan: "ket_luan",
  chi_tiet: "chi_tiet",
  loai_giuong: "loai_giuong",
  giuong: "giuong",
  benh_kem_theo: "benh_kem_theo",
  benh_chinh: "benh_chinh",
  chan_doan: "chan_doan",
  da_thuc_hien: "da_thuc_hien",
  dang_thuc_hien: "dang_thuc_hien",
  chua_thuc_hien: "chua_thuc_hien",
  dieu_tri_noi_tru: "dieu_tri_noi_tru",
  chuyen_khoa: "chuyen_khoa",
  rh_am: "rh_am",
  rh_duong: "rh_duong",
  tren_ban_mo: "tren_ban_mo",
  trong_24h: "trong_24h",
  sau_24h: "sau_24h",
  do_phau_thuat: "do_phau_thuat",
  do_thu_thuat: "do_thu_thuat",
  do_gay_me: "do_gay_me",
  do_nhiem_khuan: "do_nhiem_khuan",
  cf_Ban_co_chac_chan_khoa: "cf_Ban_co_chac_chan_khoa",
  cf_Ban_co_chac_chan_mo_khoa: "cf_Ban_co_chac_chan_mo_khoa",

  //DM Giường
  loai_giuong_thuong: "loai_giuong_thuong",
  loai_giuong_ghep_2: "loai_giuong_ghep_2",
  loai_giuong_ghep_3: "loai_giuong_ghep_3",

  data_Thu_ngan: "data_Thu_ngan",
  data_Ky_thuat_vien: "data_Ky_thuat_vien",
  data_Dieu_duong: "data_Dieu_duong",
  data_Thu_kho: "data_Thu_kho",
  data_Quan_tri: "data_Quan_tri",

  field_Nhom_nhan_vien: "field_Nhom_nhan_vien",
  //Dược ngoại trú
  txt_them_thuoc: "txt_them_thuoc",
  txt_luu_thuoc: "txt_luu_thuoc",
  txt_chon_kho: "txt_chon_kho",
  txt_chon_thuoc: "txt_chon_thuoc",
  txt_ds_thuoc_chi_dinh: "txt_ds_thuoc_chi_dinh",
  txt_thanh_toan: "txt_thanh_toan",
  txt_tong_tien_truoc_vat: "txt_tong_tien_truoc_vat",
  txt_tong_tien_vat: "txt_tong_tien_vat",
  txt_tong_tien_sau_vat: "txt_tong_tien_sau_vat",
  txt_chua_ban: "txt_chua_ban",
  txt_da_ban: "txt_da_ban",
  txt_duyet_kho: "txt_duyet_kho",
  txt_ngay_ke_don: "txt_ngay_ke_don",

  txt_luu_mau_don_thuoc: "txt_luu_mau_don_thuoc",
  txt_ma_mau_don_thuoc: "txt_ma_mau_don_thuoc",
  txt_ten_mau_don_thuoc: "txt_ten_mau_don_thuoc",
  txt_placeholder_chon_don_thuoc_mau: "txt_placeholder_chon_don_thuoc_mau",
  txt_luu_mau_thong_tin: "txt_luu_mau_thong_tin",

  //alert
  khong_duoc_phep_xoa_lich_kham: "khong_duoc_phep_xoa_lich_kham",
  khong_duoc_phep_xoa_phieu: "khong_duoc_phep_xoa_phieu",

  menu_Nha_cung_cap: "menu_Nha_cung_cap",
  So_luong_ton_kho: "So_luong_ton_kho",
  Phieu_nhap_kho: "Phieu_nhap_kho",
  Phieu_xuat_kho: "Phieu_xuat_kho",
  Van_chuyen_kho: "Van_chuyen_kho",
  The_kho: "The_kho",
  Quyen_duyet_phieu: "Quyen_duyet_phieu",
  luu_va_in: "luu_va_in",
  luu_va_in_phieu_kham: "luu_va_in_phieu_kham",
  luu_va_in_phieu_cls: "luu_va_in_phieu_cls",
  //weekdays
  thu_2: "thu_2",
  thu_3: "thu_3",
  thu_4: "thu_4",
  thu_5: "thu_5",
  thu_6: "thu_6",
  thu_7: "thu_7",
  chu_nhat: "chu_nhat",
  error_Rang_buoc_khoa_ton_tai: "error_Rang_buoc_khoa_ton_tai",

  so_hoa_don_da_day: "so_hoa_don_da_day",
  field_Gia_thang_du: "field_Gia_thang_du",
  title_phieu_nhap_ncc: "title_phieu_nhap_ncc",
  title_them_phieu_nhap_ncc: "title_them_phieu_nhap_ncc",
  fieldMa_hoa_don: "fieldMa_hoa_don",
  placeholder_Tao_tu_dong: "placeholder_Tao_tu_dong",

  info_Chi_xem_va_khong_duoc_thay_doi: "info_Chi_xem_va_khong_duoc_thay_doi",
  ma_phieu_bi_trung: "ma_phieu_bi_trung",
  bac_si_chua_co_ma_cchn: "bac_si_chua_co_ma_cchn",

  nhap_loi_dan: "nhap_loi_dan",
  ris_nhap_tay: "ris_nhap_tay",
  da_nghi: "da_nghi",
  chua_co_phong_thuc_hien: "chua_co_phong_thuc_hien",

  lich_su_nhap_thuoc: "lich_su_nhap_thuoc",
  gia_co_vat: "gia_co_vat",
  loai_thuoc: "loai_thuoc",

  them_moi_kho: "them_moi_kho",
  kho_chuyen: "kho_chuyen",
  duyet_khi_ke_thuoc: "duyet_khi_ke_thuoc",
  duyet_khi_chuyen_cac_kho: "duyet_khi_chuyen_cac_kho",
  // loai kho
  kho_le: "kho_le",
  kho_tong: "kho_tong",
  nha_thuoc: "nha_thuoc",
  benh_nhan_bhyt: "benh_nhan_bhyt",
  benh_nhan_noi_tru: "benh_nhan_noi_tru",
  benh_nhan_ngoai_tru: "benh_nhan_ngoai_tru",

  nhap_tu_ncc: "nhap_tu_ncc",
  nhap_tu_kho_khac: "nhap_tu_kho_khac",
  xuat_vo_hong: "xuat_vo_hong",
  xuat_het_han_su_dung: "xuat_het_han_su_dung",
  xuat_khac: "xuat_khac",
  ban_benh_nhan: "ban_benh_nhan",
  tao_phieu: "tao_phieu",
  them: "them",
  thong_tin_thuoc: "thong_tin_thuoc",

  so_benh_nhan: "so_benh_nhan",
  thong_tin_kham_nhanh: "thong_tin_kham_nhanh",

  field_Nam_sinh: "field_Nam_sinh",
  con_ton: "con_ton",
  da_het: "da_het",
  danh_sach_kho_thuoc: "danh_sach_kho_thuoc",
  chi_tiet_kho: "chi_tiet_kho",

  tip_xoa_nhieu: "tip_xoa_nhieu",
  tip_tai_len: "tip_tai_len",
  tip_chinh_sua_cot: "tip_chinh_sua_cot",
  tip_tai_xuong: "tip_tai_xuong",
  tip_tai_xuong_ban_mau: "tip_tai_xuong_ban_mau",
  tip_xoa_ban_ghi: "tip_xoa_ban_ghi",
  tip_sua_ban_ghi: "tip_sua_ban_ghi",
  tip_in_ban_ghi: "tip_in_ban_ghi",
  tip_copy_ban_ghi: "tip_copy_ban_ghi",
  tip_trang_ke_tiep: "tip_trang_ke_tiep",
  tip_trang_sau: "tip_trang_sau",
  tip_tai_len_hinh_anh: "tip_tai_len_hinh_anh",

  phan_quyen_nguoi_ban: "phan_quyen_nguoi_ban",
  cho_phep_ban_gia_thang_du: "cho_phep_ban_gia_thang_du",
  huy_duyet_phieu: "huy_duyet_phieu",
  huy_phieu: "huy_phieu",
  confirm_cancel_ticket: "confirm_cancel_ticket",
  luu_va_duyet: "luu_va_duyet",
  tong_tt: "tong_tt",
  duyet: "duyet",
  da_HT: "da_HT",
  phieu_nhap: "phieu_nhap",
  phieu_chuyen_kho: "phieu_chuyen_kho",

  phan_loai_xuat_tra_ncc: "phan_loai_xuat_tra_ncc",
  phan_loai_xuat_vo_hong: "phan_loai_xuat_vo_hong",
  phan_loai_xuat_huy: "phan_loai_xuat_huy",
  phan_loai_ban_benh_nhan: "phan_loai_ban_benh_nhan",
  phan_loai_xuat_khac: "phan_loai_xuat_khac",
  phan_loai_xuat_het_han: "phan_loai_xuat_het_han",
  mau_mo_ta_chan_doan_hinh_anh: "mau_mo_ta_chan_doan_hinh_anh",
  field_Ngay_bat_dau: "field_Ngay_bat_dau",
  field_Ngay_ket_thuc: "field_Ngay_ket_thuc",

  common_Them: "common_Them",
  khoa: "khoa",
  cho_duyet: "cho_duyet",
  danh_sach_thuoc_chi_dinh: "danh_sach_thuoc_chi_dinh",
  dm_may: "dm_may",

  //// Trả kết quả CDHA
  title_page_tra_kq_cdhd: "title_page_tra_kq_cdhd",
  cho_TH: "cho_TH",
  dang_TH: "dang_TH",
  da_TH: "da_TH",
  ngay_tra_kq: "ngay_tra_kq",
  nguoi_thuc_hien: "nguoi_thuc_hien",
  nguoi_tra_kq: "nguoi_tra_kq",
  thoi_gian_tra: "thoi_gian_tra",
  in_kq: "in_kq",
  huy_tra: "huy_tra",
  tra_ket_qua: "tra_ket_qua",
  chup_anh: "chup_anh",
  may_thuc_hien: "may_thuc_hien",

  thuc_hien: "thuc_hien",
  da_tra_kq: "da_tra_kq",
  da_KQ: "da_KQ",
  xem_KQ: "xem_KQ",
  content_huy_tra_phieu: "content_huy_tra_phieu",
  phan_loai_bt: "phan_loai_bt",
  tra_ket_qua_va_in: "tra_ket_qua_va_in",
  chon_mau_mo_ta: "chon_mau_mo_ta",
  chon_may_thuc_hien: "chon_may_thuc_hien",
  noti_vui_long_chon_may_thuc_hien: "noti_vui_long_chon_may_thuc_hien",
  noti_vui_long_chon_phong_thuc_hien: "noti_vui_long_chon_phong_thuc_hien",
  noti_so_luong_anh_toi_da: "noti_so_luong_anh_toi_da",
  noti_tra_kq_thanh_cong: "noti_tra_kq_thanh_cong",
  noti_tra_kq_that_bai: "noti_tra_kq_that_bai",
  title_form_nhap_ket_qua: "title_form_nhap_ket_qua",

  // Support
  gui_cau_hoi: "gui_cau_hoi",
  nhap_noi_dung: "nhap_noi_dung",
  gui: "gui",
  cau_hoi_cua_toi: "cau_hoi_cua_toi",
  danh_sach_cau_hoi_thuong_gap: "danh_sach_cau_hoi_thuong_gap",

  phan_quyen_tao_mau_mo_ta: "phan_quyen_tao_mau_mo_ta",
  phan_quyen_sua_mau_mo_ta: "phan_quyen_sua_mau_mo_ta",
  phan_quyen_xoa_mau_mo_ta: "phan_quyen_xoa_mau_mo_ta",
  phan_quyen_khoa_mau_mo_ta: "phan_quyen_khoa_mau_mo_ta",
  phan_quyen_tra_ket_qua: "phan_quyen_tra_ket_qua",
  phan_quyen_huy_phieu: "phan_quyen_huy_phieu",

  hoi_dap: "hoi_dap",
  cong_cu_ho_tro: "cong_cu_ho_tro",
  noi_dung_phan_hoi: "noi_dung_phan_hoi",
  nhap_noi_dung_phan_hoi: "nhap_noi_dung_phan_hoi",
  cham_soc_khach_hang_context: "cham_soc_khach_hang_context",
  huong_dan_su_dung_he_thong: "huong_dan_su_dung_he_thong",
  tai_pdf: "tai_pdf",
  cham_soc_khach_hang: "cham_soc_khach_hang",
  chen_anh: "chen_anh",
  so_hoan_tien: "so_hoan_tien",

  field_Ten_may: "field_Ten_may",
  field_Ma_BHYT: "field_Ma_BHYT",
  field_Loai_may: "field_Loai_may",
  title_Danh_sach_nguoi_dung: "title_Danh_sach_nguoi_dung",
  menu_mau_mo_ta_cdha: "menu_mau_mo_ta_cdha",
  menu_tra_kq_cdha: "menu_tra_kq_cdha",
  bao_cao_cdha: "bao_cao_cdha",
  huy_tra_phieu: "huy_tra_phieu",
  title_Danh_sach_mau_mo_ta: "title_Danh_sach_mau_mo_ta",
  title_Tao_mau_mo_ta: "title_Tao_mau_mo_ta",

  mau_danh_muc: "mau_danh_muc",
  field_ma_kq_dv: "field_ma_kq_dv",
  field_ten_kq_dv: "field_ten_kq_dv",

  chon_nhom_dv: "chon_nhom_dv",
  chon_loai_dv: "chon_loai_dv",
  chon_dv: "chon_dv",
  btn_mo_file_mo_ta: "btn_mo_file_mo_ta",
  placeholder_nhap_mo_ta: "placeholder_nhap_mo_ta",
  field_ket_luan: "field_ket_luan",
  tu_dong: "tu_dong",
  field_mo_ta: "field_mo_ta",
  field_may_thuc_hien: "field_may_thuc_hien",
  warning_chon_nhom_dv: "warning_chon_nhom_dv",
  warning_chon_loai_dv: "warning_chon_loai_dv",

  btn_xem_them: "btn_xem_them",
  btn_chen_anh: "btn_chen_anh",
  btn_chup_anh: "btn_chup_anh",
  common_Ngay_mai: "common_Ngay_mai",
  next_7_days: "next_7_days",
  next_30_days: "next_30_days",
  last_7_days: "last_7_days",
  last_30_days: "last_30_days",
  other_option: "other_option",
  tong_tra: "tong_tra",
  title_Danh_sach_thuoc_nhap: "title_Danh_sach_thuoc_nhap",

  //key phân hệ khám bệnh
  khambenh_7ngay_gan_nhat: "khambenh_7ngay_gan_nhat",
  khambenh_30ngay_gan_nhat: "khambenh_30ngay_gan_nhat",
  khambenh_xemthem: "khambenh_xemthem",
  khambenh_phieu_so: "khambenh_phieu_so",
  khambenh_err_xoa_dich_vu: "khambenh_err_xoa_dich_vu",
  khambenh_du_kq: "khambenh_du_kq",
  khambenh_cho_kq: "khambenh_cho_kq",
  khambenh_xoa_ban_ghi: "khambenh_xoa_ban_ghi",
  khambenh_confirm_xoa: "khambenh_confirm_xoa",
  khambenh_moi_ngay: "khambenh_moi_ngay",
  khambenh_chia_lam: "khambenh_chia_lam",
  khambenh_lan: "khambenh_lan",
  khambenh_so_luong_thuoc_lon_hon_0: "khambenh_so_luong_thuoc_lon_hon_0",
  khambenh_so_luong_thuoc_khong_du: "khambenh_so_luong_thuoc_khong_du",
  khambenh_toi_da: "khambenh_toi_da",
  khambenh_thuoc: "khambenh_thuoc",
  khambenh_chua_dien_cach_dung: "khambenh_chua_dien_cach_dung",
  khambenh_thuoc_da_het: "khambenh_thuoc_da_het",
  khambenh_sl_toida: "khambenh_sl_toida",
  khambenh_empty: "khambenh_empty",
  khambenh_khong_huy_don_da_ban: "khambenh_khong_huy_don_da_ban",
  khambenh_khong_sua_don_da_ban: "khambenh_khong_sua_don_da_ban",
  khambenh_cho_thanh_toan: "khambenh_cho_thanh_toan",
  khambenh_da_thanh_toan: "khambenh_da_thanh_toan",
  khambenh_homqua: "khambenh_homqua",
  vienphi_dv_khong_thanh_toan: "vienphi_dv_khong_thanh_toan",
  vienphi_hoan_tien_dich_vu: "vienphi_hoan_tien_dich_vu",
  vienphi_ds_dv_can_hoan_tien: "vienphi_ds_dv_can_hoan_tien",
  vienphi_tong_mien_giam: "vienphi_tong_mien_giam",
  vienphi_da_thanh_toan: "vienphi_da_thanh_toan",
  vienphi_da_thuc_hien: "vienphi_da_thuc_hien",
  vienphi_chua_thuc_hien: "vienphi_chua_thuc_hien",
  vienphi_hoan_tien: "vienphi_hoan_tien",
  vienphi_xoa_dich_vu: "vienphi_xoa_dich_vu",
  vienphi_so_hd_day: "vienphi_so_hd_day",
  vienphi_tong_tt: "vienphi_tong_tt",
  vienphi_vui_long_chon_so_tt: "vienphi_vui_long_chon_so_tt",
  vienphi_no_service: "vienphi_no_service",
  vienphi_dv_da_tt: "vienphi_dv_da_tt",
  vienphi_chon_so_hoan_tien: "vienphi_chon_so_hoan_tien",
  vienphi_da_hoan_tien: "vienphi_da_hoan_tien",
  vienphi_ds_cac_dichvu: "vienphi_ds_cac_dichvu",
  vienphi_in_phieu_thu: "vienphi_in_phieu_thu",
  vienphi_chua_thanh_toan: "vienphi_chua_thanh_toan",
  vienphi_da_tt: "vienphi_da_tt",
  vienphi_ngay: "vienphi_ngay",
  vienphi_ghi_chu: "vienphi_ghi_chu",

  //key phân hệ tiếp đón
  tiepdon_nguoi_sua: "tiepdon_nguoi_sua",
  tiepdon_ngay_sua: "tiepdon_ngay_sua",
  tiepdon_nhap_lien_tuc: "tiepdon_nhap_lien_tuc",
  tiepdon_chia_se_thong_tin: "tiepdon_chia_se_thong_tin",
  tiepdon_khong_xoa_dv_da_tt: "tiepdon_khong_xoa_dv_da_tt",
  tiepdon_chi_chon_1_ngay: "tiepdon_chi_chon_1_ngay",
  tiepdon_xoa_cuoc_hen: "tiepdon_xoa_cuoc_hen",
  tiepdon_huy_cuoc_hen: "tiepdon_huy_cuoc_hen",
  tiepdon_chinh_sua_cuoc_hen: "tiepdon_chinh_sua_cuoc_hen",
  tiepdon_cho_xac_nhan: "tiepdon_cho_xac_nhan",
  tiepdon_da_xac_nhan: "tiepdon_da_xac_nhan",
  tiepdon_da_huy: "tiepdon_da_huy",
  tiepdon_xac_nhan_xoa_cuoc_kham: "tiepdon_xac_nhan_xoa_cuoc_kham",
  tiepdon_xac_nhan_xoa_ck_da_chon: "tiepdon_xac_nhan_xoa_ck_da_chon",
  tiepdon_chua_chon_phong: "tiepdon_chua_chon_phong",
  tiepdon_doi_cuoc_hen_thanh_cong: "tiepdon_doi_cuoc_hen_thanh_cong",
  tiepdon_doi_cuoc_hen_that_bai: "tiepdon_doi_cuoc_hen_that_bai",
  tiepdon_in_dich_vu: "tiepdon_in_dich_vu",
  tiepdon_da_nghi: "tiepdon_da_nghi",
  tiepdon_khong_hoat_dong: "tiepdon_khong_hoat_dong",
  tiepdon_da_kham: "tiepdon_da_kham",
  tiepdon_xoa_cuoc_kham: "tiepdon_xoa_cuoc_kham",
  tiepdon_xac_nhan_huy_ck: "tiepdon_xac_nhan_huy_ck",
  tiepdon_in_cuoc_kham: "tiepdon_in_cuoc_kham",
  tiepdon_sua_cuoc_kham: "tiepdon_sua_cuoc_kham",
  tiepdon_xac_nhan_huy_cuoc_kham: "tiepdon_xac_nhan_huy_cuoc_kham",
  tiepdon_xac_nhan_huy_lich_hen: "tiepdon_xac_nhan_huy_lich_hen",
  bc_da_thuc_hien: "bc_da_thuc_hien",
  bc_chua_thuc_hien: "bc_chua_thuc_hien",
  trang_thai_thuc_hien: "trang_thai_thuc_hien",
  xac_nhan_cuoc_hen_thanh_cong: "xac_nhan_cuoc_hen_thanh_cong",

  duoc_message_chua_chon_loai_kho: "duoc_message_chua_chon_loai_kho",
  duoc_chon_nguoi_ban: "duoc_chon_nguoi_ban",
  duoc_ton_kha_dung: "duoc_ton_kha_dung",
  duoc_nguoi_huy: "duoc_nguoi_huy",
  duoc_ngay_huy: "duoc_ngay_huy",
  duoc_thuoc: "duoc_thuoc",
  duoc_message_lay_ds_kho_thuoc_loi: "duoc_message_lay_ds_kho_thuoc_loi",
  duoc_xoa_nha_cung_cap: "duoc_xoa_nha_cung_cap",
  duoc_sua_nha_cung_cap: "duoc_sua_nha_cung_cap",
  confirm_xoa_nha_cung_cap: "confirm_xoa_nha_cung_cap",
  duoc_hay_nhap_gia_tri_lon_hon_0: "duoc_hay_nhap_gia_tri_lon_hon_0",
  duoc_phieu_dieu_chinh_chi_tiet: "duoc_phieu_dieu_chinh_chi_tiet",
  duoc_in_phieu_thanh_toan: "duoc_in_phieu_thanh_toan",
  duoc_phieu_thanh_toan_chi_tiet: "duoc_phieu_thanh_toan_chi_tiet",
  duoc_ma_ncc_trung: "duoc_ma_ncc_trung",
  duoc_ma_so_thue_trung: "duoc_ma_so_thue_trung",
  duoc_them_moi_nhom_ncc: "duoc_them_moi_nhom",
  duoc_ten_nhom_ncc: "duoc_ten_nhom_ncc",
  duoc_nhap_gia_tri_nho_toi_lon: "duoc_nhap_gia_tri_nho_toi_lon",
  da_xac_nhan: "da_xac_nhan",
  duoc_chua_chon_kho_thuoc: "duoc_chua_chon_kho_thuoc",
  duoc_so_luong_toi_da: "duoc_so_luong_toi_da",
  duoc_so_luong_lon_hon_ton_kha_dung: "duoc_so_luong_lon_hon_ton_kha_dung",
  duoc_thuoc_da_ton_tai: "duoc_thuoc_da_ton_tai",
  duoc_so_luong_thuoc_khong_kha_dung: "duoc_so_luong_thuoc_khong_kha_dung",
  duoc_chi_tiet_phieu_chuyen_kho: "duoc_chi_tiet_phieu_chuyen_kho",
  duoc_them_phieu_chuyen_kho: "duoc_them_phieu_chuyen_kho",
  duoc_kho_xuat_thuoc: "duoc_kho_xuat_thuoc",
  duoc_kho_nhap_thuoc: "duoc_kho_nhap_thuoc",
  duoc_chua_chon_kho_xuat: "duoc_chua_chon_kho_xuat",
  duoc_xoa_thuoc: "duoc_xoa_thuoc",
  duoc_gia_nhap_lon_hon_gia_ban: "duoc_gia_nhap_lon_hon_gia_ban",
  duoc_chua_nhap_thuoc: "duoc_chua_nhap_thuoc",
  duoc_gia_sau_vat: "duoc_gia_sau_vat",
  duoc_chua_chon_thuoc: "duoc_chua_chon_thuoc",
  duoc_phieu_xuat_tra_ncc: "duoc_phieu_xuat_tra_ncc",
  duoc_phieu_xuat_vo_hong: "duoc_phieu_xuat_vo_hong",
  duoc_phieu_xuat_huy: "duoc_phieu_xuat_huy",
  duoc_phieu_xuat_het_han: "duoc_phieu_xuat_het_han",
  duoc_phieu_xuat_khac: "duoc_phieu_xuat_khac",
  duoc_phieu_ban_benh_nhan: "duoc_phieu_ban_benh_nhan",
  duoc_ly_do_xuat: "duoc_ly_do_xuat",
  duoc_chon_kho_thuoc: "duoc_chon_kho_thuoc",
  duoc_chon_ncc: "duoc_chon_ncc",
  duoc_vui_long_ke_don_thuoc: "duoc_vui_long_ke_don_thuoc",
  duoc_khong_du_so_luong: "duoc_khong_du_so_luong",
  duoc_nhap_day_du_thong_tin_benh_nhan: "duoc_nhap_day_du_thong_tin_benh_nhan",
  duoc_chua_ban: "duoc_chua_ban",
  duoc_da_ban: "duoc_da_ban",
  duoc_nam_sinh_hop_le: "duoc_nam_sinh_hop_le",
  duoc_khach_vang_lai: "duoc_khach_vang_lai",
  duoc_them_benh_nhan: "duoc_them_benh_nhan",
  duoc_huy_don: "duoc_huy_don",
  duoc_huy_duyet: "duoc_huy_duyet",
  duoc_duyet_don: "duoc_duyet_don",
  duoc_huy_thanh_toan: "duoc_huy_thanh_toan",
  duoc_xuat_kho_in_phieu: "duoc_xuat_kho_in_phieu",
  xuat_kho: "xuat_kho",
  duoc_huy_xuat_kho: "duoc_huy_xuat_kho",
  duoc_confirm_xoa_don_thuoc: "duoc_confirm_xoa_don_thuoc",
  phan_mem_ho_tro: "phan_mem_ho_tro",
  mau_dm_khoa: "mau_dm_khoa",
  mau_dm_phong: "mau_dm_phong",
  mau_dm_don_vi_tinh: "mau_dm_don_vi_tinh",
  mau_dm_nhom_dich_vu: "mau_dm_nhom_dich_vu",
  mau_dm_loai_dich_vu: "mau_dm_loai_dich_vu",
  mau_dm_dich_vu: "mau_dm_dich_vu",
  mau_dm_thuoc: "mau_dm_thuoc",
  mau_dm_hang_san_xuat: "mau_dm_hang_san_xuat",
  mau_dm_hoat_chat: "mau_dm_hoat_chat",
  mau_dm_duong_dung: "mau_dm_duong_dung",
  tong_hop_template_mau_danh_muc: "tong_hop_template_mau_danh_muc",
  tai_tat_ca_cac_mau: "tai_tat_ca_cac_mau",
  mau_dm_nguon_khach: "mau_dm_nguon_khach",

  //title báo cáo
  bao_cao_theo_doi_thu_ngan: "bao_cao_theo_doi_thu_ngan",
  bao_cao_thu_ngan_benh_nhan: "bao_cao_thu_ngan_benh_nhan",
  bao_cao_phuong_thuc_thanh_toan: "bao_cao_phuong_thuc_thanh_toan",
  bao_cao_bac_si_chi_dinh: "bao_cao_bac_si_chi_dinh",
  bao_cao_phong_chuc_nang: "bao_cao_phong_chuc_nang",
  bao_cao_doanh_thu: "bao_cao_doanh_thu",
  bao_cao_doanh_thu_kh: "bao_cao_doanh_thu_kh",
  bao_cao_the_kho: "bao_cao_the_kho",
  bao_cao_su_dung_thuoc_khang_sinh: "bao_cao_su_dung_thuoc_khang_sinh",
  bao_cao_bien_ban_kiem_ke_thuoc: "bao_cao_bien_ban_kiem_ke_thuoc",
  bao_cao_xuat_nhap_ton: "bao_cao_xuat_nhap_ton",
  bao_cao_phieu_thu_dich_vu: "bao_cao_phieu_thu_dich_vu",
  bao_cao_doanh_thu_ban_thuoc: "bao_cao_doanh_thu_ban_thuoc",
  // bao_cao_bac_si_chi_dinh : "",
  // bao_cao_bac_si_chi_dinh : ""
  // fixx ngôn ngữ báo cáo
  bao_cao_tien_kham: "bao_cao_tien_kham",
  bao_cao_tien_dvkt: "bao_cao_tien_dvkt",
  bao_cao_tien_thuoc: "bao_cao_tien_thuoc",
  bao_cao_tien_huy: "bao_cao_tien_huy",
  bao_cao_tien_xet_nghiem: "bao_cao_tien_xet_nghiem",
  bao_cao_tien_khac: "bao_cao_tien_khac",
  bao_cao_tien_cdha: "bao_cao_tien_cdha",
  bao_cao_tong_cong: "bao_cao_tong_cong",
  bao_cao_tien_mien_giam: "bao_cao_tien_mien_giam",
  phong_chuc_nang: "phong_chuc_nang",
  bao_cao_xuat_tat_ca_thuoc: "bao_cao_xuat_tat_ca_thuoc",
  bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat: "bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat",
  bao_cao_sl_so_sach: "bao_cao_sl_so_sach",
  bao_cao_ngoai_tru: "bao_cao_ngoai_tru",
  bao_cao_tien: "bao_cao_tien",
  bao_cao_ten_thuoc_ham_luong_nong_do: "bao_cao_ten_thuoc_ham_luong_nong_do",
  bao_cao_chon_kho_thuoc: "bao_cao_chon_kho_thuoc",
  bao_cao_chon_loai_thuoc: "bao_cao_chon_loai_thuoc",

  bao_cao_ton_dau_ky: "bao_cao_ton_dau_ky",
  bao_cao_ton_cuoi_ky: "bao_cao_ton_cuoi_ky",
  bao_cao_sl_nhap: "bao_cao_sl_nhap",
  bao_cao_sl_xuat: "bao_cao_sl_xuat",
  bao_cao_khac: "bao_cao_khac",
  bao_cao_sct_nhap: "bao_cao_sct_nhap",
  bao_cao_sct_xuat: "bao_cao_sct_xuat",
  bao_cao_lo_sx: "bao_cao_lo_sx",
  bao_cao_han_dung: "bao_cao_han_dung",
  bao_cao_dien_giai: "bao_cao_dien_giai",
  bao_cao_sl_ton_dau: "bao_cao_sl_ton_dau",
  bao_cao_sl_ton_cuoi: "bao_cao_sl_ton_cuoi",
  bao_cao_hl: "bao_cao_hl",
  bao_cao_thoi_gian_365_ngay: "bao_cao_thoi_gian_365_ngay",
  bao_cao_khong_co_thuoc: "bao_cao_khong_co_thuoc",
  bao_cao_chon_nhan_su: "bao_cao_chon_nhan_su",
  bao_cao_nhan_su_da_ton_tai: "bao_cao_nhan_su_da_ton_tai",
  bao_cao_thanh_phan_tham_gia: "bao_cao_thanh_phan_tham_gia",
  bao_cao_them_thanh_phan_tham_gia: "bao_cao_them_thanh_phan_tham_gia",
  bao_cao_chon_kho_xuat_bao_cao: "bao_cao_chon_kho_xuat_bao_cao",
  bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du: "bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du",
  bao_cao_chuc_vu: "bao_cao_chuc_vu",
  bao_cao_sdkt: "bao_cao_sdkt",
  bao_cao_hinh_thuc_thanh_toan: "bao_cao_hinh_thuc_thanh_toan",

  common_lua_chon_khac: "common_lua_chon_khac",
  common_xuat_file_thanh_cong: "common_xuat_file_thanh_cong",
  common_co_loi_xay_ra: "common_co_loi_xay_ra",
  commom_khong_co_du_lieu: "commom_khong_co_du_lieu",
  nguoi_lap_bao_cao: "nguoi_lap_bao_cao",
  so_dang_ky: "so_dang_ky",
  so_chung_tu: "so_chung_tu",
  so_luong_dau_ky: "so_luong_dau_ky",
  stt: "stt",
  ten_bs_chi_dinh: "ten_bs_chi_dinh",
  cong_khoan: "cong_khoan",
  bac_si: "bac_si",
  truong_phong_tckt: "truong_phong_tckt",
  ky_ten_va_dong_dau: "ky_ten_va_dong_dau",
  ky_ten_va_ghi_ro_ho_ten: "ky_ten_va_ghi_ro_ho_ten",
  ma: "ma",
  ten_bn: "ten_bn",
  truong_khoa_duoc: "truong_khoa_duoc",
  to_kiem_ke_gom_co: "to_kiem_ke_gom_co",
  thoi_gian_kiem_ke_thuoc: "thoi_gian_kiem_ke_thuoc",
  dia_chi_kiem_ke: "dia_chi_kiem_ke",
  hong_vo: "hong_vo",
  han_dung: "han_dung",
  ghi_nho: "ghi_nho",
  so_sach: "so_sach",
  thuc_te: "thuc_te",
  y_kien_de_xuat: "y_kien_de_xuat",
  thanh_vien: "thanh_vien",
  sl_nhap: "sl_nhap",
  sl_xuat: "sl_xuat",
  thuoc_kho: "thuoc_kho",
  ten_nhan_vien: "ten_nhan_vien",
  so_nhan_vien: "so_nhan_vien",
  nhan_vien: "nhan_vien",
  thong_ke_phong_chuc_nang: "thong_ke_phong_chuc_nang",
  xuat: "xuat",
  nguoi_lap_bang: "nguoi_lap_bang",
  hoa_don_ban_hang: "hoa_don_ban_hang",
  khach_hang: "khach_hang",
  khach_mua_theo_don: "khach_mua_theo_don",
  khach_vang_lai: "khach_vang_lai",
  ten_san_pham: "ten_san_pham",
  tong_tien_hang: "tong_tien_hang",
  chiet_khau_hoa_don: "chiet_khau_hoa_don",
  chiet_khau: "chiet_khau",
  tong_thanh_toan: "tong_thanh_toan",
  cam_on_va_hen_gap_lai: "cam_on_va_hen_gap_lai",
  phieu_nhap_hang: "phieu_nhap_hang",
  nguoi_lap: "nguoi_lap",
  chi_nhanh: "chi_nhanh",
  ma_hang: "ma_hang",
  ten_hang: "ten_hang",
  giam_gia: "giam_gia",
  tong_thanh_tien: "tong_thanh_tien",
  mien_giam_hoa_don: "mien_giam_hoa_don",
  tien_ncc_can_tra: "tien_ncc_can_tra",
  ky_va_ghi_ro_ho_ten: "ky_va_ghi_ro_ho_ten",
  gia_tri_huy: "gia_tri_huy",
  sl_huy: "sl_huy",
  ma_so: "ma_so",
  so: "so",
  ten_thuoc_hoa_chat: "ten_thuoc_hoa_chat",
  ham_luong_nong_do: "ham_luong_nong_do",
  ki_thuat_chi_dinh: "ki_thuat_chi_dinh",
  loi_dan_cua_bac_si_khoa: "loi_dan_cua_bac_si_khoa",
  bac_si_phu_trach_kham: "bac_si_phu_trach_kham",
  do_tuoi: "do_tuoi",
  kham_lai_theo_don_thuoc_nay: "kham_lai_theo_don_thuoc_nay",
  bang_chu: "bang_chu",
  thong_tin_bo_loc: "thong_tin_bo_loc",
  ten_benh_nhan: "ten_benh_nhan",
  kham_ngay: "kham_ngay",
  phieu_co_gia_tri_trong_ngay: "phieu_co_gia_tri_trong_ngay",
  phieu_thu_phi_dich_vu: "phieu_thu_phi_dich_vu",
  lien_0_dau: "lien_0_dau",
  thu_ngan_giu: "thu_ngan_giu",
  khach_hang_giu: "khach_hang_giu",
  thong_tin_khach_hang: "thong_tin_khach_hang",
  ma_khach_hang: "ma_khach_hang",
  ten_khach_hang: "ten_khach_hang",
  thanh_tien: "thanh_tien",
  tong_cong: "tong_cong",
  tong_so_tien: "tong_so_tien",
  phieu_tom_tat_kham: "phieu_tom_tat_kham",
  ma_don_thuoc_mau_da_ton_tai: "ma_don_thuoc_mau_da_ton_tai",

  common_benh_vien: "common_benh_vien",
  common_bo_y_te: "common_bo_y_te",
  common_ngay_thang_nam: "common_ngay_thang_nam",
  common_tu_ngay: "common_tu_ngay",
  common_den: "common_den",
  ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao: "ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao",
  ham_luong_nong_do_quy_cach_dong_goi: "ham_luong_nong_do_quy_cach_dong_goi",
  ky_ghi_ro_ho_ten: "ky_ghi_ro_ho_ten",
  nguoi_tao_phieu: "nguoi_tao_phieu",
  tm_cong_ty: "tm_cong_ty",
  chi_tiet_cong_no: "chi_tiet_cong_no",
  so_kiem_soat: "so_kiem_soat",
  bien_ban_kiem_nhap_thuoc: "bien_ban_kiem_nhap_thuoc",
  common_tt: "common_tt",
  bs_chi_dinh: "bs_chi_dinh",
  phong_tai_chinh_ke_toan: "phong_tai_chinh_ke_toan",
  mau_bao_cao_ve_khang_sinh: "mau_bao_cao_ve_khang_sinh",
  so_giuong_benh_ke_hoach_thuc_ke: "so_giuong_benh_ke_hoach_thuc_ke",
  cong_suat_su_dung_giuong_benh: "cong_suat_su_dung_giuong_benh",
  tt_hoat_chat: "tt_hoat_chat",
  tt_biet_duoc: "tt_biet_duoc",
  nong_do_ham_luong: "nong_do_ham_luong",
  ma_atc: "ma_atc",
  ten_biet_duoc: "ten_biet_duoc",
  don_vi_don_goi: "don_vi_don_goi",
  duong_dung: "duong_dung",
  bao_cao_su_dung_thuoc: "bao_cao_su_dung_thuoc",
  thoi_gian_kiem_ke_thuoc_tinh_den: "thoi_gian_kiem_ke_thuoc_tinh_den",
  lich_su_nhap_tra_ncc: "lich_su_nhap_tra_ncc",
  nguoi_thu_tien: "nguoi_thu_tien",
  no_cuoi_ky: "no_cuoi_ky",
  phat_sinh_trong_ky: "phat_sinh_trong_ky",
  tong_hoa_don: "tong_hoa_don",
  txt_can_tra: "txt_can_tra",
  txt_thuc_tra: "txt_thuc_tra",
  txt_ghi_no: "txt_ghi_no",
  txt_ghi_co: "txt_ghi_co",
  da_kiem_ke_tai: "da_kiem_ke_tai",
  common_gio: "common_gio",

  title_Phieu_thu_phi_dich_vu: "title_Phieu_thu_phi_dich_vu",
  title_Phieu_tom_tat_kham: "title_Phieu_tom_tat_kham",
  nguoi_tao_bao_cao: "nguoi_tao_bao_cao",
  sdt: "sdt",
  field_doi_tuong_bn: "field_doi_tuong_bn",
  cong_hoa_xa_hoi_chu_nghia: "cong_hoa_xa_hoi_chu_nghia",
  doc_lay_tu_do_hanh_phuc: "doc_lay_tu_do_hanh_phuc",
  txt_ngay_nhap: "txt_ngay_nhap",
  txt_hoa_don_so: "txt_hoa_don_so",
  txt_nhap_tai_kho: "txt_nhap_tai_kho",
  common_da: "common_da",
  txt_nguoi_giao: "txt_nguoi_giao",
  txt_ke_toan: "txt_ke_toan",
  txt_Thu_truong_don_vi: "txt_Thu_truong_don_vi",
  noti_so_tien_am: "noti_so_tien_am",
  noti_khong_dong: "noti_khong_dong",
  title_Chi_tiet_cong_no_ncc: "title_Chi_tiet_cong_no_ncc",
  no_dau_ky: "no_dau_ky",
  txt_cong_khoan: "txt_cong_khoan",
  txt_nguoi_tao_phieu: "txt_nguoi_tao_phieu",
  title_phieu_thanh_toan: "title_phieu_thanh_toan",
  txt_tm_cong_ty: "txt_tm_cong_ty",
  common_Tong: "common_Tong",
  txt_phieu_chi: "txt_phieu_chi",
  txt_nguoi_nhan_tien: "txt_nguoi_nhan_tien",
  txt_ly_do_nhan: "txt_ly_do_nhan",
  txt_So_tien: "txt_So_tien",
  txt_thu_quy: "txt_thu_quy",
  txt_nguoi_lap_phieu: "txt_nguoi_lap_phieu",
  txt_nguoi_nhan: "txt_nguoi_nhan",
  txt_phong_tai_chinh_ke_toan: "txt_phong_tai_chinh_ke_toan",

  loi_nhap_form: "loi_nhap_form",
  status_Da_hoan_thanh: "status_Da_hoan_thanh",

  text_khoi_tao_du_lieu: "text_khoi_tao_du_lieu",
  noti_dang_chuyen_doi_file: "noti_dang_chuyen_doi_file",
  noti_chuyen_doi_file_thanh_cong: "noti_chuyen_doi_file_thanh_cong",
  noti_chuyen_doi_file_that_bai: "noti_chuyen_doi_file_that_bai",
  noti_dinh_dang_file_word: "noti_dinh_dang_file_word",
  noti_vui_long_nhap_mo_ta: "noti_vui_long_nhap_mo_ta",
  thuoc_thuong: "thuoc_thuong",
  thuoc_nha_thuoc: "thuoc_nha_thuoc",
  title_thong_bao_chuyen_kho: "title_thong_bao_chuyen_kho",
  prefix_so_luong_thuoc_trong_kho_hien_khong_du: "prefix_so_luong_thuoc_trong_kho_hien_khong_du",
  suffix_so_luong_thuoc_trong_kho_hien_khong_du: "suffix_so_luong_thuoc_trong_kho_hien_khong_du",

  sl_chuyen_kho: "sl_chuyen_kho",
  noti_so_luong_duyet_khong_lon_hon_ton_kha_dung: "noti_so_luong_duyet_khong_lon_hon_ton_kha_dung",

  xuat_ban_dau_sac: "xuat_ban_dau_sac",
  duoc_them_moi_nhom: "duoc_them_moi_nhom",
  tao_moi_mau_mo_ta: "tao_moi_mau_mo_ta",
  noti_chua_nhap_mo_ta: "noti_chua_nhap_mo_ta",
  noti_chua_nhap_ket_luan: "noti_chua_nhap_ket_luan",
  error_khoa_da_duoc_chon: "error_khoa_da_duoc_chon",
  prefix_xoa_lich: "prefix_xoa_lich",
  noti_chua_hoan_thanh_dich_vu: "noti_chua_hoan_thanh_dich_vu",
  file_hdsd: "file_hdsd",
  cai_dat: "cai_dat",
  chi_tiet_cuoc_kham: "chi_tiet_cuoc_kham",
  tim_kiem_lich_lam_viec: "tim_kiem_lich_lam_viec",
  dat_cau_hoi_thanh_cong: "dat_cau_hoi_thanh_cong",
  dong_y: "dong_y",
  content_sau_khi_dat_cau_hoi_thanh_cong: "content_sau_khi_dat_cau_hoi_thanh_cong",
  ho_ten: "ho_ten",
  thu_tu: "thu_tu",
  toi_da_ky_tu: "toi_da_ky_tu",
  khong_duoc_de_trong: "khong_duoc_de_trong",
  thong_tin_doi_tuong: "thong_tin_doi_tuong",

  ten_nguoi_than: "ten_nguoi_than",
  sdt_nguoi_than: "sdt_nguoi_than",
  quan_he_gia_dinh: "quan_he_gia_dinh",
  dia_chi_nguoi_than: "dia_chi_nguoi_than",

  //update
  tong_chi_phi_KCB: "tong_chi_phi_KCB",
  bh_thanh_toan: "bh_thanh_toan",
  benh_nhan_da_tt: "benh_nhan_da_tt",
  benh_nhan_chua_tt: "benh_nhan_chua_tt",
  can_nang: "can_nang",
  chieu_cao: "chieu_cao",

  tien_su_benh: "tien_su_benh",
  ban_than: "ban_than",
  gia_dinh: "gia_dinh",
  tien_sua_benh_ban_than: "tien_sua_benh_ban_than",
  benh_su: "benh_su",
  kham_benh: "kham_benh",
  tien_su_san_khoa: "tien_su_san_khoa",
  tien_su_benh_gia_dinh: "tien_su_benh_gia_dinh",
  tien_su_di_ung: "tien_su_di_ung",
  trieu_chung_lam_sang: "trieu_chung_lam_sang",
  tai_nan_thuong_tich: "tai_nan_thuong_tich",
  toan_than: "toan_than",
  cac_bo_phan: "cac_bo_phan",
  tuan_hoan: "tuan_hoan",
  ho_hap: "ho_hap",
  tieu_hoa: "tieu_hoa",
  than_tiet_nieu: "than_tiet_nieu",
  noi_tiet: "noi_tiet",
  co_xuong_khop: "co_xuong_khop",
  than_kinh: "than_kinh",
  bs_kham: "bs_kham",
  tien_su_benh_khac: "tien_su_benh_khac",
  chan_doan_so_bo: "chan_doan_so_bo",
  icd_benh_chinh: "icd_benh_chinh",
  icd_yhct_chinh: "icd_yhct_chinh",
  icd_benh_kem_theo: "icd_benh_kem_theo",
  idc_yhct_kem: "idc_yhct_kem",
  chan_doan_xac_dinh: "chan_doan_xac_dinh",
  huong_xu_tri: "huong_xu_tri",
  ngay_tai_kham: "ngay_tai_kham",
  loi_dan: "loi_dan",
  hoi_benh: "hoi_benh",
  kham_tong_quan: "kham_tong_quan",
  chan_doan_xu_tri: "chan_doan_xu_tri",
  confirm_huy_xuat_kho: "confirm_huy_xuat_kho",
  confirm_huy_thanh_toan_don_thuoc: "confirm_huy_thanh_toan_don_thuoc",
  confirm_huy_duyet_don_thuoc: "confirm_huy_duyet_don_thuoc",
  confirm_ket_thuc_cuoc_kham: "confirm_ket_thuc_cuoc_kham",
  ke_don: "ke_don",
  vui_long_nhap_chan_doan_so_bo: "vui_long_nhap_chan_doan_so_bo",
  nguon_khach: "nguon_khach",
  mau_thong_tin: "mau_thong_tin",

  //CÁCH DÙNG
  them_moi_cach_dung: "them_moi_cach_dung",
  ten_cach_dung: "ten_cach_dung",
  vui_long_nhap_ten_cach_dung: "vui_long_nhap_ten_cach_dung",

  lich_su_kham_benh: "lich_su_kham_benh",
  thong_tin_ck: "thong_tin_ck",
  don_thuoc_cu: "don_thuoc_cu",
  ket_qua_cls: "ket_qua_cls",
  xem_nhanh: "xem_nhanh",
  xem_chi_tiet: "xem_chi_tiet",
  ngay_gio_y_lenh: "ngay_gio_y_lenh",
  so_ngay_sd: "so_ngay_sd",
  huong_dan_su_dung: "huong_dan_su_dung",
  xem_nhanh_lich_su_kham_benh: "xem_nhanh_lich_su_kham_benh",
  tien_su: "tien_su",
  new_so_ngay_sd: "new_so_ngay_sd",
  new_moi_ngay: "new_moi_ngay",
  tan_suat_sd: "tan_suat_sd",
  txt_ngay: "txt_ngay",

  chan_doan_hinh_anh: "chan_doan_hinh_anh",
  phieu_kham_benh: "phieu_kham_benh",
  don_thuoc_dich_vu: "don_thuoc_dich_vu",
  ma_benh_nhan: "ma_benh_nhan",
  chan_doan_chinh: "chan_doan_chinh",
  chan_doan_phu: "chan_doan_phu",
  ngay_dung: "ngay_dung",
  chi_dan_dung_thuoc: "chi_dan_dung_thuoc",
  loi_dan_bac_sy: "loi_dan_bac_sy",

  kham_lai_xin_mang_theo_don: "kham_lai_xin_mang_theo_don",
  ten_bo_hoac_me_cua_tre: "ten_bo_hoac_me_cua_tre",

  title_phieu_chi_dinh_cho_bn_ngoai_bh: "title_phieu_chi_dinh_cho_bn_ngoai_bh",
  title_phieu_chi_dinh_dich_vu: "title_phieu_chi_dinh_dich_vu",
  title_phieu_bao_cao_y_te: "title_phieu_bao_cao_y_te",
  khoa_phong: "khoa_phong",
  tong_tien_nguoi_benh_phai_tra: "tong_tien_nguoi_benh_phai_tra",
  da_duoc_tu_van_day_du: "da_duoc_tu_van_day_du",
  da_duoc_tu_van_day_du_v2: "da_duoc_tu_van_day_du_v2",

  error_loi_ket_noi: "error_loi_ket_noi",
  cho_thuc_hien: "cho_thuc_hien",
  Dang_thuc_hien: "Dang_thuc_hien",

  vui_long_chon_thuoc: "vui_long_chon_thuoc",
  don_thuoc_mau: "don_thuoc_mau",
  vui_long_chon_kho: "vui_long_chon_kho",
  ma_don: "ma_don",
  ten_don: "ten_don",
  danh_sach_thuoc_chi_dinh_trong_don: "danh_sach_thuoc_chi_dinh_trong_don",
  in_don_tu_van: "in_don_tu_van",
  phong_tiep_don: "phong_tiep_don",
  noti_chua_luu_thay_doi_don_thuoc_khi_bam_in: "noti_chua_luu_thay_doi_don_thuoc_khi_bam_in",

  phieu_tu_van: "phieu_tu_van",
  noti_don_thuoc_khong_co_thuoc_byt: "noti_don_thuoc_khong_co_thuoc_byt",
  noti_don_thuoc_khong_co_thuoc_tpcn: "noti_don_thuoc_khong_co_thuoc_tpcn",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc: "noti_vui_long_nhap_day_du_cac_truong_bat_buoc",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don: "noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don",
  noti_vui_long_nhap_dung_dinh_dang: "noti_vui_long_nhap_dung_dinh_dang",
  nhap_gia_tri_lon_hon: "nhap_gia_tri_lon_hon",
  va_nho_hon_hoac_bang: "va_nho_hon_hoac_bang",
  chua_co_lich_su: "chua_co_lich_su",
  chua_chon_benh_nhan_vao_kham: "chua_chon_benh_nhan_vao_kham",

  //popup thông tin khám
  ttk_tieu_de: "ttk_tieu_de",
  ttk_tao_moi: "ttk_tao_moi",
  nhap_ten_mau: "nhap_ten_mau",
  luu_mau: "luu_mau",
  sua_mau: "sua_mau",
  msg_ten_mau: "msg_ten_mau",
  msg_xoa_mau_thanh_cong: "msg_xoa_mau_thanh_cong",
  tooltip_sua_mau: "tooltip_sua_mau",
  txt_xac_nhan_xoa_mau: "txt_xac_nhan_xoa_mau",
  msg_luu_mau_thanh_cong: "msg_luu_mau_thanh_cong",
  msg_sua_mau_thanh_cong: "msg_sua_mau_thanh_cong",
  kq_may: "kq_may",
  ket_qua_xet_nghiem: "ket_qua_xet_nghiem",
  thong_tin_ket_qua_xn: "thong_tin_ket_qua_xn",
  file_dinh_kem: "file_dinh_kem",
  ten_xet_nghiem: "ten_xet_nghiem",
  gia_tri_tham_chieu: "gia_tri_tham_chieu",
  ngay_nhan_kq: "ngay_nhan_kq",
  thiet_bi: "thiet_bi",
  so_chay: "so_chay",
  ngay_chay: "ngay_chay",
  ngay_duyet: "ngay_duyet",
  chi_dinh: "chi_dinh",
  thong_tin_file_dinh_kem: "thong_tin_file_dinh_kem",
  khoang_tham_chieu: "khoang_tham_chieu",
  nguoi_lay_mau: "nguoi_lay_mau",
  thoi_gian_lay_mau: "thoi_gian_lay_mau",
  ket_qua_nam_lech: "ket_qua_nam_lech",
  ket_qua_nam_giua: "ket_qua_nam_giua",
  ket_qua_in_dam: "ket_qua_in_dam",
  trai: "trai",
  phai: "phai",
  label_ngay_tiep_don: "label_ngay_tiep_don",

  khong_dung_dinh_dang: "khong_dung_dinh_dang",
  ten_hinh_thuc_thanh_toan: "ten_hinh_thuc_thanh_toan",

  answer_co: "answer_co",
  answer_khong: "answer_khong",
  khai_thac_benh_su: "khai_thac_benh_su",
  thong_tin_benh_nhan: "thong_tin_benh_nhan",
  question_1: "question_1",
  question_2: "question_2",
  question_3: "question_3",
  question_4: "question_4",
  question_family: "question_family",
  question_info_1: "question_info_1",
  question_sinh_con: "question_sinh_con",
  question_nhap_vien: "question_nhap_vien",
  nguyen_vong_khac: "nguyen_vong_khac",
  lich_kham_chua_hoan_thanh: "lich_kham_chua_hoan_thanh",
  huyet_ap_cao: "huyet_ap_cao",
  tieu_duong: "tieu_duong",
  tai_bien_nao: "tai_bien_nao",
  benh_tim_mach: "benh_tim_mach",
  benh_than: "benh_than",
  benh_kawasaki: "benh_kawasaki",
  sot_xuat_huyet: "sot_xuat_huyet",
  benh_lao: "benh_lao",
  viem_da_di_ung: "viem_da_di_ung",
  other: "other",
  ung_thu: "ung_thu",
  dau_nua_dau: "dau_nua_dau",
  bao_chi: "bao_chi",
  cong_ty_ban_be: "cong_ty_ban_be",
  save_image: "save_image",
  error_khong_co_dich_vu: "error_khong_co_dich_vu",
  action_dung: "action_dung",
  chup_tu_dong: "chup_tu_dong",
  so_luong_anh_toi_da: "so_luong_anh_toi_da",
  thoi_gian_dem_lui: "thoi_gian_dem_lui",
  tiep_tuc_tai_anh_len: "tiep_tuc_tai_anh_len",
  chinh_sua_anh: "chinh_sua_anh",
  chon_anh: "chon_anh",
  tai_len_hinh_anh: "tai_len_hinh_anh",

  ma_loai_dich_vu: "ma_loai_dich_vu",
  nhom_loai_dich_vu: "nhom_loai_dich_vu",
  truong_khoa_chan_doan_hinh_anh: "truong_khoa_chan_doan_hinh_anh",
  thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang: "thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang",
  thoi_gian_chi_dinh: "thoi_gian_chi_dinh",
  ngay_thanh_toan: "ngay_thanh_toan",
  ma_kh: "ma_kh",
  phieu_dich_vu: "phieu_dich_vu",

  CIS_ql_pk: "CIS_ql_pk",
  LIS_ql_xn: "LIS_ql_xn",
  RIS_ql_cdha: "RIS_ql_cdha",
  PRM_ql_cskh: "PRM_ql_cskh",
  home_vtc: "home_vtc",
  noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan: "noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan",
  khuyen_nghi: "khuyen_nghi",
  cap_nhat_phan_mem: "cap_nhat_phan_mem",
  content_update_version: "content_update_version",
  content_update_version_reload: "content_update_version_reload",
  thu_gon: "thu_gon",
  mo_rong: "mo_rong",
  trang_thai_kham: "trang_thai_kham",
  trang_thai_thanh_toan: "trang_thai_thanh_toan",

  so_chan_doan_hinh_anh: "so_chan_doan_hinh_anh",
  hd_chan_doan_hinh_anh_theo_loai_dv: "hd_chan_doan_hinh_anh_theo_loai_dv",
  thong_ke_dv_cdha_theo_bn: "thong_ke_dv_cdha_theo_bn",
  confirm_huy_thuc_hien_khi_co_dv_da_tra: "confirm_huy_thuc_hien_khi_co_dv_da_tra",
  confirm_huy_thuc_hien: "confirm_huy_thuc_hien",
  da_thanh_toan: "da_thanh_toan",
  can_tao_lich_lam_viec: "can_tao_lich_lam_viec",

  khong_co_lich_su_kham_gan_nhat: "khong_co_lich_su_kham_gan_nhat",
  da_xac_thuc: "da_xac_thuc",
  chuyen_huong_toi_trang_chu: "chuyen_huong_toi_trang_chu",

  quan_ly_tai_khoan: "quan_ly_tai_khoan",
  thong_tin_tai_khoan: "thong_tin_tai_khoan",
  doi_mat_khau: "doi_mat_khau",
  mat_khau_cu: "mat_khau_cu",
  mat_khau_moi: "mat_khau_moi",
  nhap_lai_mat_khau_moi: "nhap_lai_mat_khau_moi",
  content_doi_mat_khau: "content_doi_mat_khau",
  vui_long_xac_nhapn_mat_khau: "vui_long_xac_nhapn_mat_khau",
  xac_nhan_mat_khau_khong_dung: "xac_nhan_mat_khau_khong_dung",
  tong_kh_thanh_toan: "tong_kh_thanh_toan",
  tong_tien_hoan: "tong_tien_hoan",
  ky_thuat: "ky_thuat",

  common_noi_dung: "common_noi_dung",
  title_khai_thac_thong_tin_kb: "title_khai_thac_thong_tin_kb",
  them_moi_thong_tin_khai_thac_kham_benh: "them_moi_thong_tin_khai_thac_kham_benh",
  confirm_xoa_lich_su: "confirm_xoa_lich_su",
  don_chi_dinh: "don_chi_dinh",
  don_vang_lai_khach_le: "don_vang_lai_khach_le",
  nguoi_ban_thuoc: "nguoi_ban_thuoc",
  thoi_gian_thuc_hien: "thoi_gian_thuc_hien",
  xem_tuong_trinh: "xem_tuong_trinh",
  nhap_thong_tin_thu_thuat: "nhap_thong_tin_thu_thuat",
  luu_va_in_tuong_trinh: "luu_va_in_tuong_trinh",
  lam_moi: "lam_moi",

  field_bs_chi_dinh: "field_bs_chi_dinh",
  field_thoi_gian_ke_don: "field_thoi_gian_ke_don",
  lable_thong_tin_chi_tiet_ban_thuoc: "lable_thong_tin_chi_tiet_ban_thuoc",
  label_thuoc_co_trong_don_BS_ke: "label_thuoc_co_trong_don_BS_ke",
  label_thuoc_co_tron_don_nhung_BN_khong_mua: "label_thuoc_co_tron_don_nhung_BN_khong_mua",
  label_thuoc_ko_co_trong_don_BS_ke: "label_thuoc_ko_co_trong_don_BS_ke",

  label_bao_cao_thong_ke_ban_thuoc: "label_bao_cao_thong_ke_ban_thuoc",
  thoi_gian_bat_dau: "thoi_gian_bat_dau",
  thoi_gian_ket_thuc: "thoi_gian_ket_thuc",
  nguoi_chi_dinh: "nguoi_chi_dinh",

  chon_phan_he_LIS: "chon_phan_he_LIS",
  chon_phan_he_RIS: "chon_phan_he_RIS",
  chon_phan_he_PRM: "chon_phan_he_PRM",
  chon_phan_he_MANAGEMENT: "chon_phan_he_MANAGEMENT",
  chon_phan_he_CIS: "chon_phan_he_CIS",
  noti_ban_khong_co_quyen_cho_chuc_nang_nay: "noti_ban_khong_co_quyen_cho_chuc_nang_nay",

  khong_co_quyen: "khong_co_quyen",

  nhap_day_du_thong_tin_thu_thuat: "nhap_day_du_thong_tin_thu_thuat",
  huy_thuc_hien: "huy_thuc_hien",
  tra_kq: "tra_kq",
  nhap_thong_tin: "nhap_thong_tin",
  da_hoan_thanh: "da_hoan_thanh",
  error_thoi_gian: "error_thoi_gian",
  trang_thai_ca_kham: "trang_thai_ca_kham",
  phieu_hoan: "phieu_hoan",
  phieu_thu: "phieu_thu",

  lua_chon_phan_he: "lua_chon_phan_he",
  home_intro: "home_intro",
  bao_cao_thong_ke: "bao_cao_thong_ke",
  sub_title_danh_muc: "sub_title_danh_muc",
  sub_title_cis: "sub_title_cis",
  sub_title_duoc_ngoai_tru: "sub_title_duoc_ngoai_tru",
  sub_title_lis: "sub_title_lis",
  sub_title_ris: "sub_title_ris",
  sub_title_prm: "sub_title_prm",
  sub_title_bao_cao: "sub_title_bao_cao",
  sub_title_pacs: "sub_title_pacs",
  dia_chi_deepcare: "dia_chi_deepcare",

  luu_y_mat_khau_mac_dinh: "luu_y_mat_khau_mac_dinh",
  noti_mat_khau_ko_ok: "noti_mat_khau_ko_ok",
  dk_do_dai_mat_khau: "dk_do_dai_mat_khau",
  dk_mat_khau_bao_gom: "dk_mat_khau_bao_gom",
  dk_mat_khau_ky_tu: "dk_mat_khau_ky_tu",
  cs_dat_kham: "cs_dat_kham",
  tong: "tong",
  noti_chuyen_cskcb: "noti_chuyen_cskcb",
  cisLabel: "cisLabel",
  home: "home",

  quan_ly_tam_ung: 'quan_ly_tam_ung',
  quan_ly_cong_no: 'quan_ly_cong_no',
  quan_ly_goi_dv: 'quan_ly_goi_dv',
  danh_sach_goi_dv: 'danh_sach_goi_dv',
  quan_ly_dk_goi_dv: 'quan_ly_dk_goi_dv',
  quan_ly_su_dung_goi_dv: 'quan_ly_su_dung_goi_dv',
  kham_dich_vu: "kham_dich_vu",
  goi_dich_vu: "goi_dich_vu",
  chi_dinh_goi_dich_vu: "chi_dinh_goi_dich_vu",
  dk_goi_dv: "dk_goi_dv",
  thong_tin_sd_goi_dv: "thong_tin_sd_goi_dv",
  dang_ky_goi_dv: "dang_ky_goi_dv",
  loai_goi: "loai_goi",
  ma_voucher: "ma_voucher",
  tt_goi_dich_vu: "tt_goi_dich_vu",
  lich_su_su_dung: "lich_su_su_dung",
  lich_su_thanh_toan: "lich_su_thanh_toan",
  gia_goi_dich_vu: "gia_goi_dich_vu",
  CCCD_Passport: "CCCD_Passport",
  loai_gia: "loai_gia",
  don_gia_phan_bo: "don_gia_phan_bo",
  thanh_tien_phan_bo: "thanh_tien_phan_bo",
  tong_sl: "tong_sl",
  tong_tien_phan_bo: "tong_tien_phan_bo",
  kich_hoat_goi_dv: "kich_hoat_goi_dv",

  chua_hoat_dong: "chua_hoat_dong",
  hoat_dong: "hoat_dong",
  tam_ngung: "tam_ngung",
  sap_het_han: "sap_het_han",
  het_han: "het_han",

  them_moi_goi_dv: "them_moi_goi_dv",
  chinh_sua_goi_dv: 'chinh_sua_goi_dv',

  ma_goi: "ma_goi",
  ten_goi: "ten_goi",
  ngay_tao: "ngay_tao",
  gia_ban_goi: "gia_ban_goi",
  ngay_hieu_luc: "ngay_hieu_luc",
  ngay_het_han: "ngay_het_han",
  so_ngay_su_dung: "so_ngay_su_dung",
  thao_tac: "thao_tac",

  thong_tin_goi_dv: "thong_tin_goi_dv",
  ma_goi_dv: "ma_goi_dv",
  ten_goi_dv: "ten_goi_dv",
  loai_goi_dv: "loai_goi_dv",
  chi_dinh_goi_dv: "chi_dinh_goi_dv",
  goi_co_san: "goi_co_san",
  sao_chep: "sao_chep",
  ty_le: "ty_le",
  nguoi_cap_nhat: "nguoi_cap_nhat",
  ngay_cap_nhat: "ngay_cap_nhat",
  thue_VAT: "thue_VAT",
  goi_dv: "goi_dv",
  ngay_kich_hoat: "ngay_kich_hoat",
  ghi_chu: "ghi_chu",
  dinh_muc: "dinh_muc",
  da_su_dung: "da_su_dung",
  con_lai: "con_lai",
  so_du_cong_no: "so_du_cong_no",
  ngay_giao_dich: "ngay_giao_dich",
  dich_vu_lieu_trinh_ngay: "dich_vu_lieu_trinh_ngay",
  xem_ket_qua: "xem_ket_qua",

  ly_do_tam_ung: "ly_do_tam_ung",
  so_tien_tam_ung: "so_tien_tam_ung",
  nguoi_nop_tien: "nguoi_nop_tien",
  thoi_gian_tao_phieu: "thoi_gian_tao_phieu",
  ma_phieu_tam_ung: "ma_phieu_tam_ung",
  thong_tin_phieu_tam_ung: "thong_tin_phieu_tam_ung",
  phieu_thu_tam_ung: "phieu_thu_tam_ung",
  noti_tam_ngung_sd_goi_dv: 'noti_tam_ngung_sd_goi_dv',
  noti_hoan_thanh_sd_goi_dv: 'noti_hoan_thanh_sd_goi_dv',

  tao_moi_that_bai: 'tao_moi_that_bai',
  bn_da_dang_ky_goi_dv_nay: 'bn_da_dang_ky_goi_dv_nay',
  xac_nhan_xoa_dang_ky_goi_dv: 'xac_nhan_xoa_dang_ky_goi_dv',
  ngay_kt_lon_hon_ngay_het_han: 'ngay_kt_lon_hon_ngay_het_han',
  dk_goi_thanh_cong: 'dk_goi_thanh_cong',
  xac_nhan_khoa_dang_ky_goi_dv: 'xac_nhan_khoa_dang_ky_goi_dv',
  lay_thong_tin_that_bai: 'lay_thong_tin_that_bai',
  dung_goi: 'dung_goi',
  hoan_tat_goi: 'hoan_tat_goi',

  warning_over_size: "warning_over_size",
  noti_khoa_goi_dich_vu: 'noti_khoa_goi_dich_vu',
  noti_tam_ngung_goi_dich_vu: 'noti_tam_ngung_goi_dich_vu',
  noti_xoa_goi_dich_vu: 'noti_xoa_goi_dich_vu',
  noti_xoa_mien_giam_goi: 'noti_xoa_mien_giam_goi',
  kham_doan: "kham_doan",
  lich_su_kham: 'lich_su_kham',
  chi_tiet_ca_kham: 'chi_tiet_ca_kham',
  thong_tin_ca_kham: 'thong_tin_ca_kham',
  chua_co_ket_qua: 'chua_co_ket_qua',

  phieu_huy: "phieu_huy",
  phieu_tam_ung: "phieu_tam_ung",
  phieu_hoan_ung: "phieu_hoan_ung",
  ma_ca_kham: 'ma_ca_kham',
  quan_ly_tam_hoan_ung: "quan_ly_tam_hoan_ung",
  ly_do_tam_hoan_ung: "ly_do_tam_hoan_ung",
  ngay_tao_phieu: "ngay_tao_phieu",
  tong_tien_tam_ung: "tong_tien_tam_ung",
  so_tien_hoan_ung: "so_tien_hoan_ung",
  hinh_thuc_chi: "hinh_thuc_chi",
  ly_do_hoan_ung: "ly_do_hoan_ung",
  thong_tin_hoan_ung: "thong_tin_hoan_ung",
  phieu_hoan_tam_ung: "phieu_hoan_tam_ung",
  phieu_thu_goi_dv: "phieu_thu_goi_dv",
  phieu_hoan_goi_dv: "phieu_hoan_goi_dv",
  phieu_tam_dat_coc: "phieu_tam_dat_coc",
  ho_ten_ng_nop_tien: "ho_ten_ng_nop_tien",
  so_tien_da_tam_ung: "so_tien_da_tam_ung",
  hinh_thuc_chi_hoan_ung: "hinh_thuc_chi_hoan_ung",
  phieu_chi_hoan_ung: "phieu_chi_hoan_ung",

  ho_va_ten_khach_hang: 'ho_va_ten_khach_hang',
  thanh_toan: 'thanh_toan',
  length_phone: 'length_phone',
  dich_vu_da_su_dung: 'dich_vu_da_su_dung',
  settlement: 'settlement',
  gioi_tinh_nam: "gioi_tinh_nam",
  gioi_tinh_nu: "gioi_tinh_nu",
  gioi_tinh_khac: "gioi_tinh_khac",
  gioi_tinh: "gioi_tinh",
  noti_thao_tac_that_bai: 'noti_thao_tac_that_bai',
  btn_mau_chi_dinh_dv: "btn_mau_chi_dinh_dv",
  khach_hang_dua: "khach_hang_dua",

  nhom_doi_tuong_nguoi_dung: "nhom_doi_tuong_nguoi_dung",
  so_tien_khach_hang_dua: 'so_tien_khach_hang_dua',
  mental: 'mental',
  left_eye: 'left_eye',
  right_eye: 'right_eye',
  eyesight_without_glasses: "eyesight_without_glasses",
  eyesight_with_glasses: "eyesight_with_glasses",

  left_ear: 'left_ear',
  right_ear: 'right_ear',
  speak_normally: 'speak_normally',
  speak_whisper: 'speak_whisper',
  tooth_diagram: 'tooth_diagram',
  damaged_teeth: 'damaged_teeth',
  child_jaw: 'child_jaw',
  adult_jaw: 'adult_jaw',
  upper_jaw: 'upper_jaw',
  lower_jaw: 'lower_jaw',
  error_maxillary_mandibular: "error_maxillary_mandibular",
  nguoi_duyet: "nguoi_duyet",
  thoi_gian_duyet: "thoi_gian_duyet",
  nguoi_nhap: "nguoi_nhap",
  tim_file: "tim_file",
  in_ket_qua: "in_ket_qua",
  them_httt: "them_httt",
  xoa_tat_ca: 'xoa_tat_ca',
  danh_sach_hinh_anh: 'danh_sach_hinh_anh',
  link_hinh_anh: 'link_hinh_anh',
  su_dung_anh: 'su_dung_anh',
  chinh_sua_hinh_anh: 'chinh_sua_hinh_anh',
  text_quet_qr: 'text_quet_qr',
  link_ket_qua: 'link_ket_qua',
  quan_ly_mien_giam: 'quan_ly_mien_giam',
  them_nhom_doi_tuong_nguoi_dung: 'them_nhom_doi_tuong_nguoi_dung',
  them_co_che_mien_giam: 'them_co_che_mien_giam',
  hen_tai_kham: 'hen_tai_kham',
  title_mau_chi_dinh_dich_vu: "title_mau_chi_dinh_dich_vu",
  danh_sach_chi_dinh_dich_vu: "danh_sach_chi_dinh_dich_vu",
  danh_sach_dich_vu_duoc_chi_dinh: "danh_sach_dich_vu_duoc_chi_dinh",
  da_sao_chep: "da_sao_chep",
  do_thi_luc: "do_thi_luc",
  thi_luc: "thi_luc",
  khong_kinh: "khong_kinh",
  co_kinh: "co_kinh",
  hearing_test_results: "hearing_test_results",
  do_thinh_luc: "do_thinh_luc",
  thinh_luc: 'thinh_luc',

  luu_mau_chi_dinh_dich_vu: "luu_mau_chi_dinh_dich_vu",
  so_tam_ung: 'so_tam_ung',
  so_cong_no: 'so_cong_no',
  noti_xoa_so: 'noti_xoa_so',
  tien_mat_dau_ca: 'tien_mat_dau_ca',
  tien_mat_cuoi_ca: 'tien_mat_cuoi_ca',
  quyet_toan: 'quyet_toan',
  noti_quyet_toan: 'noti_quyet_toan',
  hien_thi: 'hien_thi',
  on_one_page: 'on_one_page',
  truoc: 'truoc',
  tiep: 'tiep',
  chon_tat_ca: 'chon_tat_ca',
  them_goi_dich_vu: 'them_goi_dich_vu',
  dang_ky: 'dang_ky',
  tong_SL: 'tong_SL',
  ma_dich_vu: 'ma_dich_vu',
  tra_sau: "tra_sau",
  so_tien_da_thanh_toan: 'so_tien_da_thanh_toan',
  QUYET_TOAN_LOI: 'QUYET_TOAN_LOI',
  NO_BILL_IN_SHIFT: 'NO_BILL_IN_SHIFT',
  thanh_toan_tam_ung: 'thanh_toan_tam_ung',
  chi_dinh_dich_vu: "chi_dinh_dich_vu",
  trang_thai_da_cap_nhat: "trang_thai_da_cap_nhat",
  huy_thuc_hien_full: "huy_thuc_hien_full",
  danh_sach_dich_vu: "danh_sach_dich_vu",
  dich_vu_chi_dinh: "dich_vu_chi_dinh",
  tong_can_thanh_toan: 'tong_can_thanh_toan',
  bo_phan_tiep_don: 'bo_phan_tiep_don',
  title_modal_finish_examination: 'title_modal_finish_examination',
  tao_phieu_chi_dinh_dich_vu: 'tao_phieu_chi_dinh_dich_vu',
  chi_tiet_phieu_chi_dinh_dich_vu: 'chi_tiet_phieu_chi_dinh_dich_vu',
  tien_khach_dua: 'tien_khach_dua',
  so_tien_thanh_toan: 'so_tien_thanh_toan',
  luu_cong_no: 'luu_cong_no',
  vui_long_lua_chon_so_luu_cong_no: 'vui_long_lua_chon_so_luu_cong_no',
  ly_do_huy_phieu: 'ly_do_huy_phieu',
  noti_nhap_ly_do_huy: 'noti_nhap_ly_do_huy',
  note_print_xn: "note_print_xn",
  note_print_xn_2: "note_print_xn_2",
  technical_and_specialized_gynecology: "technical_and_specialized_gynecology",
  so_dien_thoai: "so_dien_thoai",
  driving_license: "driving_license",
  phieu_ket_qua_xet_nghiem: "phieu_ket_qua_xet_nghiem",
  phieu_ket_qua: "phieu_ket_qua",
  ngay_sinh: "ngay_sinh",
  implementer: 'implementer',
  implementation_date: "implementation_date",
  hang_bang_lx: "hang_bang_lx",
  kq_do_chi_so_sinh_ton: "kq_do_chi_so_sinh_ton",
  thong_tin_ket_qua_do: "thong_tin_ket_qua_do",
  specialist: "specialist",
  tro_ly_ai: "tro_ly_ai",
  tro_ly_y_khoa_ai: "tro_ly_y_khoa_ai",
  vui_long_nhap_chan_doan_xac_dinh: "vui_long_nhap_chan_doan_xac_dinh",

  ho_so_benh_an: "ho_so_benh_an",
  noti_dong_bo_doi_tuong_kh: "noti_dong_bo_doi_tuong_kh",
  sync: "sync",
  ko_hien_thi_lai: "ko_hien_thi_lai",
  kham_goi_dich_vu: 'kham_goi_dich_vu',
  label_sd_thong_tin_kh_cu: 'label_sd_thong_tin_kh_cu',
  trung_thong_tin: "trung_thong_tin",
  tool_tip_quan_tri: 'tool_tip_quan_tri',
  tool_tip_cis: 'tool_tip_cis',
  tool_tip_lis: 'tool_tip_lis',
  tool_tip_ris: 'tool_tip_ris',
  tool_tip_prm: 'tool_tip_prm',
  tool_tip_duoc: 'tool_tip_duoc',
  tool_tip_bao_cao: 'tool_tip_bao_cao',
  warn_thong_tin_tham_khao_hoi_chuyen_gia: "warn_thong_tin_tham_khao_hoi_chuyen_gia",
  de_xuat_dich_vu: "de_xuat_dich_vu",
  tool_tip_pacs: 'tool_tip_pacs',
  dang_ky_goi_lieu_trinh: 'dang_ky_goi_lieu_trinh',
  danh_sach_chuyen_khoa: "danh_sach_chuyen_khoa",
  tiep_don_moi: "tiep_don_moi",
  tao_phieu_chi_dinh_goi_dich_vu: "tao_phieu_chi_dinh_goi_dich_vu",
  vui_long_chon_benh_nhan: "vui_long_chon_benh_nhan",
  thuc_hien_can_lam_sang: "thuc_hien_can_lam_sang",
  huy_tao: 'huy_tao',
  thuc_hien_dv_cls: 'thuc_hien_dv_cls',
  kham_goi_lieu_trinh: 'kham_goi_lieu_trinh',
  ten_goi_lieu_trinh: 'ten_goi_lieu_trinh',
  nhom_goi: 'nhom_goi',
  goi_lieu_trinh: 'goi_lieu_trinh',
  goi_dv_lieu_trinh: 'goi_dv_lieu_trinh',

  // Assess sleepiness,

  danh_gia_tt_buon_ngu: "danh_gia_tt_buon_ngu",

  ngoi_va_doc: "ngoi_va_doc",
  ngoi_yen_cong_cong: "ngoi_yen_cong_cong",
  nam_nghi_buoi_chieu: "nam_nghi_buoi_chieu",
  ngoi_im_lang_sau_an_chua: "ngoi_im_lang_sau_an_chua",
  xem_truyen_hinh: "xem_truyen_hinh",
  ngoi_im_mot_tieng: "ngoi_im_mot_tieng",
  ngoi_va_noi_chuyen: "ngoi_va_noi_chuyen",
  ngoi_trong_xe_oto: "ngoi_trong_xe_oto",

  danh_gia_tt_buon_ngu_qs1: "danh_gia_tt_buon_ngu_qs1",
  danh_gia_tt_buon_ngu_qs2: "danh_gia_tt_buon_ngu_qs2",
  danh_gia_tt_buon_ngu_qs3: "danh_gia_tt_buon_ngu_qs3",
  danh_gia_tt_buon_ngu_qs4: "danh_gia_tt_buon_ngu_qs4",

  mau_kham_benh: "mau_kham_benh",
  kham_noi_tq: "kham_noi_tq",
  kham_noi_giac_ngu: "kham_noi_giac_ngu",

  kham_hong: "kham_hong",
  diem_mallampati: "diem_mallampati",
  diem_epworth: "diem_epworth",
  danh_gia_tt_lq_giac_ngu: "danh_gia_tt_lq_giac_ngu",
  diem_stop_bang: "diem_stop_bang",
  danh_gia_stop_bang: "danh_gia_stop_bang",
  cac_bo_phan_khac: "cac_bo_phan_khac",

  mallampati_1: "mallampati_1",
  mallampati_2: "mallampati_2",
  mallampati_3: "mallampati_3",
  mallampati_4: "mallampati_4",

  epworth_1_6: "epworth_1_6",
  epworth_7_8: "epworth_7_8",
  epworth_9_24: "epworth_9_24",

  stop_bang_cao: "stop_bang_cao",
  stop_bang_thap: "stop_bang_thap",
  stop_bang_min3: "stop_bang_min3",
  stop_bang_max3: "stop_bang_max3",

  thang_diem_stop_bang: "thang_diem_stop_bang",
  stop_bang_ngay_to: "stop_bang_ngay_to",
  stop_bang_thuong_met: "stop_bang_thuong_met",
  stop_bang_quan_sat: "stop_bang_quan_sat",
  stop_bang_huyet_ap: "stop_bang_huyet_ap",
  stop_bang_bmi: "stop_bang_bmi",
  stop_bang_tuoi: "stop_bang_tuoi",
  stop_bang_chu_vi: "stop_bang_chu_vi",
  stop_bang_gt_nam: "stop_bang_gt_nam",

  so_diem_tc: "so_diem_tc",
  trieu_chung_thuc_the: "trieu_chung_thuc_the",
  thang_diem: "thang_diem",
  bmi: "bmi",
  vong_co: "vong_co",
  vong_bung: "vong_bung",
  note_up_file_that_bai: "note_up_file_that_bai",
  khach_moi: "khach_moi",
  ph_tram_thang_du: "ph_tram_thang_du",
  tong_tien_kh_dua: "tong_tien_kh_dua",

  su_dung: "su_dung",

  duoc_tbyt: "duoc_tbyt",
  sub_title_duoc_tbyt: "sub_title_duoc_tbyt",
  ke_don_thuoc_tbyt: "ke_don_thuoc_tbyt",
  bac_si_chuyen_khoa: "bac_si_chuyen_khoa",
  dich_vu_da_thanh_toan: "dich_vu_da_thanh_toan",
  warn_khach_hang_co_dich_vu_da_duoc_chi_dinh: "warn_khach_hang_co_dich_vu_da_duoc_chi_dinh",
  warn_ban_co_muon_tiep_tuc_chi_dinh_dich_vu: "warn_ban_co_muon_tiep_tuc_chi_dinh_dich_vu",
  danh_sach_lich_kham_chua_tt: "danh_sach_lich_kham_chua_tt",
  noti_loi_tai_trang: "noti_loi_tai_trang",

  dky_lieu_trinh: "dky_lieu_trinh",
  lieu_trinh_de_xuat: "lieu_trinh_de_xuat",
  qly_su_dung: "qly_su_dung",
  ke_hoach_sd: "ke_hoach_sd",
  qly_goi_lieu_trinh: "qly_goi_lieu_trinh",
  so_buoi_sd: "so_buoi_sd",
  dv_sd_trong_goi: "dv_sd_trong_goi",
  thong_tin_thuoc_vat_tu_sd: "thong_tin_thuoc_vat_tu_sd",
  dv_chua_dung: "dv_chua_dung",
  chi_dinh_thuoc_vat_tu_trong_goi: "chi_dinh_thuoc_vat_tu_trong_goi",
  thuoc_vat_tu_chua_dung: "thuoc_vat_tu_chua_dung",
  thong_tin_goi_lieu_trinh: "thong_tin_goi_lieu_trinh",
  cach_thuc_thu_thuat: "cach_thuc_thu_thuat",
  phuong_phap_thu_thuat: "phuong_phap_thu_thuat",
  loai_thu_thuat: "loai_thu_thuat",
  duoc_vat_tu_sd: "duoc_vat_tu_sd",
  them_vat_tu_ngoai_goi: "them_vat_tu_ngoai_goi",
  tinh_trang_truoc_th: "tinh_trang_truoc_th",
  tinh_trang_khi_th: "tinh_trang_khi_th",
  tinh_trang_sau_th: "tinh_trang_sau_th",
  thanh_phan_thuc_hien: "thanh_phan_thuc_hien",
  nv_thuc_hien_chinh: "nv_thuc_hien_chinh",
  nv_ho_tro: "nv_ho_tro",

  thong_tin_kham_dv: "thong_tin_kham_dv",
  so_tien_no_cua_kh: "so_tien_no_cua_kh",
  da_vuot_han_muc_no: "da_vuot_han_muc_no",
  chac_chan_muon_tiep_tuc_chi_dinh_dv: "chac_chan_muon_tiep_tuc_chi_dinh_dv",
  chua_tao_phieu_thu_muon_tao_ko: "chua_tao_phieu_thu_muon_tao_ko",
  vui_long_chon_so_luu_phieu_thu: "vui_long_chon_so_luu_phieu_thu",
  tao_phieu_thu: "tao_phieu_thu",
  huy_phieu_thu: "huy_phieu_thu",
  huy_phieu_thu_da_tao: "huy_phieu_thu_da_tao",
  tien_trinh_sd_goi: "tien_trinh_sd_goi",
  vat_tu_tieu_hao_tu_truc: "vat_tu_tieu_hao_tu_truc",
  sub_title_vt: "sub_title_vt",
  sub_title_kd: "sub_title_kd",
  vat_tu_tu_truc: "vat_tu_tu_truc",
  khamdoan: "khamdoan",
  sd_tien_tam_ung: "sd_tien_tam_ung",
  tong_tam_ung: "tong_tam_ung",
  tien_hoan_ung_ko_the_lon_hon_tien_tam_ung: "tien_hoan_ung_ko_the_lon_hon_tien_tam_ung",
  in_phieu_cong_no: "in_phieu_cong_no",
  phieu_thu_cong_no: "phieu_thu_cong_no",
  tu_van_goi_lieu_trinh: "tu_van_goi_lieu_trinh",
  thoi_han_sd_goi: "thoi_han_sd_goi",
  ngay_hieu_luc_ban_goi: "ngay_hieu_luc_ban_goi",
  ngay_het_han_ban_goi: "ngay_het_han_ban_goi",
  so_ngay_sd_goi_tinh_tu_ngay_kich_hoat: "so_ngay_sd_goi_tinh_tu_ngay_kich_hoat",
  so_lan_su_dung_dich_vu: "so_lan_su_dung_dich_vu",
  goi_dung_1_lan: "goi_dung_1_lan",
  goi_dung_nhieu_ngay: "goi_dung_nhieu_ngay",
  lieu_trinh_duoc_de_xuat: "lieu_trinh_duoc_de_xuat",
  nguoi_de_xuat: "nguoi_de_xuat",
  chi_dinh_dv_trong_goi: "chi_dinh_dv_trong_goi",
  bac_si_de_xuat: "bac_si_de_xuat",
  ngay_dat_hen: "ngay_dat_hen",
  gio_dat_hen: "gio_dat_hen",
  nhan_ban: "nhan_ban",
  nhan_ban_ke_hoach: "nhan_ban_ke_hoach",
  vui_long_nhap_sl_nhan_ban: "vui_long_nhap_sl_nhan_ban",
  sl_nhan_ban: "sl_nhan_ban",
  tt_thuoc_vat_tu_trong_goi: "tt_thuoc_vat_tu_trong_goi",
  chi_dinh_thuoc_vat_tu_ngoai_goi: "chi_dinh_thuoc_vat_tu_ngoai_goi",
  kho_duoc_vat_tu: "kho_duoc_vat_tu",
  ma_thuoc_vt: "ma_thuoc_vt",
  ten_thuoc_vtyt_hoa_chat: "ten_thuoc_vtyt_hoa_chat",
  tt_thu_thuat: "tt_thu_thuat",
  sd_mau: "sd_mau",
  luu_thanh_mau: "luu_thanh_mau",
  ma_mau: "ma_mau",
  ten_mau: "ten_mau",
  hinh_anh_thu_thuat: "hinh_anh_thu_thuat",
  tt_vien_chinh: "tt_vien_chinh",
  phu: "phu",
  dung_cu_vien: "dung_cu_vien",
  gm_chinh: "gm_chinh",
  gm_phu: "gm_phu",
  giup_viec: "giup_viec",
  vui_long_thay_doi_tt_truoc_luu: "vui_long_thay_doi_tt_truoc_luu",
  gui_ma_thanh_cong_vui_long_kiem_tra_email: "gui_ma_thanh_cong_vui_long_kiem_tra_email",
  xac_thuc_thanh_cong_vui_long_nhap_mk: "xac_thuc_thanh_cong_vui_long_nhap_mk",
  vui_long_chon_httt: "vui_long_chon_httt",
  dang_duoc_sd: "dang_duoc_sd",
  chua_duoc_nhap: "chua_duoc_nhap",
  vui_long_chon_lo_thuoc_khac: "vui_long_chon_lo_thuoc_khac",
  da_het_hang_trong_kho: "da_het_hang_trong_kho",
  da_duoc_them_vao_phieu: "da_duoc_them_vao_phieu",
  vui_long_chon_thuoc_khac: "vui_long_chon_thuoc_khac",
  vui_long_them_thuoc: "vui_long_them_thuoc",
  vui_long_nhap_lo_thuoc_so_luong: "vui_long_nhap_lo_thuoc_so_luong",
  thuoc_da_tam_dung_nhap: "thuoc_da_tam_dung_nhap",
  ko_them_tu_dong_duoc_thuoc: "ko_them_tu_dong_duoc_thuoc",
  loai_don_thuoc: "loai_don_thuoc",
  lien_thong_don_thuoc: "lien_thong_don_thuoc",
  sl_ke_don: 'sl_ke_don',
  sl_ban: 'sl_ban',
  bo_loc_nang_cao: "bo_loc_nang_cao",
  don_chua_ban: "don_chua_ban",
  don_da_ban: "don_da_ban",
  trang_thai_ban_don_thuoc: "trang_thai_ban_don_thuoc",
  chua_lien_thong: "chua_lien_thong",
  da_lien_thong: "da_lien_thong",
  noti_chuyen_toi_phan_he_de_chinh_sua: "noti_chuyen_toi_phan_he_de_chinh_sua",
  nghi_ngo_u_phoi: "nghi_ngo_u_phoi",
  nghi_ngo_lao_phoi: "nghi_ngo_lao_phoi",
  benh_khac: "benh_khac",
  co_thai: "co_thai",
  benh_ve_gan: "benh_ve_gan",
  benh_ve_he_tiet_nieu: "benh_ve_he_tiet_nieu",
  benh_ve_tuyen_tuy: "benh_ve_tuyen_tuy",
  benh_ve_lach: "benh_ve_lach",
  benh_ve_tuyen_tien_liet: "benh_ve_tuyen_tien_liet",
  benh_ve_bo_phan_tu_cung: "benh_ve_bo_phan_tu_cung",
  benh_ve_da_day: "benh_ve_da_day",
  don_thuoc_chuan: "don_thuoc_chuan",
  don_thuoc_gay_nghien: "don_thuoc_gay_nghien",
  don_thuoc_huong_than: "don_thuoc_huong_than",
  don_thuoc_y_hoc_co_truyen: "don_thuoc_y_hoc_co_truyen",
  loai_khach_hang: "loai_khach_hang",
  benh_nhan_cu: 'benh_nhan_cu',
  benh_nhan_moi: "benh_nhan_moi",
  title_xuat_ban: "title_xuat_ban",
  title_xuat_tra: "title_xuat_tra",
  title_xuat_huy: "title_xuat_huy",
  noti_phien_dang_nhap_da_het_han: 'noti_phien_dang_nhap_da_het_han',
  noti_kho_thuoc_khong_duoc_phan_quyen: "noti_kho_thuoc_khong_duoc_phan_quyen",
  msg_hien_khong_kha_dung: "msg_hien_khong_kha_dung",
  msg_khong_co_mau_in: "msg_khong_co_mau_in",
  common_mau: "common_mau",
  updatingAccInfo: "updatingAccInfo",
  loading_he_thong_dang_xac_thuc: "loading_he_thong_dang_xac_thuc",
  dien_bien_cua_benh: "dien_bien_cua_benh",

  lien_thong: 'lien_thong',
  trang_thai_lien_thong: 'trang_thai_lien_thong',
  du_dieu_kien: "du_dieu_kien",
  chua_du_dieu_kien: "chua_du_dieu_kien",
  trang_thai_mua: "trang_thai_mua",
  dieu_kien_lien_thong: "dieu_kien_lien_thong",
  don_thuoc_yhct: "don_thuoc_yhct",
  content_msg_chua_du_dieu_kien_lien_thong: "content_msg_chua_du_dieu_kien_lien_thong",
  dong_bo_don_thuoc: "dong_bo_don_thuoc",
  ban_co_chac_chan_muon_huy_sid: "ban_co_chac_chan_muon_huy_sid",
  cau_hinh_ngon_ngu_that_bai: "cau_hinh_ngon_ngu_that_bai",
  noti_truy_cap_bi_tu_choi: "noti_truy_cap_bi_tu_choi",
  bac_si_tu_van: "bac_si_tu_van",
  thuoc_dieu_tri_de_xuat: "thuoc_dieu_tri_de_xuat",
  phieu_thong_tin_tu_van_cua_bac_si: "phieu_thong_tin_tu_van_cua_bac_si",
  thuoc_dieu_tri: "thuoc_dieu_tri",
  phac_do_dieu_tri: "phac_do_dieu_tri",
  lieu_trinh_dieu_tri: "lieu_trinh_dieu_tri",
  phieu_thong_tin_tu_van_khach_hang: "phieu_thong_tin_tu_van_khach_hang",
  field_ten_phieu: "field_ten_phieu",
  tao_phieu_xuat: "tao_phieu_xuat",
  family_bo: 'family_bo',
  family_me: 'family_me',
  family_vo: 'family_vo',
  family_chong: 'family_chong',
  family_con: 'family_con',
  family_khac: 'family_khac',
  xoa_phong: "xoa_phong",
  sua_phong: "sua_phong",
  xoa_khoa: "xoa_khoa",
  sua_khoa: "sua_khoa",
  xoa_don_vi: "xoa_don_vi",
  sua_don_vi: "sua_don_vi",
  sua_giuong: "sua_giuong",
  xoa_giuong: "xoa_giuong",
  xoa_thu_thuat: "xoa_thu_thuat",
  sua_thu_thuat: "sua_thu_thuat",
  xoa_nhom: "xoa_nhom",
  sua_nhom: "sua_nhom",
  xoa_loai_dich_vu: "xoa_loai_dich_vu",
  sua_loai_dich_vu: "sua_loai_dich_vu",
  xoa_dich_vu: "xoa_dich_vu",
  sua_dich_vu: "sua_dich_vu",
  sua_hang_san_xuat: "sua_hang_san_xuat",
  xoa_hang_san_xuat: "xoa_hang_san_xuat",
  xoa_duong_dung: "xoa_duong_dung",
  sua_duong_dung: "sua_duong_dung",
  xoa_hoat_chat: "xoa_hoat_chat",
  sua_hoat_chat: "sua_hoat_chat",
  xoa_thuoc: "xoa_thuoc",
  sua_thuoc: "sua_thuoc",
  xoa_kho_thuoc: "xoa_kho_thuoc",
  sua_kho_thuoc: "sua_kho_thuoc",
  xoa_danh_muc_may: "xoa_danh_muc_may",
  sua_danh_muc_may: "sua_danh_muc_may",
  xoa_xuat_khac: "xoa_xuat_khac",
  sua_xuat_khac: "sua_xuat_khac",
  xoa_nguon: "xoa_nguon",
  sua_nguon: "sua_nguon",
  xoa_phuong_phap: "xoa_phuong_phap",
  sua_phuong_phap: "sua_phuong_phap",
  xoa_ly_do: "xoa_ly_do",
  sua_ly_do: "sua_ly_do",
  noti_da_co_cuoc_hen_trong_ngay: 'noti_da_co_cuoc_hen_trong_ngay',
  noti_huy_phieu_thanh_cong: "noti_huy_phieu_thanh_cong",
  noti_huy_phieu_that_bai: "noti_huy_phieu_that_bai",
  title_huy_phieu_thu_dich_vu: "title_huy_phieu_thu_dich_vu",
  label_ly_do_huy_phieu: "label_ly_do_huy_phieu",
  tt_gui_PACS: 'tt_gui_PACS',
  da_gui: "da_gui",
  gui_loi: "gui_loi",
  chua_gui: "chua_gui",
  gui_PACS: "gui_PACS",
  btn_da_tra_kq: "btn_da_tra_kq",
  tab_Hoan_thanh_short: "tab_Hoan_thanh_short",
  tab_chi_dinh_can_lam_sang: "tab_chi_dinh_can_lam_sang",
  quan_ly_vat_tu_hoa_chat: "quan_ly_vat_tu_hoa_chat",
  phan_loai_benh_tat: "phan_loai_benh_tat",
  phieu_ke_hoach_dieu_tri: "phieu_ke_hoach_dieu_tri",
  ke_hoach_dieu_tri: "ke_hoach_dieu_tri",
  thuoc_vat_tu_su_dung: "thuoc_vat_tu_su_dung",
  dieu_duong_truong: "dieu_duong_truong",
  noti_truy_cap: "noti_truy_cap",
  noti_de_kiem_tra_don_thuoc_cua_ban: "noti_de_kiem_tra_don_thuoc_cua_ban",
  noti_vui_long_nhap_loai_don_thuoc: "noti_vui_long_nhap_loai_don_thuoc",

  giay_chuyen_vien: "giay_chuyen_vien",
  kinh_gui: "kinh_gui",
  pk_tran_trong_gt: "pk_tran_trong_gt",
  da_dc_dieu_tri_kh_benh_tai: "da_dc_dieu_tri_kh_benh_tai",
  tom_tat_benh_an: "tom_tat_benh_an",
  dau_hieu_lam_sang: "dau_hieu_lam_sang",
  cac_xet_nghiem: "cac_xet_nghiem",
  thuoc_da_dung: "thuoc_da_dung",
  tinh_trang_luc_chuyen_vien: "tinh_trang_luc_chuyen_vien",
  ly_do_chuyen_vien: "ly_do_chuyen_vien",
  chuyen_hoi: "chuyen_hoi",
  phuong_tien_van_chuyen: "phuong_tien_van_chuyen",
  ho_ten_ng_dua_di: "ho_ten_ng_dua_di",
  bac_si_dieu_tri: "bac_si_dieu_tri",
  giam_doc_pk: "giam_doc_pk",
  thoi_gian_chuyen_vien: "thoi_gian_chuyen_vien",
  phieu_chuyen_vien: "phieu_chuyen_vien",
  in_benh_an_ngoai_tru: "in_benh_an_ngoai_tru",

  goi_de_xuat_khac: "goi_de_xuat_khac",
  goi_dich_vu_lieu_trinh_can_thanh_toan: "goi_dich_vu_lieu_trinh_can_thanh_toan",
  vui_long_chon_so_cong_no: "vui_long_chon_so_cong_no",
  goi_bi_trung: "goi_bi_trung",
  vui_long_nhap_so_tien_khach_hang_co_the_tai_su_dung: "vui_long_nhap_so_tien_khach_hang_co_the_tai_su_dung",
  ban_co_chac_chan_muon_tat_toan_goi: "ban_co_chac_chan_muon_tat_toan_goi",
  tuy_chinh_cot: "tuy_chinh_cot",
  httt_bi_trung: "httt_bi_trung",
  no_cu: "no_cu",
  lich_su_giao_dich: "lich_su_giao_dich",
  bang_ke_cong_no: "bang_ke_cong_no",
  noti_vui_long_chon_ngay_muon_in_ke_hoach: "noti_vui_long_chon_ngay_muon_in_ke_hoach",
  quan_ly_su_dung: "quan_ly_su_dung",
  noti_da_het_thuoc_trong_kho: "noti_da_het_thuoc_trong_kho",
  noti_ban_chua_chon_nha_cung_cap: "noti_ban_chua_chon_nha_cung_cap",
  noti_vui_long_nhap_thong_tin_lo_thuoc: "noti_vui_long_nhap_thong_tin_lo_thuoc",
  noti_ban_chua_nhap_thong_tin_phieu: "noti_ban_chua_nhap_thong_tin_phieu",
  noti_thuoc_khong_thuoc_nha_cung_cap: "noti_thuoc_khong_thuoc_nha_cung_cap",
  ket_thuc: "ket_thuc",
  ket_thuc_va_in: "ket_thuc_va_in",
  tien_du_sau_khi_tat_toan_goi_dich_vu: "tien_du_sau_khi_tat_toan_goi_dich_vu",
  hoa_don_lien: "hoa_don_lien",
  noti_khong_the_huy_phieu_vi_co_thuoc_da_ke: "noti_khong_the_huy_phieu_vi_co_thuoc_da_ke",
  noti_co_loi_xay_ra_khong_the_huy_phieu: 'noti_co_loi_xay_ra_khong_the_huy_phieu',
  nguoi_thuc_hien_chinh: "nguoi_thuc_hien_chinh",
  phieukhambenh_emp_qr_oncare: "phieukhambenh_emp_qr_oncare",
  title_giai_phap_phong_kham: "title_giai_phap_phong_kham",
  them_chi_tiet_doi_tuong: 'them_chi_tiet_doi_tuong',
  ap_dung_tat_ca: "ap_dung_tat_ca",
  in_barcode: "in_barcode",
  barcode_thuong: "barcode_thuong",
  nghiem_phap_duong: "nghiem_phap_duong",
  in_ma_SID: "in_ma_SID",
  glucose_mau: "glucose_mau",
  ong_mau_khong_ten: "ong_mau_khong_ten",
  danh_sach_dich_vu_can_hoan_tien: "danh_sach_dich_vu_can_hoan_tien",
  noti_ban_co_muon_xua_het_anh_khong: "noti_ban_co_muon_xua_het_anh_khong",
  noti_vui_long_ket_noi_camera: "noti_vui_long_ket_noi_camera",
  modal_hay_xac_nhan: "modal_hay_xac_nhan",
  new: "new",
  administration: "administration",
  kham_benh_short: 'kham_benh_short',
  dvt: "dvt",
  phieu_benh_dieu_tri: "phieu_benh_dieu_tri",
  nguoi_ho_tro: "nguoi_ho_tro",
  dich_truyen_vat_tu: 'dich_truyen_vat_tu',
  dien_bien_tinh_trang_khach_hang: "dien_bien_tinh_trang_khach_hang",
  truoc_thuc_hien: "truoc_thuc_hien",
  trong_khi_thuc_hien: "trong_khi_thuc_hien",
  sau_khi_thuc_hien: "sau_khi_thuc_hien",
  tro_ly_bac_si: "tro_ly_bac_si",
  phieu_dang_ky_dich_vu: "phieu_dang_ky_dich_vu",
  already_duplicated: 'already_duplicated',
  exemption_mechanism: "exemption_mechanism",
  aivi_note_1: "aivi_note_1",
  aivi_note_2: "aivi_note_2",
  aivi_note_3: "aivi_note_3",
  in_benh_trinh: "in_benh_trinh",
  benh_trinh_dieu_tri: "benh_trinh_dieu_tri",
  vui_long_chon_ngay_muon_in_benh_trinh: "vui_long_chon_ngay_muon_in_benh_trinh",
  chi_so_suc_khoe: "chi_so_suc_khoe",
  ko_co_dv_thu_thuat: "ko_co_dv_thu_thuat",
  toi_da_duoc_tu_van_va_dong_y: "toi_da_duoc_tu_van_va_dong_y",
  tu_van: "tu_van",
  tt_kham_goi_lieu_trinh: "tt_kham_goi_lieu_trinh",
  unit: "unit",
  goi_kham: "goi_kham",
  goi_dieu_tri: "goi_dieu_tri",

  vui_long_chon_ngay_dat_hen: "vui_long_chon_ngay_dat_hen",
  chinh_sua_phieu_thu_thanh_toan: "chinh_sua_phieu_thu_thanh_toan",
  print_all: "print_all",
  warning_chinh_sua_hoa_don: "warning_chinh_sua_hoa_don",
  toc_do_truyen: "toc_do_truyen",
  tinh_trang_mau: "tinh_trang_mau",
  loai_mau: "loai_mau",
  dat: 'dat',
  namAu_haThanh_248_note_1: "namAu_haThanh_248_note_1",
  namAu_haThanh_248_note_2: "namAu_haThanh_248_note_2",
  trieu_chung: "trieu_chung",
  kham_goi: "kham_goi",
  ky_dien_tu: "ky_dien_tu",
  doi_tuong: "doi_tuong",
  tg_lay_mau: "tg_lay_mau",
  tg_nhan_mau: "tg_nhan_mau",
  nguoi_nhan_mau: "nguoi_nhan_mau",
  designated_person: "designated_person",
  CSTC: "CSTC",
  note_print_xn_5: "note_print_xn_5",
  vui_long_chon_bs_kham: "vui_long_chon_bs_kham",
  ko_the_huy_do_cap_nhat_trang_thai: "ko_the_huy_do_cap_nhat_trang_thai",
  vong_hong_chau: "vong_hong_chau",
  tuoi_man_kinh: "tuoi_man_kinh",
  quest_gay_xuong: "quest_gay_xuong",
  quest_di_kham_loang_xuong: "quest_di_kham_loang_xuong",
  wrong_format: "wrong_format",
  gan: "gan",
  than: "than",
  roi_loan_lipit: "roi_loan_lipit",
  loang_xuong: "loang_xuong",
  gay_xuong: "gay_xuong",
  field_ly_do_tu_van_dinh_duong: "field_ly_do_tu_van_dinh_duong",
  field_mot_ngay_an_dien_hinh_hien_nay: "field_mot_ngay_an_dien_hinh_hien_nay",
  field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay: "field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay",
  don_dinh_duong: "don_dinh_duong",
  don_tap_luyen: "don_tap_luyen",
  luu_y_chung: "luu_y_chung",
  tu_van_xu_tri: "tu_van_xu_tri",
  vong_bung_qua_eo: "vong_bung_qua_eo",
  vong_bung_qua_ron: "vong_bung_qua_ron",
  vong_bung_qua_gai_chau_truoc_tren: "vong_bung_qua_gai_chau_truoc_tren",
  muc_do_cam_ket_cua_khach_hang: "muc_do_cam_ket_cua_khach_hang",
  noti_dong_bo_ngay_th: "noti_dong_bo_ngay_th",
  khuyen_mai: "khuyen_mai",
  thuc_thu: "thuc_thu",
  thuoc_co_san: "thuoc_co_san",
  noti_sua_gia_lieu_trinh: "noti_sua_gia_lieu_trinh",
  huy_cong_no: "huy_cong_no",
  kham_thuong: "kham_thuong",
  huy_cong_no_warning: "huy_cong_no_warning",
  address:"address",
  drugList: "drugList"
};

export default languageKeys;
